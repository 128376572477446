import React from 'react';
import UDFormInput from 'modules/ud-form/components/input';
import UDText from 'modules/ud-ui/components/text';

interface LabeledInputProps {
  label: string;
  name: string;
  placeholder?: string;
  containerClassName?: string;
  inputContainerClassName?: string;
  alignRight?: boolean;
  flexShrink?: boolean;
}

const LabeledInput: React.FC<LabeledInputProps> = ({
  label,
  name,
  placeholder = '0',
  containerClassName = 'mb-4 d-flex align-items-center',
  inputContainerClassName = 'w-30',
  alignRight = true,
  flexShrink = false,
}) => {
  const containerProps = {
    className:
      inputContainerClassName +
      (flexShrink ? ' flex-shrink-0' : '') +
      (alignRight ? ' ml-auto' : ''),
  };

  return (
    <div className={containerClassName}>
      <UDText
        type="callout"
        className={
          alignRight
            ? 'mr-auto color-SurfaceRaisinBlack50'
            : 'mr-2 color-SurfaceRaisinBlack50'
        }
      >
        {label}
      </UDText>
      <UDFormInput
        name={name}
        placeholder={placeholder}
        containerProps={containerProps}
      />
    </div>
  );
};

export default LabeledInput;
