import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TeamSmallCard, TeamSmallList } from '../components/styles';

import { AppDispatch } from '../../../../store/store';
import { Player } from '../../domain/interfaces/player';
import { Team } from '../../../teams/domain/interfaces/Team';
import TeamLogo from '../../../ud-ui/components/team-logo';
import UDSearchComponent from '../../../ud-ui/components/search/search-component';
import UDText from '../../../ud-ui/components/text';
import { addTournamentUserToTeam } from '../../store/actions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import useModal from '../../../ud-ui/components/new-modal/useModal';
import { useOrganizer } from '../../../organizer/ui/hooks/useOrganizer';

function useModalAddToTeam() {
  const { open, close, Modal } = useModal();
  const { info } = useOrganizer();
  const dispatch = useDispatch<AppDispatch>();

  const [filter, setFilter] = useState('');
  const [player, setPlayer] = useState<Player>();

  const teams = useMemo(() => {
    if (!info) return [];
    return info?.teams;
  }, [info]);

  const [filteredTeams, setFilteredTeams] = useState<Team[]>([]);

  useEffect(() => {
    if (!teams) setFilteredTeams([]);
    setFilteredTeams(
      teams.filter((team) =>
        team.name.toLowerCase().includes(filter.toLowerCase() ?? '')
      )
    );
  }, [filter, teams]);

  const handleChangeFilter = useCallback((value: string) => {
    setFilter(value);
  }, []);

  const handleClickTeam = useCallback(
    (teamId: number) => {
      if (!player?.id) {
        toast.error('Не удалось определить игрока');
        return;
      }

      if (
        player.positionsInTeams.some(
          (position) => position?.team?.id === teamId
        )
      ) {
        toast.warn('Игрок уже состоит команде');
        return;
      }

      dispatch(
        addTournamentUserToTeam({ teamId: teamId, userId: player.id })
      ).then(() => {
        close();
        toast.success('Игрок добавлен в команду');
      });
    },
    [close, dispatch, player]
  );

  const Component = useCallback(() => {
    return (
      <Modal>
        <div className={'flex-center w-100'}>Введите название команды</div>
        <UDSearchComponent
          autoFocus
          style={{ marginTop: 16 }}
          placeholder={'Название команды'}
          value={filter}
          onChange={(event) => {
            event.stopPropagation();
            event.target.focus();
            handleChangeFilter(event.target.value);
          }}
          width={'100%'}
          backgroundColor={'#F2F2F2'}
        />
        <TeamSmallList style={{ marginTop: 16 }}>
          {filteredTeams?.map((team) => {
            return (
              <TeamSmallCard
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  width: 'calc(100% - 20px - 20px)',
                  cursor: 'pointer',
                }}
                onClick={() => handleClickTeam(team.id)}
              >
                <TeamLogo url={team.logo?.url} style={{ border: 'none' }} />
                <UDText
                  type={'subhead'}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {team.name}
                </UDText>
              </TeamSmallCard>
            );
          })}
        </TeamSmallList>
      </Modal>
    );
  }, [Modal, filter, filteredTeams, handleChangeFilter, handleClickTeam]);

  const openModal = useCallback(
    (player: Player) => {
      setFilter('');
      setFilteredTeams(teams);
      setPlayer(player);

      open({
        modalStyle: {
          padding: 0,
          zIndex: 9000,
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'transparent',
          top: 0,
          left: 0,
          borderRadius: 0,
          borderColor: 'transparent',
          overflow: 'clip',
          background: '#00000085',
        },
      });
    },
    [open, teams]
  );

  return { open: openModal, close, Modal: Component };
}

export default useModalAddToTeam;
