import EmptyButton from '../../../../ud-ui/components/emptyButton';
import PlusIcon from '../../../../ud-ui/components/icon/plus-icon';

interface IProps {
  showAddNewBoard?: boolean;
}

function BoardsSkeletons(props: IProps) {
  const { showAddNewBoard = false } = props;

  return (
    <div className="d-flex gap-4 w-100">
      <div className="d-flex flex-column gap-5">
        <div className="skeleton-block" style={{ width: 260, height: 436 }} />
        {showAddNewBoard && (
          <EmptyButton color={'#A2A2A2'} icon={<PlusIcon />} disabled>
            Добавить табло
          </EmptyButton>
        )}
      </div>
      <div className="d-flex flex-column gap-2 w-100">
        <div
          className="skeleton-block"
          style={{ width: '100%', minHeight: 77 }}
        />
        <div
          className="skeleton-block"
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  );
}

export default BoardsSkeletons;
