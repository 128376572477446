import { RootState } from "../../../store/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectLocationsSlice = createSelector(
    (state: RootState) => state.locations,
    (state) => state,
);

export const selectLocations = createSelector(
    [selectLocationsSlice],
    (state) => state.locations,
);

export const selectLoad = createSelector(
    [selectLocationsSlice],
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    }),
);

export const selectLocationsByFilter = createSelector(
    [selectLocationsSlice],
    (state) => Boolean(state.filter.text) ? state.filter.locations : state.locations,
);

export const selectLocationById = (id: number) => createSelector(
    [selectLocations],
    (locations) => locations.find((location) => location.id === id),
);

export const selectProcess = createSelector(
    [selectLocationsSlice],
    (state) => ({
        isProcessing: state.isProcessing,
        isProcessed: state.isProcessed,
        name: state.processName,
    }),
);

export const selectIsProcessing = createSelector(
    [selectProcess],
    (state) => ({
        isProcessing: state.isProcessing,
        isProcessed: state.isProcessed,
    }),
);

export const selectLocation = (id: number) => createSelector(
    [selectLocationsSlice],
    (state) => state.locations.find((location) => location.id === id),
);
