import React, { useCallback } from 'react';

import { Image } from '../../../ud-form/components/dropzone/types';
import { ImageGallery } from './index';
import { Options } from '@splidejs/react-splide';
import useModal from '../new-modal/useModal';

interface ImageGalleryModalProps {
  images: Image[];
  currentIndex: number | null;
  onClose: () => void;
  style?: React.CSSProperties;
  options?: Options;
}

export const ImageGalleryModal: React.FC<ImageGalleryModalProps> = ({
  images,
  currentIndex,
  onClose,
  style,
  options,
}) => {
  if (currentIndex === null) return null;

  return (
    <ImageGallery
      images={images}
      currentIndex={currentIndex}
      onClose={onClose}
      style={style}
      options={options}
    />
  );
};

interface UseImageGalleryProps {
  images?: Image[];
  size?: {
    width?: string;
    height?: string;
    maxWidth?: string;
  };
}

export const useImageGallery = (props?: UseImageGalleryProps) => {
  const { images: initialImages, size } = props || {};
  const {
    defaultOpen: openGallery,
    close: closeGallery,
    Modal: GalleryModal,
  } = useModal();
  const [selectedImageIndex, setSelectedImageIndex] = React.useState<
    number | null
  >(null);
  const [images, setImages] = React.useState<Image[]>(initialImages || []);

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    openGallery({
      modalStyle: {
        backgroundColor: 'transparent',
      },
      containerProps: {
        style: {
          width: size?.width || '73vw',
          maxWidth: size?.maxWidth || '1200px',
          borderRadius: '8px',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          border: 'none',
          boxShadow: 'none',
          padding: '0',
          overflow: 'hidden',
        },
      },
      iconProps: {
        style: {
          zIndex: 90000,
        },
      },
    });
  };

  const handleClose = useCallback(() => {
    closeGallery();
    setSelectedImageIndex(null);
  }, [closeGallery, setSelectedImageIndex]);

  const GalleryModalWrapper = React.useCallback(
    () => (
      <GalleryModal>
        <ImageGalleryModal
          images={images}
          currentIndex={selectedImageIndex}
          onClose={handleClose}
          options={{ height: size?.height || '60vh' }}
        />
      </GalleryModal>
    ),
    [GalleryModal, images, selectedImageIndex, handleClose, size?.height]
  );

  return {
    handleImageClick,
    selectedImageIndex,
    GalleryModalWrapper,
    handleClose,
    images,
    setImages,
  };
};
