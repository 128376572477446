import size from "../../../domain/constants/size";
import styled from "@emotion/styled";

export const MainBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.DarkBlue};
  z-index: 0;
  overflow-x: hidden;

  width: 100%;
  height: 100vh;
  display: flex;

  justify-content: center;
  align-items: center;

  padding: 120px;
  gap: 70px;

  & > .tm-logo {
    display: none;
  }

  @media (max-width: ${size.tablet}px) {
    flex-direction: column;
    justify-content: start;
    position: relative;

    padding: 284px 65px 65px 65px;
    gap: 40px;

    & > .tm-logo {
      display: unset;
      height: 384px !important;
      position: absolute !important;
      top: -32px;
      right: 18px;

      & p {
        font-size: 160px !important;
      }
    }
  }

  @media (max-width: ${size.mobile.width}px) {
    padding: 222px 21px 57px 21px;
    justify-content: space-between;

    & > .tm-logo {
      display: unset;
      min-width: unset !important;
      min-height: unset !important;
      height: 249px !important;
      width: 249px !important;
      position: absolute !important;
      top: 0;
      right: 50%;
      transform: translateX(50%);


      & p {
        font-size: clamp(100px, 20vw, 160px) !important;
        height: 249px !important;
      }
    }
  }

  @media screen and (orientation: landscape) and (max-height: ${size.mobile.height}px) and (max-width: ${size.tablet}px) {
    gap: 30px !important;

    & > .tm-logo {
      display: unset;
      min-width: unset !important;
      min-height: unset !important;
      height: 249px !important;
      width: 249px !important;
      position: absolute !important;
      top: -45px !important;
      right: -2px !important;
      transform: unset;

      & p {
        font-size: 100px !important;
        height: 249px !important;
      }
    }

    padding: 42px 48px !important;
  }
`
