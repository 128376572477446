import DrawBase from '../components/base/draw-base';

const FootballDraw = () => {
  return (
    <DrawBase sportType="football">
      {/* <div className="mt-4">
        <LabeledInput
          name="settings.drawWithGoalsScore"
          placeholder="0 очков"
          label="Дополнительные очки за результативную ничью"
        />
      </div> */}
    </DrawBase>
  );
};

export default FootballDraw;
