/** @jsxImportSource @emotion/react */

import React, { useMemo } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { Pages } from 'modules/navigation/domain/enums/pages';
import TourneyComponentsEditForm from '../../components/edit-form';
import TourneysComponentsEditFormTabs from '../../components/edit-form/tabs';
import UDBreadcrumbs from 'modules/ud-ui/components/breadcrumbs';
import UDMainLayout from 'modules/ud-ui/layout/main';
import { announcedChampionshipTeamsDataSelector } from '../../../../teams/store/championshipTeams/selectors';
import { championshipIsLoadingSelector } from 'modules/tourneys/store/championship/selectors';
import { useChampionship } from '../../hooks/use-championship';
import { useChampionshipFetch } from '../../hooks/use-championship-fetch';
import { useSelector } from 'react-redux';

const TourneyPagesEditPage = () => {
  const { id: champId } = useParams();
  useChampionshipFetch();

  const { data: champ } = useChampionship(Number(champId));
  const champTeamsData = useSelector(
    announcedChampionshipTeamsDataSelector(champ?.id)
  );
  const isChampionshipLoading = useSelector(championshipIsLoadingSelector);

  const applicationsPageMatch = useMatch(
    `${Pages.TOURNEYS.EDIT}/applications/*`
  );
  const applicationPlayersPageMatch = useMatch(
    `${Pages.TOURNEYS.EDIT}/applications/:teamId`
  );
  const teamPlayersPageRules = useMatch(`${Pages.TOURNEYS.EDIT}/rules`);
  const teamPlayersPageSteps = useMatch(`${Pages.TOURNEYS.EDIT}/steps`);
  const teamPlayersPageSymbolicTeams = useMatch(
    `${Pages.TOURNEYS.EDIT}/symbolic-teams`
  );

  const breadcrumbsItems = useMemo(() => {
    const items = [{ label: 'Турниры', to: Pages.TOURNEYS.INDEX }];

    if (!champ) {
      if (isChampionshipLoading) {
        items.push({
          label: 'Загрузка...',
          to: Pages.TOURNEYS.builders.edit(Number(champId)),
        });
      }
      return items;
    }

    items.push({
      label: champ.name,
      to: Pages.TOURNEYS.builders.edit(champ.id),
    });

    if (applicationsPageMatch) {
      items.push({
        label: 'Заявки',
        to: `/tourneys/${champ.id}/applications`,
      });
    }

    if (teamPlayersPageRules) {
      items.push({
        label: 'Условия',
        to: Pages.TOURNEYS.builders.edit(champ.id, 'rules'),
      });
    }

    if (teamPlayersPageSteps) {
      items.push({
        label: 'Этапы',
        to: Pages.TOURNEYS.builders.edit(champ.id, 'steps'),
      });
    }

    if (teamPlayersPageSymbolicTeams) {
      items.push({
        label: 'Сборные',
        to: Pages.TOURNEYS.builders.edit(champ.id, 'symbolic-teams'),
      });
    }

    if (
      applicationPlayersPageMatch &&
      applicationPlayersPageMatch.params.teamId &&
      champTeamsData
    ) {
      const teamId = parseInt(applicationPlayersPageMatch.params.teamId);
      const champTeam = champTeamsData[teamId];

      if (champTeam) {
        items.push({
          label: champTeam.name,
          to: `/tourneys/${champ.id}/applications/${champTeam.id}`,
        });
      }
    }

    return items;
  }, [
    champ,
    applicationsPageMatch,
    teamPlayersPageRules,
    teamPlayersPageSteps,
    teamPlayersPageSymbolicTeams,
    applicationPlayersPageMatch,
    champTeamsData,
    isChampionshipLoading,
    champId,
  ]);

  const breadcrumbs = (
    <UDBreadcrumbs className="my-10" breadcrumbs={breadcrumbsItems} />
  );

  return (
    <UDMainLayout headerContent={breadcrumbs}>
      <div className="container py-6">
        <TourneysComponentsEditFormTabs tourney={champ} />
      </div>
      <TourneyComponentsEditForm tourney={champ} />
    </UDMainLayout>
  );
};

export default TourneyPagesEditPage;
