import styled from '@emotion/styled';

export const MenuMainNavigation = styled.div`
  & .nav-item img {
    color: white;
  }
  & .nav-item {
    height: 40px;
    color: ${({ theme }) => theme.colors.SurfaceWhite};
    background-color: ${({ theme }) => theme.colors.SurfaceIndependence40};
    &.active,
    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.AccentNaplesYellow};
    }
    &.active,
    &:active {
      background-color: ${({ theme }) => theme.colors.SurfaceSpaceCadet50};
    }
    &.disabled {
      color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
    }
  }

  & .recently-added li {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
