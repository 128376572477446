import { Game } from '../../../domain/interfaces/Game';
import GamesComponentsGameCard from '../card';
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import styled from '@emotion/styled';

const StyledEmptyGames = styled.div`
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
  margin-top: 30px;
`;

export const StyledGameCard = styled(GamesComponentsGameCard)`
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledGameDateHeader = styled.div`
  padding: 8px;
  background: ${({ theme }) => theme.colors.SurfacePlatinum20};
  color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};
  border-radius: 8px 8px 0px 0px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-transform: uppercase;

  display: flex;
`;

type GamesListProps = {
  games: Game[];
  activeGameId?: number;
  championshipId: number;
  onEditGameClick?: (game: Game) => void;
  onShowGameResultClick?: (gameId: number) => void;
  onShowGameTemplatesClick?: (gameId: number) => void;
  onDeleteGameClick?: (gameId: number) => void;
  onShowMomentsClick?: (gameId: number) => void;
};

const GamesList = (props: GamesListProps) => {
  const {
    games,
    activeGameId,
    championshipId,
    onEditGameClick,
    onShowGameResultClick,
    onShowGameTemplatesClick,
    onShowMomentsClick,
    onDeleteGameClick,
  } = props;

  if (!games.length) {
    return <StyledEmptyGames>Список матчей пуст</StyledEmptyGames>;
  }

  const separator = '--';
  const gameKeys = Object.entries(
    _(games)
      .groupBy((game) => {
        const date = format(new Date(game.date), 'd MMM, eee', { locale: ru });
        return date + separator + game.round?.name;
      })
      .value()
  );

  return (
    <div>
      {gameKeys.map(([dateKey, groupGames]) => (
        <div className="mb-6 last-mb-0" key={dateKey}>
          <StyledGameDateHeader>
            <span style={{ minWidth: 140 }}>{dateKey.split(separator)[0]}</span>
            <span style={{ fontWeight: 'bold' }}>
              {dateKey.split(separator)[1]}
            </span>
          </StyledGameDateHeader>
          {groupGames.map((game) => (
            <StyledGameCard
              key={game.id}
              game={game}
              championshipId={championshipId}
              onEditClick={onEditGameClick}
              onShowResultClick={onShowGameResultClick}
              onShowTemplatesClick={onShowGameTemplatesClick}
              onShowMomentsClicked={onShowMomentsClick}
              onDeleteClick={onDeleteGameClick}
              className={classNames('last-mb-0', {
                active: game.id === activeGameId,
              })}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default GamesList;
