import React, { useCallback, useMemo, useState } from 'react';

import { ChampionshipStateData } from '../../../../store/championship';
import { GameResult } from '../../../../../games/ui/components/game-result';
import MomentsPage from '../../../../../games/ui/components/moments/moments-page';
import { Navigate } from 'react-router-dom';
import StageGrid from './stage-grid/stage-grid';
import StageInfoForm from './stage-info-form';
import StageMatches from './matches/stage-matches';
import { StageType } from '../../../../domain/enums/StageType';
import { TabMenu } from '../../../../../ud-ui/components/tab-menu';
import TeamGroupsForm from './team-groups/team-groups-form';
import Template from './cover-templates/template';
import _ from 'lodash';
import classNames from 'classnames';
import { selectedStageSelector } from '../../../../store/stages';
import { useAcceptedChampionshipTeams } from '../../../hooks/use-accepted-championship-teams';
import { useCheckAcceptedTeams } from '../../../hooks/use-check-accepted-teams';
import { useSelector } from 'react-redux';

const extractTeamName = (
  gameTeam: { name: string } | null | undefined
): string => {
  if (!gameTeam) {
    return 'Неизвестно';
  }

  return gameTeam.name;
};

type StagesFormProps = {
  championship: ChampionshipStateData;
};

const defaultTabs = [
  {
    name: 'Редактирование',
    active: true,
  },
  {
    name: 'Команды',
    active: false,
  },
  {
    name: 'Сетка матчей',
    active: false,
  },
  {
    name: 'Календарь матчей',
    active: false,
  },
];

type Pages = 'result' | 'templates' | 'moments';

const validTypeForGrid = [
  StageType.SINGLE_ELIMINATION,
  StageType.DOUBLE_ELIMINATION,
  StageType.GROUP,
];

const StageForms = ({ championship }: StagesFormProps) => {
  const { teams } = useAcceptedChampionshipTeams(championship.id);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedGameId, selectGame] = useState<number | null>(null);
  const [additionalPage, setAdditionalPage] = useState<Pages | null>(null);

  const stage = useSelector(selectedStageSelector);
  useCheckAcceptedTeams(championship.id);

  const { id: championshipId, tournamentId } = championship;

  const tabs: { name: string; active: boolean }[] = useMemo(() => {
    const tabs = _.cloneDeep(defaultTabs);

    if (!selectedGameId) {
      return tabs;
    }

    const game = stage?.matches.find((match) => match.id === selectedGameId);
    if (game) {
      const [firstTeam, secondTeam] = game.teams || [];

      return [
        ...tabs,
        {
          name: `Матч: ${extractTeamName(firstTeam)} - ${extractTeamName(
            secondTeam
          )}`,
          active: true,
        },
      ];
    }

    return tabs;
  }, [selectedGameId, stage?.matches]);

  const handleChangeActiveTab = useCallback(
    (index: number) => {
      if (!stage) {
        return;
      }

      setActiveTab(index);
      if (index !== 4) {
        selectGame(null);
        setAdditionalPage(null);
      }
    },
    [stage, setActiveTab]
  );

  const handleSelectGameId = useCallback((gameId: number, page: Pages) => {
    selectGame(gameId);
    setActiveTab(4);
    setAdditionalPage(page);
  }, []);

  const formNode = useMemo(() => {
    if (stage && activeTab === 1) {
      return <TeamGroupsForm stage={stage} allTeams={teams || []} />;
    }

    if (stage && activeTab === 2) {
      return <StageGrid stage={stage} />;
    }

    if (stage && activeTab === 3) {
      return (
        <StageMatches
          stage={stage}
          championshipId={championshipId}
          tournamentId={tournamentId}
          onShowResultsClick={(gameId) => handleSelectGameId(gameId, 'result')}
          onShowTemplatesClick={(gameId) =>
            handleSelectGameId(gameId, 'templates')
          }
          onShowMomentsClick={(gameId) => handleSelectGameId(gameId, 'moments')}
        />
      );
    }

    if (stage && selectedGameId && additionalPage && activeTab === 4) {
      switch (additionalPage) {
        case 'result':
          return (
            <GameResult
              gameId={selectedGameId}
              championshipId={championshipId}
            />
          );
        case 'templates':
          const game = stage.matches.find(
            (match) => match.id === selectedGameId
          );
          if (!game) return <Navigate to={'/tourneys'} />;
          return <Template game={game} championship={championship} />;
        case 'moments':
          return (
            <MomentsPage
              gameId={selectedGameId}
              championshipId={championship.id}
            />
          );
      }
    }

    return <StageInfoForm championshipId={championshipId} stage={stage} />;
  }, [
    stage,
    activeTab,
    selectedGameId,
    additionalPage,
    championshipId,
    teams,
    tournamentId,
    handleSelectGameId,
    championship,
  ]);

  const activeTabs = useMemo(() => {
    const resultTabs = tabs.slice();

    if (!stage) {
      return resultTabs;
    }

    resultTabs[1].active = true;

    if (stage.teams.length < 2) {
      return resultTabs;
    }

    resultTabs[3].active = true;

    const isHasGrid = stage?.type && validTypeForGrid.includes(stage?.type);
    if (isHasGrid) {
      resultTabs[2].active = true;
    }

    return resultTabs;
  }, [stage, tabs]);

  return (
    <>
      <div className="mb-5">
        <TabMenu
          tabs={activeTabs}
          activeTab={activeTab}
          onChange={handleChangeActiveTab}
        />
      </div>
      <div
        className={classNames('container', {
          'flex-column h-100': activeTab === 4,
        })}
      >
        {formNode}
      </div>
    </>
  );
};

export default StageForms;
