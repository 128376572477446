import { ChampionshipSettings, ChampionshipSettingsType } from '../interfaces/ChampionshipSettings';
import { Game, GameTeam } from '../../../games/domain/interfaces/Game';
import { GameDTO, TeamMatchDTO } from '../../../games/domain/interfaces/GameDTO';

import { ChampionshipSettingDTO } from '../interfaces/ChampionshipSettingDTO';
import { PlayerPosition } from 'modules/teams/domain/enums/PlayerPosition';
import { SportType } from '../../../core/domain/enums/SportType';
import { TeamDTO } from '../../../teams/domain/dtos/Team.dto';
import { TeamPlayer } from '../../../teams/domain/interfaces/TeamPlayer';
import { TeamPlayersNumber } from '../../../../shared/types/team-players-number';
import { format } from 'date-fns';
import { mapLocationDtoToLocation } from '../../../locations/domain/mappers/location-data-mapper';

const defaultPlayerAvatar = 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';

const mapPlayerPosition = (position?: string): PlayerPosition => {
  const positions = ['defender', 'forward', 'goalkeeper', 'midfielder'];

  if (positions.includes(position || '')) {
    return position as PlayerPosition;
  }

  return PlayerPosition.MIDFIELDER;
}

export const mapperPlayer = (player: Partial<TeamPlayer>): TeamPlayer => ({
  id: player.id ?? 0,
  userId: player.userId ?? 0,
  firstName: player.firstName ?? '',
  lastName: player.lastName ?? '',
  middleName: player.middleName ?? '',
  birthDate: player.birthDate ?? '',
  position: mapPlayerPosition(player.position),
  number: player.number ?? 99,
  avatar: player.avatar ?? defaultPlayerAvatar,
});

export const mapTeamDTOToTeam = (dto: TeamDTO, score: number | null): GameTeam => ({
  id: dto.id,
  name: dto.name,
  logo: dto.emblem
    ? {
      id: dto.emblem.id,
      url: dto.emblem.externalUrl,
    }
    : undefined,
  createdAt: dto.createdAt ? format(new Date(dto.createdAt), 'dd.MM.yyyy') : undefined,
  players: dto.members
    ? dto.members.map((member) => mapperPlayer({
      id: member.id,
      userId: member.user.id,
      firstName: member.user.name,
      lastName: member.user.surname,
      middleName: member.user.middleName,
      birthDate: member.user.birthDate,
      position: member.position as PlayerPosition | undefined,
      number: member.number,
      avatar: member.user.photo?.externalUrl,
    } as TeamPlayer))
    : [] as TeamPlayer[],
  score,
});

export const mapGameTeams = (
  gameId: number,
  teams: [TeamDTO | null, TeamDTO | null],
  teamMatches: [TeamMatchDTO, TeamMatchDTO],
): [GameTeam | null, GameTeam | null] => {
  const mappedTeams = teamMatches.map((teamMatch) => {
    if (!teamMatch.team) {
      return null;
    }

    const team = teams.find((t) => t && t.id === teamMatch.team!.id);
    if (!team) {
      return null;
    }

    return mapTeamDTOToTeam(team, teamMatch.score);
  });

  return [mappedTeams[0], mappedTeams[1]];
};

export const mapGameDTOToGame = (match: GameDTO): Game => {
  return {
    id: match.id,
    sportType: match.sportType as SportType,
    date: new Date(match.date),
    location: match.location ? mapLocationDtoToLocation(match.location) : undefined,
    teams: mapGameTeams(match.id, match.teams, match.teamMatches),
    hasResults: match.teamMatches.some((result) => result.score !== null),
    round: match.round
      ? {
        id: match.round.id,
        name: match.round.name,
        stageId: match.round.stage.id,
      }
      : undefined,
  };
}

// Base settings mapper interface
interface IChampionshipSettingsMapper {
  map(settings?: ChampionshipSettingDTO[] | null): ChampionshipSettings;
}

// Base implementation with common settings for all sports
class BaseChampionshipSettingsMapper implements IChampionshipSettingsMapper {
  protected findSettingValue(key: ChampionshipSettingsType, settings: ChampionshipSettingDTO[]): string | null {
    const setting = settings.find(({ name }) => name === key);
    return setting && setting.value !== 'null' ? setting.value : null;
  }

  protected parseString(value: string | null): string | null {
    return value || null;
  }

  protected parseNumber(value: string | null): number | null {
    return value ? Number(value) : null;
  }

  protected parseBoolean(value: string | null): boolean {
    return !!value && value === 'true';
  }

  protected mapRanking(settings: ChampionshipSettingDTO[], additionalRankingKeys?: ChampionshipSettingsType[]): Array<{ name: string; value: number }> {
    const rankingKeys = [
      ChampionshipSettingsType.RANKING_POINTS_PERSONAL_MEETINGS,
      ChampionshipSettingsType.RANKING_WINS_PERSONAL_MEETINGS,
      ChampionshipSettingsType.RANKING_WINS_TOTAL,
    ];

    if (additionalRankingKeys) {
      rankingKeys.push(...additionalRankingKeys);
    }

    return rankingKeys
      .map((key, index) => {
        const settingValue = this.findSettingValue(key, settings);
        const value = settingValue ? this.parseNumber(settingValue) : null;

        return {
          name: key as string,
          value: value ?? index,
        };
      })
      .sort((a, b) => a.value - b.value);
  }

  map(settings?: ChampionshipSettingDTO[] | null): ChampionshipSettings {
    const map = settings || [];
    const ranking = this.mapRanking(map);
    const teamPlayersNumber = this.parseNumber(this.findSettingValue(ChampionshipSettingsType.TEAM_PLAYERS_NUMBER, map));

    return {
      teamPlayersNumber: teamPlayersNumber || TeamPlayersNumber.FIVE,

      applicationStartDate: this.parseString(this.findSettingValue(ChampionshipSettingsType.APPLICATION_START_DATE, map)),
      applicationEndDate: this.parseString(this.findSettingValue(ChampionshipSettingsType.APPLICATION_END_DATE, map)),
      applicationMinPlayers: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.APPLICATION_MIN_PLAYERS, map)),
      applicationMaxPlayers: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.APPLICATION_MAX_PLAYERS, map)),
      applicationTransfersAllowed: this.parseBoolean(this.findSettingValue(ChampionshipSettingsType.APPLICATION_TRANSFERS_ALLOWED, map)),
      participationFee: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.PARTICIPATION_FEE, map)),

      winScore: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.WIN_SCORE, map)),
      winScoreExtraTime: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.WIN_SCORE_EXTRA_TIME, map)),
      winPenaltySeries: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.WIN_PENALTY_SERIES, map)),

      lossScore: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.LOSS_SCORE, map)),
      lossScoreExtraTime: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.LOSS_SCORE_EXTRA_TIME, map)),
      lossPenaltySeries: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.LOSS_PENALTY_SERIES, map)),
      technicalDefeatGoalsCount: this.parseBoolean(this.findSettingValue(ChampionshipSettingsType.TECHNICAL_DEFEAT_GOALS_COUNT, map)),

      drawScore: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.DRAW_SCORE, map)),

      gamePeriodsNumber: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.GAME_PERIODS_NUMBER, map)),
      periodTime: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.PERIOD_TIME, map)),
      gameExtraPeriodsNumber: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.GAME_EXTRA_PERIODS_NUMBER, map)),
      periodExtraTime: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.PERIOD_EXTRA_TIME, map)),

      ranking,
      overlay: this.parseString(this.findSettingValue(ChampionshipSettingsType.OVERLAY, map)),
      overlayId: this.parseNumber(this.findSettingValue(ChampionshipSettingsType.OVERLAY_ID, map)),
    };
  }
}

// Football specific settings mapper
class FootballChampionshipSettingsMapper extends BaseChampionshipSettingsMapper {
  override map(settings?: ChampionshipSettingDTO[] | null): ChampionshipSettings {
    const baseSettings = super.map(settings);
    const map = settings || [];

    // Добавляем футбольные специфичные настройки
    return {
      ...baseSettings,
      ranking: [
        ...this.mapRanking(map, [
          ChampionshipSettingsType.RANKING_SCORED_CONCEDED_DIFF_PERSONAL_MEETINGS,
          ChampionshipSettingsType.RANKING_SCORED_GOALS_PERSONAL_MEETINGS,
          ChampionshipSettingsType.RANKING_SCORED_CONCEDED_DIFF_TOTAL,
          ChampionshipSettingsType.RANKING_SCORED_GOALS_TOTAL,
        ]),
      ],
    };
  }
}

// Фабрика для создания нужного маппера в зависимости от типа спорта
class ChampionshipSettingsMapperFactory {
  static createMapper(sportType?: SportType): IChampionshipSettingsMapper {
    switch (sportType) {
      case SportType.football:
        return new FootballChampionshipSettingsMapper();
      default:
        return new BaseChampionshipSettingsMapper();
    }
  }
}

// Обновленная функция mapChampionshipSettings, использующая фабрику
export const mapChampionshipSettings = (
  settings?: ChampionshipSettingDTO[] | null,
  sportType?: SportType
): ChampionshipSettings => {
  const mapper = ChampionshipSettingsMapperFactory.createMapper(sportType);
  return mapper.map(settings);
};

function camelToUnderscore(key: string): string {
  const result = key.replace(/([A-Z])/g, " $1");
  return result.split(' ').join('_').toLowerCase();
}

export const mapChampionshipSettingsToDTO = (settings: ChampionshipSettings): Array<{
  name: string,
  value: string
}> => {
  const { ranking = [], ...otherSettings } = settings;
  const settingsObj = otherSettings as any;

  const result = Object.keys(otherSettings)
    .filter((propertyName) => settingsObj[propertyName] !== null)
    .map((propertyName) => {
      const name = camelToUnderscore(propertyName);
      const value = settingsObj[propertyName];

      return {
        name,
        value: (value instanceof Date) ? value.toISOString() : String(value),
      };
    });

  ranking.forEach((item, index) => {
    result.push({
      name: item.name,
      value: String(index),
    });
  });

  return result;
}
