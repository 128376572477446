import { LabeledInput, SectionTitle } from '../fields';
import React, { ReactNode } from 'react';

import { GameRuleProps } from 'modules/tourneys/ui/components/form-parts/rules/types';

interface ExtendedGameRuleProps extends GameRuleProps {
  children?: ReactNode;
}

const GameBase: React.FC<ExtendedGameRuleProps> = ({
  sportType = 'general',
  children,
}) => {
  return (
    <>
      <SectionTitle title="Игра" icon="⚙️" />

      <div className="row gx-4">
        <div className="col-6">
          <LabeledInput
            name="settings.gamePeriodsNumber"
            placeholder="0"
            label="Количество периодов в матче"
            alignRight={false}
            flexShrink={true}
          />

          <LabeledInput
            name="settings.periodTime"
            placeholder="0 мин"
            label="Длительность матча"
            containerClassName="d-flex align-items-center"
            alignRight={false}
            flexShrink={true}
          />
        </div>
        <div className="col-6">
          <LabeledInput
            name="settings.gameExtraPeriodsNumber"
            placeholder="0"
            label="Кол-во периодов дополнительного времени"
            alignRight={false}
            flexShrink={true}
          />

          <LabeledInput
            name="settings.periodExtraTime"
            placeholder="0 мин"
            label="Длительность дополнительного периода"
            containerClassName="d-flex align-items-center"
            alignRight={false}
            flexShrink={true}
          />
        </div>
      </div>

      {/* Место для дополнительных полей */}
      {children}
    </>
  );
};

export default GameBase;
