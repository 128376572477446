import * as yup from 'yup';

import { Form, Formik } from 'formik';
import {
  deleteStage,
  upsertStage,
} from '../../../../store/stages/stages.actions';
import { useCallback, useState } from 'react';

import { AppDispatch } from '../../../../../../store/store';
import { SelectOption } from 'modules/ud-form/components/select/component';
import { StageType } from '../../../../domain/enums/StageType';
import { StageV2 } from '../../../../domain/interfaces/Stage';
import { TFunction } from 'i18next';
import UDButton from '../../../../../ud-ui/components/button';
import UDFormInput from 'modules/ud-form/components/input';
import UDFormSelect from 'modules/ud-form/components/select';
import { showChampionshipStageForms } from '../../../../store/championship/actions';
import { stagesActions } from '../../../../store/stages';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useModal } from '../../../../../ud-ui/components/modal/useModal';
import { useTranslation } from 'react-i18next';

interface StageInfoFormValues {
  id?: number;
  name: string;
  type?: SelectOption;
}

const stageValidator: yup.SchemaOf<StageInfoFormValues> = yup.object().shape({
  id: yup.number().optional(),
  name: yup.string().required('Название этапа обязательно'),
  type: yup
    .object({
      label: yup.string().required('Тип этапа обязателен'),
      value: yup.string().oneOf(Object.values(StageType)),
    })
    .default(undefined)
    .required('Тип этапа обязателен'),
});

const placesOptions: SelectOption[] = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
];

const prepareTypeOptions = (t: TFunction): SelectOption[] => {
  return Object.values(StageType).map((key) => ({
    label: t(`roundType.${key}`),
    value: key,
  }));
};

const gridOptions: SelectOption[] = [
  {
    value: 1,
    label: 'Исходя из списка команд',
  },
];

type StageInfoFormProps = {
  stage: StageV2 | null;
  championshipId: number;
};

const StageInfoForm = ({ championshipId, stage }: StageInfoFormProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const {
    open: openDeleteModal,
    close: closeDeleteModal,
    Modal: ConfirmDeleteModal,
  } = useModal({ defaultShow: false });

  const typeOptions = prepareTypeOptions(t);
  const gridOption = {
    value: 1,
    label: 'Исходя из списка команд',
  };

  const initialValues = stage
    ? {
        id: stage.id,
        name: stage.name,
        type: {
          value: stage.type,
          label: t(`roundType.${stage.type}`),
        },
        grid: gridOption,
      }
    : { name: '', grid: gridOption };

  const handleSubmit = useCallback(
    (values: StageInfoFormValues) => {
      setIsSaving(true);

      dispatch(
        upsertStage({
          id: values.id,
          championshipId,
          name: values.name,
          type: values.type!.value as StageType,
          teamIds: stage?.teams.map((team) => team.id),
        })
      )
        .then((result) => {
          const updatedStage = unwrapResult(result);
          dispatch(stagesActions.stageSelected(updatedStage.id));
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [championshipId, dispatch, stage]
  );

  const handleDeleteStageClick = useCallback(() => {
    if (!stage?.id) {
      return;
    }

    setIsDeleting(true);

    dispatch(deleteStage(stage.id))
      .then(() => {
        dispatch(showChampionshipStageForms({ state: false, championshipId }));
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }, [championshipId, dispatch, stage?.id]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={stageValidator}
      onSubmit={handleSubmit}
    >
      {(formProps) => {
        return (
          <Form onSubmit={formProps.handleSubmit}>
            <div className="row gy-2">
              <div className="col-7">
                <UDFormInput
                  autoFocus={!Boolean(stage)}
                  name="name"
                  label="Название*"
                  placeholder="Название этапа"
                />
              </div>
              <div className="col-7">
                <div className="row gx-2">
                  <div className="col-6">
                    <UDFormSelect
                      name="type"
                      label="Тип этапа*"
                      placeholder="Выберите этап"
                      options={typeOptions}
                    />
                  </div>
                  <div className="col-6">
                    <UDFormSelect
                      name="place"
                      label="Игра за место"
                      placeholder="Выберите место"
                      options={placesOptions}
                    />
                  </div>
                </div>
              </div>
              <div className="col-7">
                <UDFormSelect
                  name="grid"
                  label="Размер сетки"
                  placeholder="Выберите размер сетки"
                  options={gridOptions}
                />
              </div>
              <div className="col-7">
                <div className="row">
                  <UDButton
                    variant="primary"
                    type="submit"
                    disabled={
                      !formProps.dirty || !formProps.isValid || isSaving
                    }
                    loading={isSaving}
                    className="col-12 mt-3 mb-3"
                  >
                    Сохранить
                  </UDButton>
                </div>
                {stage?.id && (
                  <div className="row">
                    <UDButton
                      className="col-12 mt-3 mb-3"
                      variant="secondary"
                      type="button"
                      disabled={isSaving || isDeleting}
                      loading={isDeleting}
                      onClick={openDeleteModal}
                    >
                      Удалить
                    </UDButton>
                  </div>
                )}
              </div>
            </div>
            <ConfirmDeleteModal
              header={{
                title: 'Подтвердить удаление',
                subtitle: 'Вы действительно хотите удалить этап?',
              }}
            >
              <div className="mt-10 d-flex justify-content-between">
                <UDButton
                  variant="secondary"
                  className="mb-3"
                  type="button"
                  onClick={closeDeleteModal}
                >
                  Отмена
                </UDButton>

                <UDButton
                  variant="primary"
                  className="mb-3"
                  type="button"
                  onClick={handleDeleteStageClick}
                >
                  Удалить
                </UDButton>
              </div>
            </ConfirmDeleteModal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StageInfoForm;
