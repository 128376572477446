import { RootState } from 'store/store';
import { TeamPlayersNumber } from '../../../../shared/types/team-players-number';
import { createSelector } from '@reduxjs/toolkit';

export const tourneysChampionshipStoreSelector = (state: RootState) => state.tourneys.championship;

export const championshipIsLoadingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.isLoading,
);

export const championshipIsLoadedSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.isLoaded,
);


export const championshipTeamsIsLoadingSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.teamsFlags.isLoading || false,
);

export const championshipTeamsIsLoadedSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.teamsFlags.isLoaded || false,
);

export const championshipStagesIsLoadingSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.stagesFlags.isLoading || false,
);

export const championshipStagesIsLoadedSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.stagesFlags.isLoaded || false,
);

export const championshipStagesDataSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.stages || [],
);

export const championshipTeamsDataSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.teams || [],
);

export const championshipApplicationsDataSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.applications || [],
);


export const championshipISavingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.isSaving,
);

export const championshipDataSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId],
);

export const allIdsChampionshipsDataSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.allIds,
);

export const updatingApplicationTeamIdSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.updatingApplicationTeamId,
);

export const championshipStagesSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.stages || [],
);

export const isStageSavingSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.isStageSaving || false,
);

export const championshipGamesIsLoadingSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.gamesFlags.isLoading,
);

export const championshipGamesIsLoadedSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.gamesFlags.isLoaded,
);

export const championshipGamesSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.games || [],
);

export const championshipIsMatchSavingSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.isMatchSaving || false,
);

export const championshipSymbolicTeamSelectedSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.selectedSymbolicTeam || null,
);

export const championshipSymbolicTeamIsSavingSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.isSymbolicTeamSaving || false,
);

export const championshipTeamPlayersNumberSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.settings.teamPlayersNumber || TeamPlayersNumber.FIVE,
);

export const championshipSettingsSelector = (championshipId: number) => createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.byId[championshipId]?.settings,
);

export const championshipIsDeletingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.isDeleting,
);
