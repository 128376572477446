import UDButton from 'modules/ud-ui/components/button';
import UDFormLabel from '../../../../../modules/ud-form/components/label';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  showDeleteButton?: boolean;
};

function InfoFormSkeleton(props: Props) {
  const { t } = useTranslation();
  const { showDeleteButton = false } = props;

  return (
    <div className="container g-2 mb-5">
      <div className="col-6">
        <ItemSkeleton
          label={t('championship.info.nameInput.label')}
          className="mb-5"
        />
        <div className="d-flex gap-6 w-100">
          <ItemSkeleton
            label={t('championship.info.startDateInput.label')}
            className="mb-5"
          />
          <ItemSkeleton
            label={t('championship.info.endDateInput.label')}
            className="mb-5"
          />
        </div>
        <ItemSkeleton
          label={t('championship.info.typeSelect.label')}
          className="mb-5"
        />
        <ItemSkeleton
          label={t('championship.info.locationInput.label')}
          className="mb-5"
        />
        <ItemSkeleton
          label={t('championship.info.overlayInput.label')}
          className="mb-5"
        />
        <ItemSkeleton
          label={t('championship.info.descriptionInput.label')}
          className="mb-5"
          height="58px"
        />

        <div className="d-flex gap-6">
          <UDButton
            variant="primary"
            type="button"
            disabled
            className="w-100"
            style={{ cursor: 'not-allowed', zIndex: 90000 }}
          >
            {t('championship.info.saveButton.title')}
          </UDButton>

          {showDeleteButton && (
            <UDButton
              variant="secondary"
              type="button"
              disabled
              style={{
                color: 'lightcoral',
                outline: 'none',
                cursor: 'not-allowed',
              }}
              className="w-100"
            >
              {t('championship.info.deleteButton.title')}
            </UDButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default InfoFormSkeleton;

type ItemSkeletonProps = {
  label: string;
  className?: string;
  height?: string;
};

const ItemSkeleton = (props: ItemSkeletonProps) => {
  return (
    <div className={classNames('d-flex flex-column w-100', props.className)}>
      <UDFormLabel className="mb-2">{props.label}</UDFormLabel>
      <div
        className="skeleton-block w-100"
        style={{ height: props.height || '38px' }}
      />
    </div>
  );
};
