import React, { useCallback } from 'react';
import {
  getStagesChampionshipIdSelector,
  isStagesIdleSelector,
  isStagesLoadedSelector,
  isStagesLoadingSelector,
  stageSelectors,
  stagesActions,
} from '../../../../store/stages';
import { useDispatch, useSelector } from 'react-redux';

import { ChampionshipStateData } from '../../../../store/championship';
import StageCard from './stage-card';
import StageForms from './stage-forms';
import { StageV2 } from '../../../../domain/interfaces/Stage';
import { StagesGrid } from './stages-grid';
import { StagesGridSkeleton } from './stages-grid-skeleton';
import UDButton from '../../../../../ud-ui/components/button';
import UDIcon from '../../../../../ud-ui/components/icon';
import { fetchStages } from '../../../../store/stages/stages.actions';
import { showChampionshipStageForms } from '../../../../store/championship/actions';
import styled from '@emotion/styled';

const StyledAddStageBlock = styled.div`
  border: 2px dashed #8f8e99;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 157px;
`;

export const StyledAddStageButton = styled(UDButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type StagesProps = {
  championship: ChampionshipStateData;
};

const Stages = ({ championship }: StagesProps) => {
  const dispatch = useDispatch();

  const isIdle = useSelector(isStagesIdleSelector);
  const isLoading = useSelector(isStagesLoadingSelector);
  const isLoaded = useSelector(isStagesLoadedSelector);
  const stages = useSelector(stageSelectors.selectAll);
  const loadedStagesChampionshipId = useSelector(
    getStagesChampionshipIdSelector
  );

  if (
    championship &&
    (isIdle || (isLoaded && loadedStagesChampionshipId !== championship.id))
  ) {
    dispatch(fetchStages(championship.id));
  }

  const { showStageForms } = championship;

  const handleAddStageClick = useCallback(() => {
    dispatch(
      showChampionshipStageForms({
        state: true,
        championshipId: championship.id,
      })
    );
  }, [dispatch, championship.id]);

  const handleStageSelect = useCallback(
    (stage: StageV2) => () => {
      dispatch(stagesActions.stageSelected(stage.id));
      dispatch(
        showChampionshipStageForms({
          state: true,
          championshipId: championship.id,
        })
      );
    },
    [dispatch, championship.id]
  );

  if (isLoading || !championship) {
    return (
      <div className="container">
        <StagesGridSkeleton />
      </div>
    );
  }

  if (!showStageForms) {
    return (
      <div className="container">
        <StagesGrid>
          {stages?.map((stage) => (
            <StageCard
              key={stage.id}
              stage={stage}
              onSelect={handleStageSelect(stage)}
            />
          ))}
          <StyledAddStageBlock>
            <StyledAddStageButton
              className="col-6 mt-3 mb-3"
              variant="dark"
              type="button"
              onClick={handleAddStageClick}
            >
              <UDIcon name="plus" />
              &nbsp;Создать этап
            </StyledAddStageButton>
          </StyledAddStageBlock>
        </StagesGrid>
      </div>
    );
  }

  return <StageForms championship={championship} />;
};

export default Stages;
