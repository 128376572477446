import { changePlayerRole, deleteTournamentPlayerApplication, getTeamPlayers, upsertTournamentPlayerApplication } from './actions';

import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { Role } from 'shared/types/role';
import { TournamentUser } from '../../domain/interfaces/TournamentUser';
import { toast } from "react-toastify";

export type PlayersSliceState = {
    isGettingPlayers: boolean;
    isLoading: boolean;
    info: TournamentUser[];
};

type PlayerReducerBuilder = ActionReducerMapBuilder<PlayersSliceState>;

export function createGetTournamentPlayers(builder: PlayerReducerBuilder) {
    builder.addCase(getTeamPlayers.pending, (state) => {
        state.isGettingPlayers = true;
    });

    builder.addCase(getTeamPlayers.fulfilled, (state, { payload }) => {
        state.info = payload.sort((a, b) => b.id - a.id);
        state.isGettingPlayers = false;
    },);

    builder.addCase(getTeamPlayers.rejected, (state) => {
        state.isGettingPlayers = false;
    });
}

export function createUpsertTournamentPlayerApplication(
    builder: PlayerReducerBuilder,
) {
    builder.addCase(upsertTournamentPlayerApplication.pending, (state) => {
        state.isLoading = true;
    });

    builder.addCase(
        upsertTournamentPlayerApplication.fulfilled,
        (state, { payload }) => {
            if (state.info) {
                const editingPlayer = state.info.find(
                    (player) => player.id === payload.id,
                );
                let players;

                if (editingPlayer) {
                    const editingPlayerIndex = state.info.indexOf(editingPlayer);
                    state.info.splice(editingPlayerIndex, 1, payload);
                    players = [...state.info].sort((a, b) => b.id - a.id);
                } else {
                    players = [...state.info, payload].sort((a, b) => b.id - a.id);
                }

                if (players?.length) {
                    state.info = [...players];
                }
            }
            state.isLoading = false;
        },
    );

    builder.addCase(upsertTournamentPlayerApplication.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (payload === 'User is already in this team') {
            toast.error('Игрок уже в этой команде');
        } else if (payload === 'Tournament user created') {
            toast.error('Такой игрок уже существует');
        } else {
            toast.error('Не удалось выполнить операцию');
        }
    });
}

export function createDeleteTournamentPlayerApplication(
    builder: PlayerReducerBuilder,
) {
    builder.addCase(deleteTournamentPlayerApplication.pending, (state) => {
        state.isLoading = true;
    });

    builder.addCase(
        deleteTournamentPlayerApplication.fulfilled,
        (state, { payload }) => {
            if (state.info) {
                const deletingPlayer = state.info.find(
                    (player) => player.id === payload.userId,
                );
                let players;

                if (deletingPlayer) {
                    const deletingPlayerIndex = state.info.indexOf(deletingPlayer);
                    state.info.splice(deletingPlayerIndex, 1);
                    players = [...state.info].sort((a, b) => b.id - a.id);
                } else {
                    players = [...state.info].sort((a, b) => b.id - a.id);
                }

                if (players?.length) {
                    state.info = [...players];
                } else {
                    state.info = [];
                }
            }
            state.isLoading = false;
        },
    );

    builder.addCase(deleteTournamentPlayerApplication.rejected, (state) => {
        state.isLoading = false;
        toast.error('Не удалось выполнить операцию')
    });
}

export function createChangePlayerRole(builder: PlayerReducerBuilder) {
    builder.addCase(changePlayerRole.fulfilled, (state, { payload, meta }) => {
        if (state.info) {
            const changingPlayer = state.info.find(
                (player) => player.id === meta.arg.userId,
            );

            if (changingPlayer) {
                changingPlayer.role = meta.arg.role as Role;
            }
        }
    });
}
