import { useCallback, useMemo, useState } from 'react';

import { AppDispatch } from '../../../../../store/store';
import { Organizer } from '../../../domain/interfaces/Organizer';
import UDButton from '../../../../ud-ui/components/button';
import UDInput from '../../../../ud-form/components/input/component';
import UDText from '../../../../ud-ui/components/text';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { topupBalance } from '../../../store/actions';
import { useDispatch } from 'react-redux';
import useModal from '../../../../ud-ui/components/new-modal/useModal';

interface IProps {
  organizer: Organizer | undefined;
}

function Balance(props: IProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { organizer } = props;
  const { open, close, Modal } = useModal();
  const [amount, setAmount] = useState<number>(0);

  const handleOpen = useCallback(() => {
    setAmount(0);

    open({
      modalStyle: {
        padding: 0,
        zIndex: 9000,
        position: 'fixed',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderColor: 'transparent',
        overflow: 'clip',
        background: '#00000085',
      },
    });
  }, [open]);

  const handleTopup = useCallback(() => {
    if (!organizer?.adminUser?.id) {
      toast.error('Не определить пользователя');
      console.error({
        message: "Can't identify user",
        adminUser: organizer?.adminUser,
        organizer,
      });
      return;
    }

    if (amount <= 0) {
      toast.error('Сумма должна быть больше 0');
      return;
    }

    dispatch(
      topupBalance({
        amount,
        recipientId: organizer?.adminUser?.id,
        tournamentId: organizer.id,
      })
    )
      .unwrap()
      .then(close);
  }, [amount, close, dispatch, organizer]);

  const balance = useMemo(() => {
    return organizer?.adminUser?.balance
      ? (organizer?.adminUser?.balance / 100).toFixed(2)
      : '0.00';
  }, [organizer]);

  return (
    <>
      <div className={'d-flex align-items-center'} style={{ gap: 20 }}>
        <UDText type={'subhead'}>Баланс: {balance} руб.</UDText>
        <UDButton
          style={{ height: 36 }}
          disabled={!organizer}
          onClick={handleOpen}
          type={'button'}
          variant={'primary'}
        >
          Пополнить баланс
        </UDButton>
      </div>

      <Modal>
        <form
          className={'d-flex flex-column w-100'}
          onSubmit={(event) => {
            event.preventDefault();
            handleTopup();
          }}
        >
          <UDText className={'mb-4'} type={'headline'}>
            Пополнить баланс
          </UDText>
          <UDText className={'mb-7'} type={'subhead'}>
            Текущий баланс: {balance} руб.
          </UDText>
          <div
            className={'d-flex align-items-center w-100 mb-7'}
            style={{ gap: 20 }}
          >
            <UDText type={'subhead'} style={{ textWrap: 'nowrap' }}>
              Сумма в рублях
            </UDText>
            <UDInput
              autoFocus
              style={{ backgroundColor: '#F2F2F2' }}
              wrapperProps={{ className: classNames('w-100') }}
              placeholder={'Введите сумму'}
              value={amount}
              onChange={(event) => setAmount(Number(event.target.value))}
            />
          </div>
          <div
            className={
              'd-flex align-items-center w-100 justify-content-between gap-10'
            }
          >
            <UDButton className={'w-100'} onClick={close} variant={'secondary'}>
              Отменить
            </UDButton>
            <UDButton
              className={'w-100'}
              onClick={handleTopup}
              variant={'primary'}
            >
              Пополнить
            </UDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default Balance;
