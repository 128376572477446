import UDModal, { UDModalProps } from '.';
import { useCallback, useState } from 'react';

export const useModal = (props: { defaultShow: boolean }) => {
  const [shown, setShown] = useState(props.defaultShow);

  const close = useCallback(() => {
    setShown(false);
  }, []);

  const open = useCallback(() => {
    setShown(true);
  }, []);

  const Modal = (modalProps: Omit<UDModalProps, 'show'>) => {
    const { onClose, ...otherProps } = modalProps;

    const handleClose = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        if (onClose) {
          onClose(event as any);
        }

        close();
      },
      [onClose]
    );

    return <UDModal {...otherProps} show={shown} onClose={handleClose} />;
  };

  return { close, open, Modal };
};
