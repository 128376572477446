import React, { useMemo } from 'react';
import { TourneyEditFormPart, defaultTab } from './constants';
import { selectedStageSelector, stagesActions } from '../../../store/stages';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';

import { AppDispatch } from '../../../../../store/store';
import { Championship } from 'modules/tourneys/domain/interfaces/Championship';
import { ChampionshipStateData } from '../../../store/championship/index';
import { Pages } from '../../../../navigation/domain/enums/pages';
import { Tab } from '../../../../ud-ui/components/tabs/interfaces';
import UDTabs from 'modules/ud-ui/components/tabs';
import UDText from '../../../../ud-ui/components/text';
import classNames from 'classnames';
import { showChampionshipStageForms } from '../../../store/championship/actions';

type CreateEditFormParams = {
  tab: Tab;
  count?: number;
  isActive?: boolean;
  subLabel?: string;
  action?: () => void;
  disabled?: boolean;
};

const createEditFormTab = (params: CreateEditFormParams): Tab => {
  const { tab, count, isActive, subLabel, action, disabled } = params;

  if (count !== undefined) {
    const badgeBgColor = isActive
      ? 'bgc-SurfaceWhite'
      : 'bgc-AccentNapplesYellow';
    const className = classNames([
      'color-SurfaceRaisinBlack50',
      badgeBgColor,
      'p-1',
      'br-4',
      'ml-2',
      'line-height-1',
      'caption-1',
    ]);

    return {
      hash: tab.hash,
      disabled,
      label: (
        <>
          {tab.label}{' '}
          <UDText type="subhead" className={className}>
            {count}
          </UDText>
        </>
      ),
    };
  }

  return { ...tab, subLabel, onClick: action, disabled };
};

type TourneyEditFormTabsProps = {
  tourney?: ChampionshipStateData;
};

const TourneyEditFormTabs = ({ tourney }: TourneyEditFormTabsProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();
  const activeTab = (location.hash || '').split('/')[0];
  const isTeam = useMatch(`${Pages.TOURNEYS.EDIT}/applications/:teamId`);
  const isStage = useMatch(`${Pages.TOURNEYS.EDIT}/steps`);
  const stage = useSelector(selectedStageSelector);

  let subLabel: string | undefined;
  let urlTab: string | undefined;
  let action = () => {};

  if (isTeam) {
    urlTab = 'applications';
    subLabel = tourney?.teams?.find(
      (t) => t.id === Number(isTeam.params.teamId)
    )?.name;
  }

  if (isStage) {
    urlTab = 'steps';
    subLabel =
      stage?.name || (tourney?.showStageForms ? 'Добавление этапа' : undefined);
    action = () => {
      if (!tourney) {
        return;
      }

      dispatch(stagesActions.stageSelected(null));
      dispatch(
        showChampionshipStageForms({ state: false, championshipId: tourney.id })
      );
    };
  }

  const tabs = useMemo(() => {
    return Object.values(TourneyEditFormPart).map((tab) =>
      createEditFormTab({
        tab: tab,
        isActive: tab.hash === activeTab,
        subLabel: urlTab === tab.hash ? subLabel : undefined,
        action: urlTab === tab.hash ? action : undefined,
        disabled: !tourney,
      })
    );
  }, [activeTab, urlTab, subLabel, tourney]);

  return <UDTabs tabs={tabs} defaultTab={defaultTab} />;
};

export default TourneyEditFormTabs;
