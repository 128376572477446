import * as styles from './game-result-events.list.styles';

import {
  GameDetails,
  GameDetailsTeamMember,
  GameEvent,
  GameEventType,
} from '../../../domain/interfaces/GameDetails';
import { deleteGameEvent, editGameEvent } from 'modules/games/store/actions';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ChampionshipSettings } from '../../../../tourneys/domain/interfaces/ChampionshipSettings';
import { GameEventDTO } from 'modules/games/domain/interfaces/GameEventDTO';
import { GameEventForm } from '../game-event-form';
import { GameResultEvent } from './game-result-event';
import { GameResultEventPeriod } from './game-result-events.list.styles';
import UDButton from 'modules/ud-ui/components/button';
import { championshipSettingsSelector } from '../../../../tourneys/store/championship/selectors';
import { getMembers } from '../../../utils/getMembers';
import { getPeriodsSettings } from '../../../domain/mappers/match-periods-settings';
import { useModal } from 'modules/ud-ui/components/modal/useModal';
import { useTranslation } from 'react-i18next';

type GameEventsListProps = {
  className?: string;
  isLoading: boolean;
  game: GameDetails | null;
  championshipId: number;
};

export const GameResultEventsList = (props: GameEventsListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSelector(
    championshipSettingsSelector(props.championshipId)
  );
  const periodSettings = getPeriodsSettings(
    settings || ({} as ChampionshipSettings)
  );

  const { Modal, open, close } = useModal({ defaultShow: false });
  const {
    Modal: ConfirmDeleteModal,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal({ defaultShow: false });

  const [editData, setEditData] = useState<
    | {
        values: {
          id: number;
          teamId: number;
          type?: GameEventType;
          period?: number;
          seconds?: number;
          firstPlayerId?: number;
          secondPlayerId?: number;
          videoUrl?: string;
        };
        members: GameDetailsTeamMember[];
      }
    | undefined
  >();

  const { className, isLoading, game } = props;

  const onEditClick = useCallback(
    (eventId: number) => {
      const events = game ? game.events : [];
      const event = events.find((e) => e.id === eventId);

      if (event) {
        const teamIndex = game!.teams.findIndex(
          (team) => team.id === event.teamId
        );

        setEditData({
          values: {
            id: event.id,
            teamId: event.teamId,
            type: event.type,
            period: event.period,
            seconds: event.seconds,
            firstPlayerId: event.playerIds[0] ?? undefined,
            secondPlayerId: event.playerIds[1] ?? undefined,
            videoUrl: event.videoUrl,
          },
          members: game!.teams[teamIndex].members,
        });

        open();
      }
    },
    [game, setEditData, open]
  );

  const handleSubmit = useCallback(
    (data: GameEventDTO) => {
      dispatch(
        editGameEvent({
          data,
          periodSettings,
        })
      );
      close();
    },
    [close, dispatch, periodSettings]
  );

  const handleDeleteEvent = useCallback(() => {
    close();
    openDeleteModal();
  }, [close, openDeleteModal]);

  const editEventForm = useMemo(() => {
    if (!editData) {
      return null;
    }

    return (
      <GameEventForm
        initialValues={editData.values}
        members={editData.members}
        onSubmit={handleSubmit}
        onDelete={handleDeleteEvent}
      />
    );
  }, [editData, handleDeleteEvent, handleSubmit]);

  const confirmDeleteEvent = useCallback(() => {
    if (!editData) {
      return;
    }

    dispatch(deleteGameEvent(editData.values.id));
    closeDeleteModal();
  }, [editData, dispatch, closeDeleteModal]);

  const cancelDeleteEvent = useCallback(() => {
    closeDeleteModal();
    open();
  }, [closeDeleteModal, open]);

  const isEmptyList = !isLoading && !(game && game.events.length);
  const events = !isLoading && game ? game.events : [];
  const members = game?.teams ? getMembers(game.teams) : {};

  const isLeftEvent = (event: GameEvent) => event.teamId === game?.teams[0].id;

  return (
    <styles.GameEventsList className={className}>
      {isEmptyList && (
        <div className="empty-list">{t('games.emptyGameResultEvents')}</div>
      )}
      {events.map((event, i) => (
        <>
          {events[i - 1]?.period !== event.period && (
            <GameResultEventPeriod>
              <div className="period-title">
                {t(`games.periods.${event.period}`)}
              </div>
            </GameResultEventPeriod>
          )}
          <GameResultEvent
            key={event.id}
            event={event}
            members={members}
            isLeft={isLeftEvent(event)}
            onEditClick={onEditClick}
          />
        </>
      ))}
      <Modal
        header={{ title: 'Редактировать событие матча', subtitle: '' }}
        containerProps={{ style: { width: '500px' } }}
      >
        {editEventForm}
      </Modal>
      <ConfirmDeleteModal
        header={{
          title: 'Подтвердить удаление',
          subtitle: 'Вы действительно хотите удалить событие?',
        }}
        onClose={open}
      >
        <div className="mt-10 d-flex justify-content-between">
          <UDButton
            variant="secondary"
            className="mb-3"
            type="button"
            onClick={cancelDeleteEvent}
          >
            Отмена
          </UDButton>

          <UDButton
            variant="primary"
            className="mb-3"
            type="button"
            onClick={confirmDeleteEvent}
          >
            Удалить
          </UDButton>
        </div>
      </ConfirmDeleteModal>
    </styles.GameEventsList>
  );
};
