import { Background, backgrounds } from 'shared/constants/backgrounds';
import { Formik, FormikHelpers } from 'formik';
import UDFormSelect, {
  UDFormAddressSelect,
} from '../../../../ud-form/components/select';
import {
  mapAddressToDadataArea,
  mapDadataAreaToAddress,
} from '../../../../../shared/features/dadata-address-autocomplete/infra/mappers/dadata-area.mapper';

import { AuthAvailableTournament } from 'modules/auth/domain/interfaces/AuthAvailableTournament';
import { OrganizerUpsertPayload } from '../../../domain/interfaces/OrganizerUpsertPayload';
import { SportType } from '../../../../core/domain/enums/SportType';
import { TFunction } from 'i18next';
import UDButton from '../../../../ud-ui/components/button';
import UDFormDropZoneCover from 'modules/ud-form/components/dropzone/UDFormDropZoneCover';
import UDFormInput from '../../../../ud-form/components/input';
import UDFormTextearea from '../../../../ud-form/components/input/textarea';
import UDInputPhone from 'modules/ud-form/components/input/phone';
import getAvailableTournamentTypes from 'shared/utils/getAvailableTournamntType';
import { isEmptyAddress } from '../../../../core/helpers/isEmptyAddress';
import { useAuth } from 'modules/auth/ui/hooks/useAuth';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type SportTypeOption = {
  label: string;
  value: string;
};

type DadataAreaType = any; // Заменить на правильный тип, когда будет доступен

export interface OrganizerFormValues {
  name: string;
  sportType: SportTypeOption;
  email?: string;
  phone?: string;
  address?: DadataAreaType;
  description?: string;
  logo: {
    img: string | File;
    background: Background;
  };
}

const sportTypeToSelectOption = (
  sportType: string,
  t: TFunction
): SportTypeOption => {
  const sportTypeKey = sportType.toLowerCase();
  return {
    label: t(`sportType.${sportTypeKey}`),
    value: sportTypeKey,
  };
};

const sportTypesToSelectOptions = (
  availableTournaments: AuthAvailableTournament[],
  t: TFunction
): SportTypeOption[] =>
  getAvailableTournamentTypes(availableTournaments).map((sportType) =>
    sportTypeToSelectOption(sportType, t)
  );

type OrganizerFormProps = {
  isLoading?: boolean;
  initialValues?: OrganizerUpsertPayload & { backgroundColor?: Background };
  onSubmit: (
    values: OrganizerUpsertPayload,
    formikHelpers: FormikHelpers<OrganizerFormValues>
  ) => void;
};

export const OrganizerForm = ({
  isLoading,
  initialValues,
  onSubmit,
}: OrganizerFormProps) => {
  const { t } = useTranslation();
  const { availableTournaments } = useAuth();
  const sportTypeOptions = sportTypesToSelectOptions(availableTournaments, t);
  const logoUrl = initialValues?.logoUrl;

  const initialFormValues: OrganizerFormValues = initialValues
    ? {
        ...initialValues,
        logo: {
          img: initialValues.logoUrl ?? '',
          background: initialValues.backgroundColor ?? backgrounds[0],
        },
        address: isEmptyAddress(initialValues.address)
          ? undefined
          : mapAddressToDadataArea(initialValues.address!),
        sportType: initialValues.sportType
          ? sportTypeToSelectOption(initialValues.sportType, t)
          : sportTypeToSelectOption(SportType.football, t),
      }
    : {
        name: '',
        sportType: sportTypeToSelectOption(SportType.football, t),
        phone: '',
        logo: { img: '', background: backgrounds[0] },
      };

  const handleSubmit = useCallback(
    (
      values: OrganizerFormValues,
      formikHelpers: FormikHelpers<OrganizerFormValues>
    ) => {
      const { address: changedAddress } = values;
      const address = changedAddress
        ? mapDadataAreaToAddress(changedAddress)
        : changedAddress;

      const data = {
        ...values,
        address,
        sportType: values.sportType.value,
      };

      onSubmit(data as OrganizerUpsertPayload, formikHelpers);
    },
    [onSubmit]
  );

  return (
    <Formik<OrganizerFormValues>
      enableReinitialize
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <div className="row g-2">
            <div className="col-6">
              <UDFormInput
                disabled={isLoading}
                name="name"
                label="Название организации"
                placeholder="Введите название"
                containerProps={{ className: 'mb-5' }}
              />

              <UDFormSelect
                isDisabled={isLoading}
                name="sportType"
                label="Тип спорта"
                placeholder="Выберите тип спорта"
                isMulti={false}
                options={sportTypeOptions}
                containerProps={{ className: 'mb-5' }}
              />

              <UDFormInput
                disabled={isLoading}
                name="email"
                label="Email"
                placeholder="Email"
                containerProps={{ className: 'mb-5' }}
              />

              <UDInputPhone
                name="phone"
                label="Телефон"
                placeholder="Введите номер телефона"
                wrapperProps={{ className: 'mb-6' }}
                value={formProps.values.phone}
              />

              <UDFormAddressSelect
                name="address"
                label="Город"
                placeholder={t('championship.info.addressInput.placeholder')}
                containerProps={{ className: 'mb-5' }}
                onlyCity={true}
              />

              <UDFormTextearea
                disabled={isLoading}
                name="description"
                label="Описание"
                placeholder="Введите текст"
                containerProps={{ className: 'mb-5' }}
              />
            </div>
            <div className="col-1"></div>
            <div className="col-5">
              <UDFormDropZoneCover
                disabled={isLoading}
                name="logo"
                label="Обложка и изображение"
                value={logoUrl ? [{ url: logoUrl }] : []}
                background={initialValues?.backgroundColor}
                formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-6 mb-2">
            <UDButton
              variant="primary"
              type="submit"
              className="mb-3"
              disabled={isLoading}
              loading={isLoading || formProps.isSubmitting}
            >
              Сохранить
            </UDButton>
          </div>
        </form>
      )}
    </Formik>
  );
};
