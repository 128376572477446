import React, { useCallback, useMemo, useState } from 'react';
import { selectCalendar, selectFilter } from '../../../../store/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../../../../store/store';
import UDButton from '../../../../../ud-ui/components/button';
import { UDSelect } from '../../../../../ud-form/components/select/component';
import UDText from '../../../../../ud-ui/components/text';
import _ from 'lodash';
import { components } from './styles';
import { setChampionshipFilter } from '../../../../store';
import useModal from '../../../../../ud-ui/components/new-modal/useModal';

function useFilterModal() {
  const dispatch = useDispatch<AppDispatch>();
  const { close, open, Modal } = useModal();
  const calendar = useSelector(selectCalendar);
  const filter = useSelector(selectFilter);
  const championships = useMemo(() => {
    const matches = Object.values(calendar)
      .flat()
      .map((day) => day.matches)
      .flat();
    return _(matches).map('championship.name').uniq().value();
  }, [calendar]);

  const [selectedChampionship, setSelectedChampionship] = useState(
    filter.championship
  );

  const onOpen = useCallback(() => {
    open({
      modalStyle: {
        padding: 0,
        zIndex: 9000,
        position: 'fixed',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderColor: 'transparent',
        overflow: 'clip',
        background: '#00000085',
      },
    });
  }, [open]);

  const onClickSubmit = useCallback(() => {
    dispatch(setChampionshipFilter(selectedChampionship));
    close();
  }, [close, dispatch, selectedChampionship]);

  const component = useMemo(() => {
    const options = [
      { value: 'all', label: 'Все' },
      ...championships.map((name) => ({ value: name, label: name })),
    ];

    return (
      <Modal>
        <div className="d-flex flex-column" style={{ gap: 30 }}>
          <UDText type={'title-3'}>Фильтровать по лигам</UDText>
          <div style={{ maxWidth: 370 }}>
            <UDSelect
              onChange={(newValue) =>
                setSelectedChampionship(newValue?.value ?? 'all')
              }
              options={options}
              value={options.find(
                ({ value }) => value === selectedChampionship
              )}
              components={components}
            />
          </div>
          <UDButton
            style={{ width: 130, height: 36 }}
            variant={'primary'}
            onClick={onClickSubmit}
          >
            Применить
          </UDButton>
        </div>
      </Modal>
    );
  }, [Modal, championships, onClickSubmit, selectedChampionship]);

  return { close, open: onOpen, modal: component };
}

export default useFilterModal;
