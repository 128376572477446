import FootballApplicationCompany from './football/application-company';
import GeneralApplicationCompany from './general/application-company';
import { SportType } from './types';

const ApplicationCompany = (props?: { sportType?: SportType }) => {
  const { sportType } = props || {};

  const renderApplicationCompanyComponent = () => {
    switch (sportType) {
      case 'football':
        return FootballApplicationCompany;
      case 'general':
      default:
        return GeneralApplicationCompany;
    }
  };

  const ApplicationCompanyComponent = renderApplicationCompanyComponent();

  return (
    <>
      <ApplicationCompanyComponent />
    </>
  );
};

export default ApplicationCompany;
