import {
  allIdsChampionshipsDataSelector,
  championshipDataSelector,
  championshipIsLoadedSelector,
  championshipIsLoadingSelector,
} from '../../store/championship/selectors';

import { useSelector } from 'react-redux';

export const useChampionship = (championshipId: number) => {
  const isLoading = useSelector(championshipIsLoadingSelector);
  const isLoaded = useSelector(championshipIsLoadedSelector);
  const data = useSelector(championshipDataSelector(championshipId));

  return {
    isLoading,
    isLoaded,
    data,
  };
};
