import { LabeledInput, SectionTitle } from '../fields';
import React, { ReactNode } from 'react';

import { DrawRuleProps } from 'modules/tourneys/ui/components/form-parts/rules/types';

interface ExtendedDrawRuleProps extends DrawRuleProps {
  children?: ReactNode;
}

const DrawBase: React.FC<ExtendedDrawRuleProps> = ({
  sportType = 'general',
  children,
}) => {
  return (
    <>
      <SectionTitle title="Ничья" icon="⚔️" />

      <LabeledInput
        name="settings.drawScore"
        placeholder="0 очков"
        label=""
        containerClassName="mb-4"
        inputContainerClassName="w-100"
        alignRight={false}
      />

      {/* Место для дополнительных полей */}
      {children}
    </>
  );
};

export default DrawBase;
