import { RootState } from 'store/store';
import { createSelector } from '@reduxjs/toolkit';

export const announcedChampionshipTeamsSelector = (state: RootState) => state.teams.championshipTeams;

export const championshipTeamsChampionshipIdSelector = createSelector(
  announcedChampionshipTeamsSelector,
  (state) => state.championshipId,
);

export const announcedChampionshipTeamsIsLoadingSelector = createSelector(
  announcedChampionshipTeamsSelector,
  (state) => state.isLoading,
);

export const announcedChampionshipTeamsDataSelector = (championshipId?: number) => createSelector(
  announcedChampionshipTeamsSelector,
  (state) => championshipId ? state.data?.byId[championshipId] : null,
);

export const updatingChampionshipTeamSelector = createSelector(
  announcedChampionshipTeamsSelector,
  (state) => state.updatingTeamApplication,
);

export const updatingChampionshipTeamApplicationSelector = createSelector(
  announcedChampionshipTeamsSelector,
  (state) => state.updatingPlayerApplication,
);
