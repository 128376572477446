import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'store/store';
import { ApplicationStatus } from '../../../applications/domain/enums/ApplicationStatus';
import { selectedStageSelector } from '../../store/stages';
import { upsertStage } from '../../store/stages/stages.actions';
import { useChampionship } from './use-championship';
import { useEffect } from 'react';

export const useCheckAcceptedTeams = (championshipId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const { data: championship } = useChampionship(championshipId);
    const stage = useSelector(selectedStageSelector);

    useEffect(() => {
        const notAcceptedTeams = championship?.applications.filter((team) => {
            return team.status !== ApplicationStatus.ACCEPTED;
        }) ?? [];

        if (notAcceptedTeams.length && stage?.teams.length) {
            const hasNotAcceptedTeams = stage?.teams.some((team) => {
                return notAcceptedTeams.some((t) => t.teamId === team.id);
            });

            if (!hasNotAcceptedTeams) return

            const teams = stage?.teams.filter((team) => {
                return notAcceptedTeams.some((t) => t.teamId !== team.id);
            });

            dispatch(upsertStage({
                id: stage.id,
                championshipId: stage.championshipId,
                name: stage.name,
                type: stage.type,
                teamIds: teams.map((team) => team.id),
            }));
        }
    }, [stage, championship, dispatch]);
};

export default useCheckAcceptedTeams;
