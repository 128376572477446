import { LabeledCheckbox, LabeledInput, SectionTitle } from '../fields';
import React, { ReactNode } from 'react';

import { LoseRuleProps } from 'modules/tourneys/ui/components/form-parts/rules/types';

interface ExtendedLoseRuleProps extends LoseRuleProps {
  children?: ReactNode;
}

const LoseBase: React.FC<ExtendedLoseRuleProps> = ({
  sportType = 'general',
  children,
}) => {
  return (
    <>
      <SectionTitle title="Поражение" icon="❌" />

      <LabeledInput
        name="settings.lossScore"
        placeholder="0 очков"
        label=""
        containerClassName="mb-4"
        inputContainerClassName="w-30"
        alignRight={false}
      />

      <LabeledInput
        name="settings.lossScoreExtraTime"
        placeholder="0 очков"
        label="В дополнительное время"
      />

      {/* Место для дополнительных полей */}
      {children}
    </>
  );
};

export default LoseBase;
