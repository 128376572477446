import { TeamByMatchId, TeamByMatchIdDTO } from "../interfaces/TeamByMatchId";
import { TeamGroupStatisticsDTO, TeamGroupStatisticsRowDTO } from '../interfaces/TeamGroupStatisticsDTO';
import { TournamentDataMapper, TournamentDataMapperResult } from '../data-mappers/tournament.data-mapper';
import graphqlResource, { GraphqlResource } from 'modules/core/graphqlResource';
import {
  mapChampionshipSettings,
  mapChampionshipSettingsToDTO,
  mapGameDTOToGame,
  mapTeamDTOToTeam,
} from '../data-mappers/championship-game-teams-mapper';

import { Address } from '../../../core/domain/interfaces/address';
import { Application } from '../../../applications/domain/interfaces/Application';
import { ApplicationDTO } from '../../../applications/domain/interfaces/ApplicationDTO';
import { ApplicationStatus } from '../../../applications/domain/enums/ApplicationStatus';
import { Championship } from '../interfaces/Championship';
import { ChampionshipDTO } from '../interfaces/ChampionshipDTO';
import { ChampionshipSettings } from '../interfaces/ChampionshipSettings';
import { Game } from 'modules/games/domain/interfaces/Game';
import { GameDTO } from '../../../games/domain/interfaces/GameDTO';
import { Round } from '../interfaces/Round';
import { SportType } from "modules/core/domain/enums/SportType";
import { Stage } from '../interfaces/Stage';
import { StageDTO } from '../interfaces/StageDTO';
import { StageType } from '../enums/StageType';
import { SymbolicTeam } from '../interfaces/SymbolicTeam';
import { SymbolicTeamDTO } from '../interfaces/SymbolicTeamDTO';
import { Team } from '../../../teams/domain/interfaces/Team';
import { TeamDTO } from '../../../teams/domain/dtos/Team.dto';
import { TeamGroup } from '../interfaces/TeamGroup';
import { TeamGroupStatistics } from '../interfaces/TeamGroupStatistics';
import { TeamPlayerDTO } from '../../../teams/domain/dtos/TeamPlayer.dto';
import { TournamentBracket } from '../interfaces/TournamentBracket';
import { TournamentBracketDTO } from '../interfaces/TournamentBracketDTO';
import { TournamentDTO } from '../interfaces/TournamentDTO';
import { TourneyStatus } from '../enums/TourneyStatus';
import { UpsertMatchParams } from '../../../games/ui/components/create-game-form';
import formatDateWithTimezone from '../../../utils/format-date-with-timezone';
import { mapEventTypeForBackend } from "modules/games/domain/repositories/game-repository";
import { mapLocationsDtoToLocations } from '../../../locations/domain/mappers/location-data-mapper';
import { teamByMatchDataMapper } from "../data-mappers/team-by-match.data-mapper";
import { toast } from "react-toastify";

const findBracketSizeByTeamsCount = (teamsCount: number): number => {
  if (teamsCount === 0) return 0;

  let count = 2;
  while (count < teamsCount) {
    count *= 2;
  }

  return count - 1;
};

type ChampionshipResponse = {
  data: {
    championship: ChampionshipDTO;
  };
};

type ChampionshipMatchesWithoutStageResponse = {
  data: {
    championship: {
      matches: GameDTO[];
    };
  };
};

type ChampionshipTeamsWithApplicationsResponse = {
  data: {
    championship: {
      teams: TeamDTO[];
      championshipApplications: ApplicationDTO[];
    };
  };
};

type ChampionshipStagesResponse = {
  data: {
    championship: {
      stages: StageDTO[];
    };
  };
};

export type ChampionshipTeamsWithApplicationsDTO = {
  teams: Team[];
  applications: Application[];
};

export type ChampionshipGamesDTO = {
  games: Game[];
};

export type ChampionshipStagesDTO = {
  stages: Stage[];
};

export type UpsertGameDTO = {
  tournamentId: number,
  championshipId: number,
  values: UpsertMatchParams,
  matchId?: number,
};

export interface UpsertChampionshipParams {
  id?: number;
  tournamentId: number;
  name: string;
  description: string | null;
  startDate?: string;
  endDate?: string;
  address?: Address;
  settings: ChampionshipSettings;
  teamIds: number[];
  locationIds?: number[];
}

// export type UpsertChampionshipSettingsParams = Omit<UpsertChampionshipParams, 'teamPlayersNumber'> & {
//   settings: ChampionshipSettings;
// };

export interface UpdateApplicationStatusParams {
  teamId: number;
  championshipId: number;
  userIds: number[];
  status: ApplicationStatus;
}

export interface UpdateApplicationStatusResponse {
  teamId: number;
  championshipId: number;
  status: ApplicationStatus;
}

export interface UpsertChampionshipStageParams {
  id?: number;
  championshipId: number;
  name: string;
  type: StageType;
  teamIds?: number[];
  bracketSize?: number;
}

interface UpsertChampionshipStageResponse {
  data: {
    upsertStage: {
      id: number;
      name: string;
      championship: { id: number; };
      type: StageType;
      teams?: TeamDTO[];
      createdAt: string;
      updatedAt: string;
    };
  };
}

interface DeleteChampionshipStageResponse {
  data: {
    deleteStage: {
      status: string;
    };
  };
}

export interface UpsertStageTeamGroupParams {
  id?: number;
  name: string;
  stageId: number;
  teamIds: number[];
}

interface UpsertStageTeamGroupResponse {
  data: {
    upsertTeamGroup: {
      id: number;
      name: string;
      stage: { id: number; };
      teams: Array<{
        id: number;
        name: string;
        emblem?: {
          id: number;
          externalUrl: string;
        };
      }>;
      groupChampionshipTable: {
        rows: TeamGroupStatisticsRowDTO[];
      };
      createdAt: string;
      updatedAt: string;
    };
  };
}

interface DeleteStageTeamGroupResponse {
  data: {
    deleteTeamGroup: {
      status: string;
    };
  };
}

export interface UpsertSymbolicTeamDTO {
  id?: number;
  championshipId: number;
  name: string;
  formation: string;
  players: Array<{
    userId: number;
    teamId: number;
    rank: number;
  }>;
}

interface UpsertSymbolicTeamResponse {
  data: {
    upsertSymbolicTeam: SymbolicTeamDTO;
  };
}

interface CreateRoundParams {
  name: string;
  stageId: number;
}

interface CreateRoundResponse {
  data: {
    upsertRound: {
      id: number;
      name: string;
      stage: { id: number; };
      createdAt: string;
      updatedAt: string;
    };
  };
}

export interface SetTournamentBracketParams {
  tournamentBracketId: number,
  team1Id: number | null,
  team2Id: number | null
}

type SetTournamentBracketResponse = {
  data: {
    setTournamentBracketTeams: TournamentBracketDTO;
  };
};

export interface SetStageBracketsTeams {
  stageId: number;
  brackets: SetTournamentBracketParams[];
}

interface DeleteChampionshipResponse {
  data: {
    deleteChampionship: {
      status: string;
    };
  };
}

class TournamentRepository {
  constructor(private readonly graphqlResource: GraphqlResource) { }

  public async fetchChampionshipById(id: number): Promise<Championship> {
    const query = `#graphql
      query getChampionship($id: Int!) {
        championship(id: $id) {
          id
          name
          startDate
          endDate
          description
          tournament { id, sportType }
          images {
            id
            type
            externalUrl
          }
          address {
            id
            addressLine1
            addressLine2
            city
            country
            region
          }
          settings {
            id
            name
            value
          }
          teams {
            id
            name
            emblem { externalUrl }
          }
          symbolicTeams {
            id
            name
            formation
            players {
              rank
              user {
                id
                surname
                name
                photo { externalUrl }
              }
              team {
                id
                emblem { externalUrl }
              }
            }
          }
          locations {
            id
            name
          }
        }
      }
    `;

    const response = await this.graphqlResource.query<ChampionshipResponse>(query, { id });
    const championship: ChampionshipDTO = response.data.data.championship;
    const symbolicTeams = championship.symbolicTeams.map((symbolicTeam) => ({
      id: symbolicTeam.id,
      name: symbolicTeam.name,
      formation: symbolicTeam.formation,
      players: symbolicTeam.players.map((player) => ({
        rank: player.rank,
        teamLogo: player.team?.emblem?.externalUrl,
        user: {
          id: player.user.id,
          teamId: player.team?.id,
          firstName: player.user.name,
          lastName: player.user.surname,
          avatar: player.user.photo?.externalUrl,
        },
      })),
    }));
    const sportType = championship.tournament.sportType.toLowerCase() as SportType;

    return {
      id: championship.id,
      tournamentId: championship.tournament.id,
      sportType,
      name: championship.name,
      description: championship.description,
      startDate: championship.startDate,
      endDate: championship.endDate,
      matches: [],
      status: TourneyStatus.OPEN,
      address: championship.address ?? {
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
      },
      teams: championship.teams.map((team) => mapTeamDTOToTeam(team, null)),
      games: [],
      settings: mapChampionshipSettings(championship.settings || [], sportType),
      settingsMap: championship.settings || [],
      applications: [],
      stages: [],
      symbolicTeams,
      locations: mapLocationsDtoToLocations(championship.locations),
    };
  }

  public async fetchChampionshipTeamsWithApplications(championshipId: number): Promise<ChampionshipTeamsWithApplicationsDTO> {
    const query = `#graphql
      query getChampionshipApplications($id: Int!) {
        championship(id: $id) {
          teams {
            id
            name
            emblem {
              id
              externalUrl
            }
          }
          championshipApplications {
            id
            team {
              id
              name
              emblem {
                id
                externalUrl
              }
            }
            position
            number
            user {
              id
              name
              surname
              photo {
                id
                externalUrl
              }
            }
            state
            createdAt
            updatedAt
          }
        }
      }
    `;

    const response = await this.graphqlResource.query<ChampionshipTeamsWithApplicationsResponse>(query, {
      id: championshipId,
    });

    const {
      teams: championshipTeams,
      championshipApplications,
    } = response.data.data.championship;

    const teamsMap = championshipTeams.reduce<Record<string, TeamDTO>>((result, team) => ({
      ...result,
      [team.id]: team,
    }), {});

    const applicationsByTeam = championshipApplications.reduce<Record<string, ApplicationDTO[]>>((result, app) => {
      const teamApps = result[app.team.id] || [];
      teamApps.push(app);

      return {
        ...result,
        [app.team.id]: teamApps,
      };
    }, {});

    const applications: Application[] = [];
    const teams: Team[] = []

    Object.keys(applicationsByTeam).forEach((teamId) => {
      if (!teamsMap[teamId]) {
        teamsMap[teamId] = applicationsByTeam[teamId][0].team;
      }

      const teamDto = teamsMap[teamId];
      const apps = applicationsByTeam[teamId];
      const userIds = apps.map((app) => app.user.id);

      if (!teamDto || !userIds) {
        return null;
      }

      const [firstApp] = apps;
      const members: TeamPlayerDTO[] = apps.map((app) => ({
        position: app.position,
        number: app.number,
        user: app.user,
      }));

      const team = mapTeamDTOToTeam({
        ...teamDto,
        members,
      }, null);

      const hasAcceptedApplication = apps.some(app => app.state === ApplicationStatus.ACCEPTED);

      const teamStatus = hasAcceptedApplication ? ApplicationStatus.ACCEPTED : firstApp.state;

      applications.push({
        championshipId,
        teamId: teamDto.id,
        userIds,
        team,
        status: teamStatus,
        createdAt: firstApp.createdAt || null,
        updatedAt: firstApp.updatedAt || null,
      });

      if (hasAcceptedApplication) {
        teams.push({
          ...team,
          players: team.players.filter((player) => {
            const playerApp = apps.find((app) => app.user.id === player.userId);
            return playerApp && playerApp.state === ApplicationStatus.ACCEPTED;
          }),
        });
      }
    });

    return {
      teams,
      applications,
    };
  }

  public async fetchChampionshipMatchesWithoutStage(championshipId: number): Promise<ChampionshipGamesDTO> {
    const query = `#graphql
      query getChampionshipMatches($id: Int!) {
        championship(id: $id) {
          matches {
            id
            sportType
            date
            teams {
              id
              name
              emblem {
                id
                externalUrl
              }
            }
            teamMatches {
              team { id }
              score
            }
            round {
              stage { id }
            }
          }
        }
      }
    `;

    const response = await this.graphqlResource.query<ChampionshipMatchesWithoutStageResponse>(query, {
      id: championshipId,
    });

    const matches: GameDTO[] = response.data.data.championship.matches;
    const games = matches
      .filter((match) => !match.round?.stage.id)
      .map(mapGameDTOToGame)
      .sort((a, b) => b.date.valueOf() - a.date.valueOf());

    return { games };
  }

  public async fetchChampionshipStages(championshipId: number): Promise<Stage[]> {
    const query = `#graphql
      query getChampionshipStages($id: Int!) {
        championship(id: $id) {
          stages {
            id
            name
            championship { id }
            type
            rounds {
              id
              name
              matches {
                id
                sportType
                date
                teams {
                  id
                  name
                  emblem {
                    id
                    externalUrl
                  }
                }
                teamMatches {
                  team { id }
                  score
                }
                round {
                  id
                  name
                  stage { id }
                }
                location {
                  id
                  name
                }
              }
              createdAt
              updatedAt
            }
            teams {
              id
              name
              emblem {
                id
                externalUrl
              }
            }
            teamGroups {
              id
              name
              stage { id }
              teams {
                id
                name
                emblem { externalUrl }
              }
              groupChampionshipTable {
                rows {
                  position
                  team { id }
                  gamesPlayed
                  gamesWon
                  gamesDraw
                  gamesLost
                  goalsWon
                  goalsLost
                  championshipScore
                }
              }
              createdAt
              updatedAt
            }
            tournamentBrackets {
              id
              rank
              match {
                teams {
                  id
                  name
                  emblem {
                    externalUrl
                  }
                }
                teamMatches {
                  team { id }
                  teamOrder
                  score
                }
                location {
                  id
                  name
                }
              }
              round {
                id
                name
              }
              placeholder1
              placeholder2
              group
              childBracket { id }
              parentBrackets { id }
            }
            createdAt
            updatedAt
          }
        }
      }
    `;

    const response = await this.graphqlResource.query<ChampionshipStagesResponse>(query, {
      id: championshipId,
    });

    const { stages: championshipStages } = response.data.data.championship;

    return championshipStages.map((item) => {
      const stageMatches: Game[] = [];

      const rounds = item.rounds.map((roundItem) => {
        const matchIds: number[] = [];

        roundItem.matches.forEach((match) => {
          const game = mapGameDTOToGame(match);

          matchIds.push(match.id)
          stageMatches.push(game);
        });

        stageMatches.sort((a, b) => b.date.valueOf() - a.date.valueOf());

        return {
          id: roundItem.id,
          stageId: roundItem.id,
          name: roundItem.name,
          matchIds,
          createdAt: new Date(roundItem.createdAt),
          updatedAt: new Date(roundItem.updatedAt),
        }
      });

      const teamGroups = item.teamGroups
        .filter((teamGroup) => teamGroup.stage.id === item.id)
        .map((teamGroup) => {
          const groupTeams = teamGroup.teams
            .map((team) => mapTeamDTOToTeam(team, null))
            .filter(Boolean);

          const statistics = teamGroup.groupChampionshipTable.rows.map((row) => ({
            position: row.position,
            teamId: row.team.id,
            gamesPlayed: row.gamesPlayed,
            gamesWon: row.gamesWon,
            gamesLost: row.gamesLost,
            gamesDraw: row.gamesDraw,
            goalsWon: row.goalsWon,
            goalsLost: row.goalsLost,
            score: row.championshipScore,
          }));

          return {
            id: teamGroup.id,
            name: teamGroup.name,
            stageId: teamGroup.stage.id,
            teams: groupTeams,
            statistics,
            createdAt: new Date(teamGroup.createdAt),
            updatedAt: new Date(teamGroup.updatedAt),
          };
        })
        .sort((a, b) => a.id - b.id);

      const teams = item.teams ? item.teams.map(team => mapTeamDTOToTeam(team, null)) : [];

      const brackets = (item.tournamentBrackets || []).map((bracket) => ({
        id: bracket.id,
        stageId: item.id,
        roundId: bracket.round.id,
        rank: bracket.rank,
        group: bracket.group,
        childBracketId: bracket.childBracket?.id || null,
        parentBracketIds: bracket.parentBrackets.map((parent) => parent.id),
        placeholder1: bracket.placeholder1,
        placeholder2: bracket.placeholder2,
        match: !bracket.match ? null : {
          id: bracket.match.id,
          teamMatches: bracket.match.teamMatches.map((tm) => {
            const team = !tm.team ? null : teams.find((t) => t.id === tm.team.id);

            return {
              team,
              teamOrder: tm.teamOrder,
              score: tm.score,
            };
          }),
        },
      }));

      return {
        id: item.id,
        championshipId: item.championship.id,
        name: item.name,
        type: item.type,
        rounds,
        teams,
        teamGroups,
        matches: stageMatches,
        brackets,
        createdAt: new Date(item.createdAt),
        updatedAt: new Date(item.updatedAt),
      } as Stage;
    });
  }

  public async fetchChampionships(tournamentId: number): Promise<TournamentDataMapperResult> {
    const query = `#graphql
      query getTournamentChampionships($id: Int!) {
        tournament(id: $id) {
          id
          name
          championships {
            id
            name
            startDate
            endDate
            address {
              addressLine1
              addressLine2
              city
              country
            }
            locations{
              id
              address {
                city
                country
              }
            }
          }
          address {
              city
              country
            }
        }
      }
    `;

    const response = await this.graphqlResource.query<TournamentDTO>(query, { id: tournamentId });
    const dataMapper = new TournamentDataMapper();

    return dataMapper.decode(response.data);
  }

  public async upsertChampionship(params: UpsertChampionshipParams): Promise<number> {
    const query = `#graphql
      mutation upsertChampionship($input: UpsertChampionshipInput!) {
        upsertChampionship(input: $input) {
          id
        }
      }
    `;

    const settingsParams = params.settings;
    const settings = mapChampionshipSettingsToDTO(settingsParams);

    const variables = {
      input: {
        id: params.id,
        tournamentId: params.tournamentId,
        name: params.name,
        description: params.description,
        startDate: params.startDate,
        endDate: params.endDate,
        address: params.address,
        settings,
        teamIds: params.teamIds,
        locationIds: params.locationIds,
      },
    };

    const response = await this.graphqlResource.query<{
      data: {
        upsertChampionship: { id: number },
      };
      errors?: { message: string }[];
    }>(query, variables);

    if (response.data?.errors && response.data.errors?.length > 0) {
      throw new Error(response.data.errors[0].message);
    }

    return response.data.data.upsertChampionship.id;
  }

  public async upsertSymbolicTeam(params: UpsertSymbolicTeamDTO): Promise<SymbolicTeam> {
    const query = `#graphql
      mutation upsertSymbolicTeam($input: UpsertSymbolicTeamInput!) {
        upsertSymbolicTeam(input: $input) {
          id
          name
          formation
          players {
            rank
            user {
              id
              surname
              name
              photo {
                externalUrl
              }
            }
            team {
              id
              emblem {
                externalUrl
              }
            }
          }
        }
      }
    `;

    const variables = {
      input: params,
    };

    const response = await this.graphqlResource.query<UpsertSymbolicTeamResponse>(query, variables);
    const symbolicTeam = response.data.data.upsertSymbolicTeam;

    return {
      id: symbolicTeam.id,
      name: symbolicTeam.name,
      formation: symbolicTeam.formation,
      players: symbolicTeam.players.map((player) => ({
        rank: player.rank,
        teamLogo: player.team.emblem?.externalUrl,
        user: {
          id: player.user.id,
          teamId: player.team.id,
          firstName: player.user.name,
          lastName: player.user.surname,
          avatar: player.user.photo?.externalUrl,
        },
      })),
    };
  }

  public async upsertGame(params: UpsertGameDTO): Promise<Game> {
    const query = `#graphql
      mutation upsertMatch($input: UpsertMatchInput!) {
        upsertMatch(input: $input) {
          id
          sportType
          date
          teams {
            id
            name
            emblem {
              id
              externalUrl
            }
          }
          teamMatches {
            team { id }
            score
          }
          round {
            id
            name
            stage { id }
          }
          location {
            id
            name
            address {
              id
              addressLine1
              addressLine2
              city
              region
              country
              coords {
                lat
                lon
              }
            }
            description
            locationImages {
              image {
                id
                filename
                externalUrl
              }
            }
            tournament {
              id
            }
            createdAt
            updatedAt
          }
        }
      }
    `;

    const {
      tournamentId,
      championshipId,
      values,
      matchId,
    } = params;

    const [hours, minutes] = values.time.split(':');
    const date = new Date(values.date);
    const gameDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    gameDate.setHours(parseInt(hours));
    gameDate.setMinutes(parseInt(minutes));

    const variables = {
      input: {
        matchId,
        tournamentId,
        championshipId,
        sportType: values.sportType,
        firstTeamId: values.firstTeam,
        secondTeamId: values.secondTeam,
        date: formatDateWithTimezone(gameDate),
        roundId: values.roundId ?? undefined,
        locationId: values.locationId,
        firstTeamScore: values.result?.firstTeamScore,
        secondTeamScore: values.result?.secondTeamScore,
        matchEvents: (values.events ?? []).map((event) => ({
          type: mapEventTypeForBackend(event.type),
          firstTeamScore: null,
          secondTeamScore: null,
          playerName: null,
          second: event.seconds,
          period: event.period,
          userId: event.playerIds[0],
          secondaryUserId: event.playerIds[1],
          videoUrl: event?.videoUrl,
        })),
        videoUrls: values.videoUrls,
      },
    };

    if (values.round) {
      const round = await this.createRound(values.round);
      variables.input.roundId = round.id;
    }

    const response = await this.graphqlResource.query<{ data: { upsertMatch: GameDTO } }>(query, variables);
    return mapGameDTOToGame(response.data.data.upsertMatch);
  }

  public async createRound(params: CreateRoundParams): Promise<Round> {
    const query = `#graphql
      mutation upsertRound($input: UpsertRoundInput!) {
        upsertRound(input: $input) {
          id
          name
          stage { id }
          createdAt
          updatedAt
        }
      }
    `;

    const variables = {
      input: {
        name: params.name,
        stageId: params.stageId,
      },
    };

    const response = await this.graphqlResource.query<CreateRoundResponse>(query, variables);
    const result = response.data.data.upsertRound;

    return {
      id: result.id,
      name: result.name,
      stageId: result.stage.id,
      createdAt: new Date(result.createdAt),
      updatedAt: new Date(result.updatedAt),
      matchIds: [],
    };
  }

  public async deleteGame(gameId: number): Promise<number> {
    const query = `#graphql
      mutation deleteMatch($gameId: Int!) {
        deleteMatch(matchId: $gameId) {
          id
        }
      }
    `;

    const response = await this.graphqlResource.query<{ data: { deleteMatch: { id: number; }; }; }>(query, { gameId });
    return response.data.data.deleteMatch.id;
  }

  public async upsertChampionshipStage(params: UpsertChampionshipStageParams): Promise<Stage> {
    const query = `#graphql
      mutation upsertStage($input: UpsertStageInput!) {
        upsertStage(input: $input) {
          id
          name
          championship { id }
          type
          teams {
            id
            name
            emblem {
              id
              externalUrl
            }
          }
          createdAt
          updatedAt
        }
      }
    `;

    const bracketSize = (params.type === StageType.SINGLE_ELIMINATION && params.teamIds)
      ? findBracketSizeByTeamsCount(params.teamIds.length)
      : undefined;

    const variables = {
      input: {
        id: params.id,
        championshipId: params.championshipId,
        name: params.name,
        type: params.type,
        teamIds: params.teamIds,
      },
    };

    const response = await this.graphqlResource.query<UpsertChampionshipStageResponse>(query, variables);
    const result = response.data.data.upsertStage;

    let brackets: TournamentBracket[] = [];
    if (bracketSize !== undefined) {
      return await this.recreateTournamentBrackets(result.id, bracketSize);
    }

    return {
      id: result.id,
      championshipId: result.championship.id,
      name: result.name,
      type: result.type,
      rounds: [],
      teams: result.teams ? result.teams.map(team => mapTeamDTOToTeam(team, null)) : [],
      teamGroups: [],
      matches: [],
      brackets,
      createdAt: new Date(result.createdAt),
      updatedAt: new Date(result.updatedAt),
    };
  }

  public async fetchStageBrackets(stageId: number): Promise<Stage> {
    const query = `#graphql
      query getStageBrackets($stageId: Int!) {
        stage(id: $stageId) {
          id
          name
          championship { id }
          type
          createdAt
          updatedAt
          rounds {
            id
            name
            matches {
              id
              sportType
              date
              teams {
                id
                name
                emblem {
                  id
                  externalUrl
                }
              }
              teamMatches {
                team { id }
                score
              }
              round {
                id
                name
                stage { id }
              }
            }
            createdAt
            updatedAt
          }
          teams {
            id
            name
            emblem {
              id
              externalUrl
            }
          }
          teamGroups {
            id
            name
            stage { id }
            teams {
              id
              name
              emblem { externalUrl }
            }
            groupChampionshipTable {
              rows {
                position
                team { id }
                gamesPlayed
                gamesWon
                gamesDraw
                gamesLost
                goalsWon
                goalsLost
                championshipScore
              }
            }
            createdAt
            updatedAt
          }
          tournamentBrackets {
            id
            match {
              id
              teamMatches {
                teamOrder
                score
                team {
                  id
                  name
                  emblem {
                    id
                    externalUrl
                  }
                }
              }
            }
            rank
            childBracket { id }
            parentBrackets { id }
            stage { id }
            round { id }
            placeholder1
            placeholder2
            group
          }
        }
      }
    `;
    const variables = { stageId };

    type FetchStageBracketsResponse = {
      data: {
        stage: StageDTO;
      };
    };

    const response = await this.graphqlResource.query<FetchStageBracketsResponse>(query, variables);
    const { stage: item } = response.data.data;

    const stageMatches: Game[] = [];
    const rounds = item.rounds.map((roundItem) => {
      const matchIds: number[] = [];

      roundItem.matches.forEach((match) => {
        const game = mapGameDTOToGame(match);

        matchIds.push(match.id)
        stageMatches.push(game);
      });

      stageMatches.sort((a, b) => b.date.valueOf() - a.date.valueOf());

      return {
        id: roundItem.id,
        stageId: roundItem.id,
        name: roundItem.name,
        matchIds,
        createdAt: new Date(roundItem.createdAt),
        updatedAt: new Date(roundItem.updatedAt),
      }
    });

    const teamGroups = item.teamGroups
      .filter((teamGroup) => teamGroup.stage.id === item.id)
      .map((teamGroup) => {
        const groupTeams = teamGroup.teams
          .map((team) => mapTeamDTOToTeam(team, null))
          .filter(Boolean);

        const statistics = teamGroup.groupChampionshipTable.rows.map((row) => ({
          position: row.position,
          teamId: row.team.id,
          gamesPlayed: row.gamesPlayed,
          gamesWon: row.gamesWon,
          gamesLost: row.gamesLost,
          gamesDraw: row.gamesDraw,
          goalsWon: row.goalsWon,
          goalsLost: row.goalsLost,
          score: row.championshipScore,
        }));

        return {
          id: teamGroup.id,
          name: teamGroup.name,
          stageId: teamGroup.stage.id,
          teams: groupTeams,
          statistics,
          createdAt: new Date(teamGroup.createdAt),
          updatedAt: new Date(teamGroup.updatedAt),
        };
      })
      .sort((a, b) => a.id - b.id);

    const teams = item.teams ? item.teams.map(team => mapTeamDTOToTeam(team, null)) : [];

    const brackets = (item.tournamentBrackets || []).map((bracket) => ({
      id: bracket.id,
      stageId: item.id,
      roundId: bracket.round.id,
      rank: bracket.rank,
      group: bracket.group,
      childBracketId: bracket.childBracket?.id || null,
      parentBracketIds: bracket.parentBrackets.map((parent) => parent.id),
      placeholder1: bracket.placeholder1,
      placeholder2: bracket.placeholder2,
      match: !bracket.match ? null : {
        id: bracket.match.id,
        teamMatches: bracket.match.teamMatches.map((tm) => {
          const team = !tm.team ? null : teams.find((t) => t.id === tm.team.id);

          return {
            team,
            teamOrder: tm.teamOrder,
            score: tm.score,
          };
        }),
      },
    }));

    return {
      id: item.id,
      championshipId: item.championship.id,
      name: item.name,
      type: item.type,
      rounds,
      teams,
      teamGroups,
      matches: stageMatches,
      brackets,
      createdAt: new Date(item.createdAt),
      updatedAt: new Date(item.updatedAt),
    } as Stage;
  }

  public async recreateTournamentBrackets(stageId: number, bracketSize: number): Promise<Stage> {
    const query = `#graphql
      mutation recreateTournamentBrackets($stageId: Int!, $bracketSize: Int!) {
        recreateTournamentBrackets(stageId: $stageId, bracketSize: $bracketSize) {
          id
        }
      }
    `;

    const variables = {
      stageId,
      bracketSize,
    };

    const response = await this.graphqlResource.query<{ errors: Array<{ message: string }> }>(query, variables);

    if (response.data.errors) {
      if (response.data.errors.length === 1) {
        if (response.data.errors[0].message.includes('Cannot delete match less than 5 hours before match')) {
          toast.error('Нельзя обновить тип этапа, так как матч начинается менее чем через 5 часов. Сетка матчей не будет обновлена.');
        }
      } else if (response.data.errors.length > 1) {
        for (const error of response.data.errors) {
          console.error(error.message);
        }
      }
    }

    return this.fetchStageBrackets(stageId);
  }

  public async deleteChampionshipStage(stageId: number): Promise<boolean> {
    const query = `#graphql
      mutation deleteStage($id: Int!) {
        deleteStage(id: $id) {
          status
        }
      }
    `;

    const response = await this.graphqlResource.query<DeleteChampionshipStageResponse>(query, { id: stageId });
    const { status } = response.data.data.deleteStage;

    return status === 'ok';
  }

  public async upsertStageTeamGroup(params: UpsertStageTeamGroupParams): Promise<TeamGroup> {
    const query = `#graphql
      mutation upsertTeamGroup($input: UpsertTeamGroupInput!) {
        upsertTeamGroup(input: $input) {
          id
          name
          stage { id }
          teams {
            id
            name
            emblem {
              id
              externalUrl
            }
          }
          groupChampionshipTable {
            rows {
              position
              team { id }
              gamesPlayed
              gamesWon
              gamesDraw
              gamesLost
              goalsWon
              goalsLost
              championshipScore
            }
          }
          createdAt
          updatedAt
        }
      }
    `;

    const variables = { input: params };

    const response = await this.graphqlResource.query<UpsertStageTeamGroupResponse>(query, variables);
    const result = response.data.data.upsertTeamGroup;

    const statistics = result.groupChampionshipTable.rows.map((row) => ({
      position: row.position,
      teamId: row.team.id,
      gamesPlayed: row.gamesPlayed,
      gamesWon: row.gamesWon,
      gamesLost: row.gamesLost,
      gamesDraw: row.gamesDraw,
      goalsWon: row.goalsWon,
      goalsLost: row.goalsLost,
      score: row.championshipScore,
    }));

    return {
      id: result.id,
      name: result.name,
      stageId: result.stage.id,
      teams: result.teams.map((team) => mapTeamDTOToTeam(team, null)),
      statistics,
      createdAt: new Date(result.createdAt),
      updatedAt: new Date(result.updatedAt),
    };
  }

  public async deleteStageTeamGroup(teamGroupId: number): Promise<boolean> {
    const query = `#graphql
      mutation deleteTeamGroup($id: Int!) {
        deleteTeamGroup(id: $id) {
          status
        }
      }
    `;

    const response = await this.graphqlResource.query<DeleteStageTeamGroupResponse>(query, { id: teamGroupId });
    const { status } = response.data.data.deleteTeamGroup;

    return status === 'ok';
  }

  public async fetchStageGroupsStatistics(teamGroupIds: number[]): Promise<Record<number, TeamGroupStatistics>> {
    const groupsStatistics = await Promise.all(teamGroupIds.map(id => this.fetchTeamGroupStatistics(id)));

    return groupsStatistics.reduce<Record<number, TeamGroupStatistics>>((result, item) => {
      return {
        ...result,
        [item.teamGroupId]: item,
      };
    }, {});
  }

  public async setStageBracketsTeams(params: SetStageBracketsTeams): Promise<TournamentBracket[]> {
    const { brackets } = params;
    return Promise.all(brackets.map((bracket) => this.setTournamentBracket(bracket)));
  }

  public async setTournamentBracket(params: SetTournamentBracketParams): Promise<TournamentBracket> {
    const variables = { input: params };
    const query = `#graphql
      mutation setTournamentBracketTeams($input: SetTournamentBracketTeamsInput!) {
        setTournamentBracketTeams(input: $input) {
          id
          match {
            id
            teamMatches {
              teamOrder
              score
              team {
                id
                name
                emblem {
                  id
                  externalUrl
                }
              }
            }
          }
          rank
          childBracket { id }
          parentBrackets { id }
          stage { id }
          round { id }
          placeholder1
          placeholder2
          group
        }
      }
    `;

    const response = await this.graphqlResource.query<SetTournamentBracketResponse>(query, variables);
    const bracket = response.data.data.setTournamentBracketTeams;

    return {
      id: bracket.id,
      stageId: bracket.stage.id,
      roundId: bracket.round.id,
      rank: bracket.rank,
      group: bracket.group,
      childBracketId: bracket.childBracket?.id || null,
      parentBracketIds: bracket.parentBrackets.map((parent) => parent.id),
      placeholder1: bracket.placeholder1,
      placeholder2: bracket.placeholder2,
      match: !bracket.match ? null : {
        id: bracket.match.id,
        teamMatches: bracket.match.teamMatches.map((tm) => {
          const team = tm.team ? mapTeamDTOToTeam(tm.team as TeamDTO, null) : null
          return {
            team,
            teamOrder: tm.teamOrder,
            score: tm.score,
          };
        }),
      },
    }
  }

  private async fetchTeamGroupStatistics(teamGroupId: number): Promise<TeamGroupStatistics> {
    const query = `#graphql
      query getTeamGroupStatistics($teamGroupId: Int!) {
          groupChampionshipTable(teamGroupId: $teamGroupId) {
              rows {
                  position
                  team { id }
                  gamesPlayed
                  gamesWon
                  gamesDraw
                  gamesLost
                  goalsWon
                  goalsLost
                  championshipScore
              }
          }
      }
    `;

    const response = await this.graphqlResource.query<TeamGroupStatisticsDTO>(query, { teamGroupId });
    const { rows } = response.data.data.groupChampionshipTable;

    return {
      teamGroupId,
      rows: rows.map((row) => ({
        position: row.position,
        teamId: row.team.id,
        gamesPlayed: row.gamesPlayed,
        gamesWon: row.gamesWon,
        gamesDraw: row.gamesDraw,
        gamesLost: row.gamesLost,
        goalsWon: row.goalsWon,
        goalsLost: row.goalsLost,
        score: row.championshipScore,
      })),
    };
  }

  public async fetchTeamPlayersByMatchId(matchId: number): Promise<TeamByMatchId[]> {
    const query = `#graphql
      query Match($id: Int!) {
        match(id: $id) {
          teams {
            id
            championshipApplications {
              id
              team {
                id
              }
              user {
                id
                name
                surname
                middleName
                photo {
                  id
                  externalUrl
                }
              }
              number
              position
            }
          }
        }
      }
    `

    const response = await this.graphqlResource.query<TeamByMatchIdDTO>(query, { id: matchId });
    return teamByMatchDataMapper(response.data.data.match.teams)
  }

  public async deleteChampionship(championshipId: number): Promise<boolean> {
    const query = `#graphql
      mutation DeleteChampionship($id: Int!) {
        deleteChampionship(id: $id) {
          status
        }
      }
    `;

    const response = await this.graphqlResource.query<DeleteChampionshipResponse>(query, { id: championshipId });
    const { status } = response.data.data.deleteChampionship;

    return status === 'ok';
  }
}

const tourneysRepository = new TournamentRepository(graphqlResource);

export default tourneysRepository;
