import 'tippy.js/dist/tippy.css';

import * as S from './styles';

import React, { useCallback } from 'react';

import { Championship } from 'modules/tourneys/domain/interfaces/Championship';
import { DivProps } from '../../../../../typings';
import Tippy from '@tippyjs/react';
import { TourneyEncoded } from 'modules/tourneys/domain/interfaces/TourneyEncoded';
import { TourneyStatus } from 'modules/tourneys/domain/enums/TourneyStatus';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import UDText from 'modules/ud-ui/components/text';
import classNames from 'classnames';

type Props = {
  // tourney: TourneyEncoded;
  tourney: Championship;
  containerProps?: Omit<DivProps, 'children' | 'onClick'>;
  onClickCard: (id: TourneyEncoded['id']) => any;
};

const statusIndicatorMessage = {
  [TourneyStatus.OPEN]: 'Прием заявок открыт',
  [TourneyStatus.CLOSE]: 'Прием заявок закрыт',
};

const TourneyComponentsTourneyCard = (props: Props) => {
  const start = props.tourney.startDate
    ? new Date(props.tourney.startDate).toLocaleDateString('ru')
    : null;

  const end = props.tourney.endDate
    ? new Date(props.tourney.endDate).toLocaleDateString('ru')
    : null;

  const onClickCard = useCallback(() => {
    props.onClickCard(props.tourney.id);
  }, [props]);

  return (
    <S.CardContainer
      {...props.containerProps}
      className={classNames(
        'd-flex flex-column pl-3 pr-4 py-4',
        props.containerProps?.className
      )}
      onClick={onClickCard}
    >
      <div className="d-flex flex-row align-items-center mb-5">
        <UDColorableIcon name="cup" componentProps={{ className: 'mr-1' }} />
        <UDText type="caption-2" className="color-SurfaceRomanSilver30">
          Турнир
        </UDText>
        <UDColorableIcon
          name="geo-pin"
          componentProps={{
            className: 'mr-1 ml-auto color-SurfaceRomanSilver30',
          }}
        />
        <UDText type="caption-2" className="color-SurfaceRomanSilver30">
          {props.tourney.address.city}
          {/* {props.tourney.place} */}
          {/* TODO: resolve place field */}
        </UDText>
      </div>
      <S.StyledTournamentName type="subhead" className="bold">
        {props.tourney.name}
      </S.StyledTournamentName>
      <div className="d-flex flex-row align-items-center justify-content-between mt-auto">
        <UDText type="subhead" className="date color-SurfaceRichBlack60">
          {start} —{' '}
          {end || (
            <span className="color-SurfaceRomanSilver30">открытая дата</span>
          )}
        </UDText>
        <Tippy
          content={statusIndicatorMessage[props.tourney.status]}
          placement="right"
          interactive={false}
        >
          <S.StatusIndicator data-status={props.tourney.status} />
        </Tippy>
      </div>
    </S.CardContainer>
  );
};

export default TourneyComponentsTourneyCard;
