import React from 'react';
import UDFormCheckbox from 'modules/ud-form/components/checkbox';
import UDText from 'modules/ud-ui/components/text';

interface LabeledCheckboxProps {
  label: string;
  name: string;
  containerClassName?: string;
}

const LabeledCheckbox: React.FC<LabeledCheckboxProps> = ({
  label,
  name,
  containerClassName = 'd-flex align-items-center mb-4',
}) => {
  return (
    <div className={containerClassName}>
      <UDFormCheckbox name={name} />
      <UDText type="callout" className="ml-2 color-SurfaceRaisinBlack50">
        {label}
      </UDText>
    </div>
  );
};

export default LabeledCheckbox;
