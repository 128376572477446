/** @jsxImportSource @emotion/react */

import * as yup from 'yup';

import { Image, Location } from '../../domain/interfaces/location';
import {
  LocationFormContainer,
  LocationMapImage,
  LocationMapImageContainer,
} from './styles';
import {
  mapAddressToDadataArea,
  mapDadataAreaToAddress,
} from 'shared/features/dadata-address-autocomplete/infra/mappers/dadata-area.mapper';
import { useCallback, useEffect, useState } from 'react';

import { DadataAreaType } from 'shared/features/dadata-address-autocomplete/domain/entities/dadata-area.entity';
import { Formik } from 'formik';
import UDButton from '../../../ud-ui/components/button';
import { UDFormAddressSelect } from '../../../ud-form/components/select';
import UDFormDropZone from '../../../ud-form/components/dropzone';
import UDFormInput from '../../../ud-form/components/input';
import UDFormTextearea from '../../../ud-form/components/input/textarea';
import UDText from 'modules/ud-ui/components/text';
import { UpsertLocationInput } from '../../domain/interfaces/actions';
import { css } from '@emotion/react';
import { isEmptyAddress } from 'modules/core/helpers/isEmptyAddress';
import { toast } from 'react-toastify';
import { useImageGallery } from 'modules/ud-ui/components/image-gallery/modal';
import { useTranslation } from 'react-i18next';

type LocationFormProps = {
  location?: Location;
  isLoading: boolean;
  onSubmit: (values: UpsertLocationInput) => Promise<void>;
  onCancel: () => void;
  tournamentId?: number;
  action: 'create' | 'edit';
  onChange?: (values: UpsertLocationInput) => void;
  hasUnsavedChanges?: boolean;
  isAvailableFeatures?: boolean;
  isMapImageLoading?: boolean;
};

const locationValidator = yup.object().shape({
  name: yup.string().required('Название обязательно'),
  address: yup.object().nullable().required('Адрес обязателен'),
  images: yup
    .array()
    .max(5, 'Максимум 5 фотографий')
    .test('unique', 'Изображения должны быть уникальными', function (value) {
      if (!value) return true;

      const files = value.reduce<File[]>((acc, img: File | Image) => {
        if (img instanceof File) return [...acc, img];
        return acc;
      }, []);

      return (
        files.length === new Set(files.map((file: File) => file.name)).size
      );
    }),
});

function LocationForm({
  location,
  isLoading,
  onSubmit,
  onCancel,
  tournamentId,
  action,
  onChange,
  hasUnsavedChanges,
  isAvailableFeatures,
  isMapImageLoading,
}: LocationFormProps) {
  const [descriptionHeight, setDescriptionHeight] =
    useState<string>('flex-grow: 1');
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation();
  const { GalleryModalWrapper, handleImageClick, setImages } = useImageGallery({
    size: { width: '650px', height: '450px' },
  }); // Инициализация галереи

  const openMapImageGallery = useCallback(() => {
    setImages([
      {
        id: 0,
        url: `data:image/png;base64,${location?.mapImage}`,
        externalUrl: `data:image/png;base64,${location?.mapImage}`,
      },
    ]);
    handleImageClick(0);
  }, [setImages, location?.mapImage, handleImageClick]);

  useEffect(() => {
    const description = document.getElementById('description');
    if (!description) {
      return;
    }

    const observer = new MutationObserver(() => {
      setDescriptionHeight(description.style.height);
    });

    observer.observe(description, {
      attributes: true,
      attributeFilter: ['style'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const initialValues = location
    ? {
        name: location.name,
        address:
          location.address && !isEmptyAddress(location.address)
            ? mapAddressToDadataArea(location.address)
            : null,
        description: location.description || '',
        images: location.images || [],
      }
    : {
        name: '',
        address: null,
        description: '',
        images: [],
      };

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      const effectiveTournamentId = location?.tournamentId || tournamentId;

      if (!effectiveTournamentId) {
        toast.error('ID организатора не найден, попробуйте обновить страницу');
        throw new Error('Tournament ID is required for location');
      }

      const locationData: UpsertLocationInput = {
        id: location?.id,
        name: values.name,
        description: values.description,
        images: values.images as Array<File | Image>,
        tournamentId: effectiveTournamentId,
        address: values.address
          ? mapDadataAreaToAddress(values.address)
          : undefined,
      };
      setIsProcessing(true);
      onSubmit(locationData).finally(() => {
        setIsProcessing(false);
      });
    },
    [location?.id, location?.tournamentId, tournamentId, onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={locationValidator}
      onSubmit={handleSubmit}
      validate={(values) => {
        if (onChange) {
          const currentValues: UpsertLocationInput = {
            id: location?.id,
            name: values.name,
            description: values.description,
            images: values.images,
            tournamentId: location?.tournamentId || tournamentId || 0,
            address: values.address
              ? mapDadataAreaToAddress(values.address)
              : undefined,
          };
          onChange(currentValues);
        }
        return {};
      }}
    >
      {(formProps) => {
        const handleDeleteImage = (index: number) => {
          const newImages = [...formProps.values.images];
          newImages.splice(index, 1);
          formProps.setFieldValue('images', newImages);
        };

        const isDisabled = Boolean(
          isLoading ||
            isProcessing ||
            formProps.errors?.name ||
            formProps.errors?.address ||
            formProps.errors?.description ||
            formProps.errors?.images
        );

        return (
          <form
            className="d-flex flex-column justify-content-between h-100"
            onSubmit={formProps.handleSubmit}
          >
            <LocationFormContainer>
              <div className="row">
                <div className="col-6 flex-column">
                  <UDFormInput
                    name="name"
                    label="Название*"
                    placeholder="Введите название места проведения"
                    required={true}
                  />

                  <div className="location-data flex-column">
                    <UDFormAddressSelect
                      name="address"
                      required={true}
                      label="Город и адрес*"
                      placeholder="Введите адрес"
                      onlyCity={false}
                      allowedTypes={[
                        DadataAreaType.REGION,
                        DadataAreaType.CITY,
                        DadataAreaType.STREET,
                        DadataAreaType.HOUSE,
                      ]}
                    />
                    {(location?.mapImage || isMapImageLoading) && (
                      <LocationMapImageContainer onClick={openMapImageGallery}>
                        {isMapImageLoading && (
                          <div
                            className="skeleton-block"
                            style={{ width: '100%', height: 425 }}
                          />
                        )}
                        {location?.mapImage && (
                          <LocationMapImage
                            src={`data:image/png;base64,${location.mapImage}`}
                            alt="Карта"
                          />
                        )}
                      </LocationMapImageContainer>
                    )}
                  </div>

                  <div
                    className="location-data"
                    css={css`
                      ${descriptionHeight}
                    `}
                  >
                    <UDFormTextearea
                      name="description"
                      label="Описание"
                      required={false}
                      placeholder="Опишите локацию как можно подробнее, расскажите о преимуществах"
                      style={{ height: '100%' }}
                      id="description"
                      containerProps={{ className: 'flex-column w-100' }}
                      wrapperProps={{ style: { flexGrow: 1 } }}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <UDFormDropZone
                    name="images"
                    label="Загрузите фото локации (до 5 шт.)"
                    multiple
                    enableDragAndDrop
                    onChangeInterceptor={(files) => files.slice(0, 5)}
                    onDeleteImage={handleDeleteImage}
                    formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
                    disabled={!isAvailableFeatures}
                    title={
                      isAvailableFeatures
                        ? 'Загрузить фото локации'
                        : 'Загрузка фото доступна только пользователям\nс платной подпиской.'
                    }
                    subtitle={isAvailableFeatures ? 'с вашего устройства' : ''}
                    buttonText={
                      isAvailableFeatures
                        ? 'Выбрать файлы'
                        : 'Подключить подписку'
                    }
                    onButtonClick={() => {
                      if (!isAvailableFeatures) {
                        window.open('https://imgameapp.ru/#contacts', '_blank');
                      }
                    }}
                  />
                  {!isAvailableFeatures && (
                    <UDText
                      type="caption-1"
                      className="color-SurfaceRomanSilver30 mt-2"
                    >
                      Для редактирования фотографий необходимо иметь активную
                      подписку.
                    </UDText>
                  )}
                </div>
              </div>

              <div
                className="d-flex justify-content-between mb-6"
                style={{ width: '50%', paddingRight: 24, marginTop: 60 }}
              >
                <UDButton
                  type="submit"
                  variant="primary"
                  disabled={
                    hasUnsavedChanges === undefined
                      ? isDisabled
                      : isDisabled || !hasUnsavedChanges
                  }
                  loading={isProcessing}
                  className="mr-4"
                  style={{ width: '50%' }}
                >
                  {t(`location.action.${action}`)}
                </UDButton>

                <UDButton
                  type="button"
                  variant="secondary"
                  disabled={isLoading || isProcessing}
                  onClick={onCancel}
                  style={{ width: '50%' }}
                >
                  {t('location.action.cancel')}
                </UDButton>
              </div>
              <GalleryModalWrapper />
            </LocationFormContainer>
          </form>
        );
      }}
    </Formik>
  );
}

export default LocationForm;
