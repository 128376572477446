import { scrollbar } from 'shared/styles/scrollbar';
import styled from '@emotion/styled';
import { theme } from 'styles/theme/theme';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Menu = styled.menu`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 250px;
  width: 20%;
  height: 100vh;
  background: ${({ theme }) => theme.colors.SurfaceIndependence40};
  z-index: 8000;
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: max(20%, 250px);
  right: 0;
  height: 70px;
  background: ${({ theme }) => theme.colors.SurfaceRichBlack60};
  z-index: 7999;

  display: flex;
  align-items: center;
`;

export const MainBlockWrapper = styled.div`
  margin-top: 70px;
  margin-left: max(20%, 250px);
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: calc(100% - max(20%, 250px));
  min-height: calc(100vh - 70px);
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.colors.SurfaceCultured10};
  flex: 1;
  overflow: auto;
  ${scrollbar(theme)}
`;

export const Footer = styled.div`
  height: 70px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.SurfaceCultured10};
`;
