import * as S from './styles';

import OrganizerContainer from 'modules/organizer/ui/components/organizer-contrainer';
import React from 'react';
import UDCopyright from '../../components/copyright';
import UDMenu from '../../components/menu';

type Props = {
  children?: React.ReactNode;
  headerContent?: React.ReactNode;
};

const UDMainLayout = (props: Props) => {
  return (
    <OrganizerContainer>
      {(isLoading, info) => (
        <S.Container>
          <S.Menu>
            <UDMenu isOrganizerLoading={isLoading} organizerInfo={info} />
          </S.Menu>
          <S.Header className="container">{props.headerContent}</S.Header>
          <S.MainBlockWrapper>
            <S.Content>{props.children}</S.Content>
            <S.Footer className="container py-6 bt-1">
              <a
                className="link text callout mr-6"
                href="https://imgameapp.ru/#organizer"
                target="_blank"
                rel="noreferrer"
              >
                О приложении
              </a>
              <a
                className="link text callout mr-6"
                href="https://imgameapp.ru/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Политика конфиденциальности
              </a>
              <a
                className="link text callout mr-6"
                href="https://imgameapp.ru/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
              >
                Условия использования
              </a>
              <UDCopyright className="ml-auto d-inline-block" />
            </S.Footer>
          </S.MainBlockWrapper>
        </S.Container>
      )}
    </OrganizerContainer>
  );
};

export default UDMainLayout;
