import { LabeledCheckbox, LabeledInput } from '../fields';
import React, { ReactNode } from 'react';

import { SportType } from 'modules/tourneys/ui/components/form-parts/rules/types';
import UDFormDateRange from 'modules/ud-form/components/datetime/range';

interface ApplicationCompanyBaseProps {
  sportType?: SportType;
  children?: ReactNode;
}

const ApplicationCompanyBase: React.FC<ApplicationCompanyBaseProps> = ({
  sportType = 'general',
  children,
}) => {
  return (
    <div className="row mt-5 g-2">
      <div className="col-6">
        <UDFormDateRange
          startName="settings.applicationStartDate"
          endName="settings.applicationEndDate"
          startLabel="Начало приема заявок"
          endLabel="Окончание приема заявок"
          containerProps={{ className: 'mb-5' }}
        />

        <LabeledInput
          name="settings.applicationMinPlayers"
          placeholder="0 чел"
          label="Минимальное количество игроков в заявке"
        />

        <LabeledInput
          name="settings.applicationMaxPlayers"
          placeholder="0 чел"
          label="Максимальное количество игроков в заявке"
        />

        <LabeledCheckbox
          name="settings.applicationTransfersAllowed"
          label="Переходы возможны"
        />

        <LabeledInput
          name="settings.participationFee"
          placeholder="0 ₽"
          label="Взнос за участие"
        />

        {/* Место для дополнительных полей */}
        {children}
      </div>
    </div>
  );
};

export default ApplicationCompanyBase;
