/** @jsxImportSource @emotion/react */

import React, { ChangeEvent, useCallback } from 'react';

import { AnnouncedTeamStatus } from '../../../domain/enums/AnnouncedTeamStatus';
import FilterButton from './filter-button';
import { UDButton } from 'modules/ud-ui/components';
import UDInput from '../../../../ud-form/components/input/component';
import classNames from 'classnames';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import { useAsyncButton } from 'modules/ud-ui/hooks/useAsyncButton';
import { useTranslation } from 'react-i18next';
import { ApplicationStatus } from '../../../../applications/domain/enums/ApplicationStatus';

export type ChampionshipApplicationFiltersOption<StatusType> = {
  status: StatusType;
  icon: React.JSXElementConstructor<{
    width: number;
    height: number;
    color?: string;
  }>;
  color: string;
};

export type ChampionshipTeamsFilterValues<StatusType> = {
  name: string;
  status: StatusType;
};

type ChampionshipApplicationFiltersProps<StatusType> = {
  selectedStatus: StatusType;
  name?: string;
  options: ChampionshipApplicationFiltersOption<StatusType>[];
  inputPlaceholder?: string;
  countByStatus?: {
    [key: string]: number;
  };
  onSelect?: (status: AnnouncedTeamStatus) => void;
  onChange?: (values: ChampionshipTeamsFilterValues<StatusType>) => void;
  className?: string;
  selectedIds?: number[];
  onAccept?: () => Promise<void> | void;
  onDecline?: () => Promise<void> | void;
};

function ChampionshipApplicationFilters<StatusType>(
  props: ChampionshipApplicationFiltersProps<StatusType>
) {
  const {
    selectedStatus,
    name = '',
    options,
    inputPlaceholder,
    countByStatus,
    className,
    onChange,
    selectedIds = [],
    onAccept,
    onDecline,
  } = props;

  const { t } = useTranslation();

  const handleSelect = useCallback(
    (status: StatusType) => () => {
      if (onChange && status !== selectedStatus) {
        onChange({
          name,
          status,
        });
      }
    },
    [onChange, selectedStatus, name]
  );

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange({
          name: event.target.value,
          status: selectedStatus,
        });
      }
    },
    [onChange, selectedStatus]
  );

  const { isLoading: isAcceptLoading, handleClick: onClickAccept } =
    useAsyncButton(onAccept, {
      onError: () => toast.error('Ошибка при принятии игроков'),
    });
  const { isLoading: isDeclineLoading, handleClick: onClickDecline } =
    useAsyncButton(onDecline, {
      onError: () => toast.error('Ошибка при отклонении игроков'),
      onSuccess: () => toast.success('Игроки отклонены'),
    });

  const showAcceptButton =
    selectedStatus === ApplicationStatus.PENDING ||
    selectedStatus === ApplicationStatus.DECLINED;
  const showDeclineButton =
    selectedStatus === ApplicationStatus.PENDING ||
    selectedStatus === ApplicationStatus.ACCEPTED;

  return (
    <div className={classNames('d-flex align-items-center', className)}>
      <div
        className="d-flex"
        css={cssBlockButtons(
          selectedIds.length > 0,
          showAcceptButton,
          showDeclineButton
        )}
      >
        {showAcceptButton && (
          <UDButton
            style={{ width: 116, minWidth: 116, height: 36 }}
            variant="primary"
            onClick={onClickAccept}
            disabled={isDeclineLoading}
            loading={isAcceptLoading}
          >
            Принять
          </UDButton>
        )}
        {showDeclineButton && (
          <UDButton
            style={{ width: 116, minWidth: 116, height: 36 }}
            variant="secondary"
            onClick={onClickDecline}
            disabled={isAcceptLoading}
            loading={isDeclineLoading}
          >
            Отклонить
          </UDButton>
        )}
      </div>
      <div style={{ width: 300 }}>
        <UDInput
          style={{ border: '1px solid #E7E7E7' }}
          value={name}
          placeholder={inputPlaceholder}
          iconProps={{
            position: 'icon-left',
            name: 'search',
            size: 20,
            componentProps: { style: { color: '#8F8E99' } },
          }}
          onChange={handleOnChange}
        />
      </div>

      <div className="d-flex ml-auto">
        {options.map((item) => {
          const isActive = item.status === selectedStatus;
          const statusString = item.status as any as string;

          return (
            <FilterButton
              key={statusString}
              className={classNames({ active: isActive })}
              onClick={handleSelect(item.status)}
            >
              <span className="ml-2 text subhead">
                {t(`applications.statusFilter.${item.status}`)}
                {countByStatus && countByStatus[statusString] !== undefined && (
                  <span className="ml-1 text" style={{ color: item.color }}>
                    ({countByStatus[statusString]})
                  </span>
                )}
              </span>
            </FilterButton>
          );
        })}
      </div>
    </div>
  );
}

export default ChampionshipApplicationFilters;

const cssBlockButtons = (
  isActive: boolean,
  showAcceptButton: boolean,
  showDeclineButton: boolean
) => css`
  gap: 16px;
  overflow: hidden;

  transition: padding ${isActive ? '0s' : '0.25s'} ease-in-out,
    margin-right 0.25s ease-in-out, width 0.25s ease-in-out;

  padding: ${isActive ? '1px' : '0'};
  margin-right: ${isActive ? '20px' : '0'};
  width: ${isActive ? calcWidth(showAcceptButton, showDeclineButton) : '0'};
`;

const calcWidth = (showAcceptButton: boolean, showDeclineButton: boolean) => {
  if (showAcceptButton && showDeclineButton) {
    return '250px';
  }
  if (showAcceptButton) {
    return '118px';
  }
  if (showDeclineButton) {
    return '118px';
  }
  return '0';
};
