import { Route, Routes, useMatch } from 'react-router-dom';

import ChampionshipApplicationsPage from '../../../../teams/ui/pages/championship-applications-page';
import { ChampionshipInfoForm } from 'features/championship';
import { ChampionshipStateData } from '../../../store/championship';
import ChampionshipSymbolicTeams from '../form-parts/symbolic-teams/symbolic-teams';
import InfoFormSkeleton from 'features/championship/info/ui/components/info-form-skeleton';
import TourneysComponentsFormRulesPart from '../form-parts/rules';
import TourneysComponentsStages from '../form-parts/stages/stages';
import { championshipIsLoadingSelector } from '../../../store/championship/selectors';
import { useOrganizer } from '../../../../organizer/ui/hooks/useOrganizer';
import { useSelector } from 'react-redux';

type Props = {
  tourney?: ChampionshipStateData;
};

const TourneyComponentsEditForm = (props: Props) => {
  const { info } = useOrganizer();
  const isCommon = useMatch({ path: '/tourneys/:id/common' });
  const { tourney } = props;
  const isChampionshipLoading = useSelector(championshipIsLoadingSelector);

  if (isCommon && (!info || isChampionshipLoading || !tourney)) {
    return <InfoFormSkeleton showDeleteButton={true} />;
  }

  if (!info || !tourney) {
    return null;
  }

  return (
    <Routes>
      <Route
        path="rules"
        element={
          <TourneysComponentsFormRulesPart
            tournamentId={info!.id}
            championshipId={tourney.id}
          />
        }
      />
      <Route
        path="applications/*"
        element={<ChampionshipApplicationsPage championshipId={tourney.id} />}
      />
      <Route
        path="steps"
        element={<TourneysComponentsStages championship={tourney} />}
      />
      <Route
        path="symbolic-teams"
        element={
          <ChampionshipSymbolicTeams
            championshipId={tourney.id}
            items={tourney.symbolicTeams}
          />
        }
      />
      <Route
        path="*"
        element={
          <ChampionshipInfoForm
            tournamentId={info!.id}
            championshipId={tourney.id}
          />
        }
      />
    </Routes>
  );
};

export default TourneyComponentsEditForm;
