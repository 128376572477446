import FootballDraw from './football/draw';
import FootballGame from './football/game';
import FootballLose from './football/lose';
import FootballRanking from './football/ranking';
import FootballWin from './football/win';
import GeneralDraw from './general/draw';
import GeneralGame from './general/game';
import GeneralLose from './general/lose';
import GeneralRanking from './general/ranking';
import GeneralWin from './general/win';
import { SportType } from './types';

const Regulations = (props?: { sportType?: SportType }) => {
  const { sportType } = props || {};

  const renderRuleComponents = () => {
    switch (sportType) {
      case 'football':
        return {
          Win: FootballWin,
          Lose: FootballLose,
          Draw: FootballDraw,
          Game: FootballGame,
          Ranking: FootballRanking,
        };
      case 'general':
      default:
        return {
          Win: GeneralWin,
          Lose: GeneralLose,
          Draw: GeneralDraw,
          Game: GeneralGame,
          Ranking: GeneralRanking,
        };
    }
  };

  const { Win, Lose, Draw, Game, Ranking } = renderRuleComponents();

  return (
    <>
      <div className="row mt-5 g-2">
        <div className="col-5">
          <div className="b-1 br-8 p-4 h-100">
            <Win />
          </div>
        </div>
        <div className="col-5">
          <div className="b-1 br-8 p-4 h-100">
            <Lose />
          </div>
        </div>
        <div className="col-2">
          <div className="b-1 br-8 p-4 h-100">
            <Draw />
          </div>
        </div>
        <div className="col-10">
          <div className="b-1 br-8 p-4 h-100">
            <Game />
          </div>
        </div>
        <div className="col-7">
          <div className="b-1 br-8 p-4 h-100">
            <Ranking />
          </div>
        </div>
      </div>
    </>
  );
};

export default Regulations;
