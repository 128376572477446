import GameBase from '../components/base/game-base';

const FootballGame = () => {
  return (
    <GameBase sportType="football">
      {/* <div className="mt-4">
        <div className="row gx-4">
          <div className="col-6">
            <LabeledInput
              name="settings.halftimeBreak"
              placeholder="0 мин"
              label="Длительность перерыва"
            />

            <LabeledCheckbox
              name="settings.penaltiesAfterDraw"
              label="Пенальти после ничьей в основное время"
            />
          </div>
          <div className="col-6">
            <LabeledInput
              name="settings.penaltiesCount"
              placeholder="5"
              label="Количество пенальти в серии"
            />
          </div>
        </div>
      </div> */}
    </GameBase>
  );
};

export default FootballGame;
