import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  announceNewTeam,
  getChampionshipAnnouncedTeams,
  playerChampionshipApplicationNumberChanged,
  playerChampionshipApplicationPositionChanged,
  upsertChampionshipApplicationUsers,
  upsertChampionshipPlayerApplication,
  upsertChampionshipTeamApplication
} from './actions';

import { AnnouncedTeamStatus } from '../../domain/enums/AnnouncedTeamStatus';
import { AnnouncedTeamsMap } from '../../domain/interfaces/AnnouncedTeamsMap';
import { ApplicationStatus } from '../../../applications/domain/enums/ApplicationStatus';
import { PlayerApplication } from '../../domain/interfaces/PlayerApplication';
import { PlayerPosition } from '../../domain/enums/PlayerPosition';
import { UserResponse } from '../../domain/dtos/UpsertChampionshipApplicationResponse.dto';

type State<Entity, Key extends number = number> = {
  byId: Record<Key, Entity>;
  allIds: Key[];
};

type ChampionshipIds = {
  championshipId: number;
}[]

function updateState<Entity, Key extends number = number>(state: State<Entity, Key> | null, payload: Entity, key: Key) {
  return {
    byId: { ...state?.byId, [key]: payload },
    allIds: [...(state?.allIds || []), key],
  };
}

export type ChampionshipTeamsSliceState = {
  championshipId: number | null;
  isLoading: boolean;
  updatingPlayerApplication: number | null;
  updatingTeamApplication: number | null;
  data: State<AnnouncedTeamsMap> | null;
  teams: {
    byId: Record<number, ChampionshipIds>;
    allIds: number[];
  };
};

const initialState: ChampionshipTeamsSliceState = {
  championshipId: null,
  isLoading: false,
  updatingPlayerApplication: null,
  updatingTeamApplication: null,
  data: null,
  teams: {
    byId: {},
    allIds: [],
  },
};

function createGetChampionshipAnnouncedTeamsReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(getChampionshipAnnouncedTeams.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getChampionshipAnnouncedTeams.fulfilled, (state, { payload, meta }) => {
    const championshipId = meta.arg.championshipId;
    state.championshipId = championshipId;
    state.data = updateState<AnnouncedTeamsMap>(state.data!, payload, championshipId);
    state.teams = Object.keys(payload).reduce((acc, teamId) => {
      const teamIdNumber = Number(teamId);
      acc.byId[teamIdNumber] = [...(acc.byId[teamIdNumber] || []), { championshipId }];
      acc.allIds = Array.from(new Set([...acc.allIds, teamIdNumber]));
      return acc;
    }, { ...state.teams });
    state.isLoading = false;
  });

  builder.addCase(getChampionshipAnnouncedTeams.rejected, (state) => {
    state.isLoading = false;
  });
}

function createUpsertChampionshipPlayerApplication(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(upsertChampionshipPlayerApplication.pending, (state, { meta }) => {
    if (meta.arg.id) {
      state.updatingPlayerApplication = meta.arg.id;
    }
  });

  builder.addCase(upsertChampionshipPlayerApplication.fulfilled, (state) => {
    state.updatingPlayerApplication = null;
  });

  builder.addCase(upsertChampionshipPlayerApplication.rejected, (state) => {
    state.updatingPlayerApplication = null;
  });
}

function createUpsertChampionshipTeamApplicationReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(upsertChampionshipTeamApplication.pending, (state, { meta }) => {
    if (meta.arg.teamId) {
      state.updatingTeamApplication = meta.arg.teamId;
    }
  });

  builder.addCase(upsertChampionshipTeamApplication.fulfilled, (state, { payload, meta }) => {
    if (state.data && payload) {
      const teamData = state.data.byId[meta.arg.championshipId];

      state.data.byId[meta.arg.championshipId][payload.teamId] = {
        ...teamData[payload.teamId],
        status: payload.status,
        applications: teamData[payload.teamId].applications.map((app) => ({
          ...app,
          status: payload.applicationStatus,
        })),
      }

      state.teams.byId[payload.teamId] = [...(state.teams.byId[payload.teamId] || []), { championshipId: meta.arg.championshipId }];
      state.teams.allIds = Array.from(new Set([...state.teams.allIds, payload.teamId]));
    }

    state.updatingTeamApplication = null;
  });

  builder.addCase(upsertChampionshipTeamApplication.rejected, (state) => {
    state.updatingTeamApplication = null;
  });
}

function createAnnounceNewTeamReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(announceNewTeam.fulfilled, (state, { payload }) => {
    const createdAt = new Date();

    if (!state.data) {
      state.data = {
        byId: {},
        allIds: [],
      };
    }

    state.data = {
      byId: {
        ...state.data.byId,
        [payload.championshipId]: {
          ...state.data.byId[payload.championshipId],
          [payload.team.id]: {
            id: payload.team.id,
            name: payload.team.name,
            status: AnnouncedTeamStatus.ANNOUNCED,
            applications: payload.team.players.map((player, index) => ({
              id: index,
              teamId: payload.team.id,
              championshipId: payload.championshipId,
              status: ApplicationStatus.PENDING,
              number: player.number,
              position: player.position as PlayerPosition,
              player: {
                id: player.userId,
                firstName: player.firstName,
                lastName: player.lastName,
                logoUrl: player.avatar,
              },
              createdAt: createdAt.toISOString(),
            })),
            applicationDate: createdAt.toISOString() as unknown as string,
            isNew: true,
            logo: payload.team.logo,
            createdAt: payload.team.createdAt,
          },
        },
      },
      allIds: [...state.data.allIds, payload.championshipId],
    };

    state.teams.byId[payload.team.id] = [...(state.teams.byId[payload.team.id] || []), { championshipId: payload.championshipId }];
    state.teams.allIds = Array.from(new Set([...state.teams.allIds, payload.team.id]));
  });
}

function createPlayerChampionshipApplicationNumberChangedReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(playerChampionshipApplicationNumberChanged, (state, { payload }) => {
    if (!state.data || !state.data.byId[payload.championshipId][payload.teamId]) {
      return;
    }

    state.data = {
      ...state.data,
      byId: {
        ...state.data.byId,
        [payload.championshipId]: {
          ...state.data.byId[payload.championshipId],
          [payload.teamId]: {
            ...state.data.byId[payload.championshipId][payload.teamId],
            applications: state.data.byId[payload.championshipId][payload.teamId].applications.map((app) => {
              if (app.id === payload.id) {
                return {
                  ...app,
                  number: payload.number,
                };
              }

              return app;
            })
          }
        }
      },
      allIds: Array.from(new Set([...state.data.allIds, payload.championshipId])),
    };

    state.teams.byId[payload.teamId] = [...(state.teams.byId[payload.teamId] || []), { championshipId: payload.championshipId }];
    state.teams.allIds = Array.from(new Set([...state.teams.allIds, payload.teamId]));
  });
}

function createPlayerChampionshipApplicationPositionChangedReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(playerChampionshipApplicationPositionChanged, (state, { payload }) => {
    if (!state.data || !state.data.byId[payload.championshipId][payload.teamId]) {
      return;
    }

    state.data = {
      ...state.data,
      byId: {
        ...state.data.byId,
        [payload.championshipId]: {
          ...state.data.byId[payload.championshipId],
          [payload.teamId]: {
            ...state.data.byId[payload.championshipId][payload.teamId],
            applications: state.data.byId[payload.championshipId][payload.teamId].applications.map((app) => {
              if (app.id === payload.id) {
                return {
                  ...app,
                  position: payload.position,
                };
              }

              return app;
            })
          }
        }
      },
      allIds: Array.from(new Set([...state.data.allIds, payload.championshipId])),
    };

    state.teams.byId[payload.teamId] = [...(state.teams.byId[payload.teamId] || []), { championshipId: payload.championshipId }];
    state.teams.allIds = Array.from(new Set([...state.teams.allIds, payload.teamId]));
  });
}

function createUpsertChampionshipApplicationUsersReducer(builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) {
  builder.addCase(upsertChampionshipApplicationUsers.pending, (state, { meta }) => {
    if (meta.arg.teamId) {
      state.updatingTeamApplication = meta.arg.teamId;
    }
  });

  builder.addCase(upsertChampionshipApplicationUsers.fulfilled, (state, { payload, meta }) => {
    if (state.data && payload) {
      const teamData = state.data.byId[meta.arg.championshipId][payload.team.id];
      if (teamData) {
        const teamStatus = payload.team.state === ApplicationStatus.ACCEPTED
          ? AnnouncedTeamStatus.ACCEPTED
          : payload.team.state === ApplicationStatus.DECLINED
            ? AnnouncedTeamStatus.DECLINED
            : AnnouncedTeamStatus.ANNOUNCED;

        state.data.byId[meta.arg.championshipId][payload.team.id] = {
          ...teamData,
          status: teamStatus,
          applications: teamData.applications.map(app => {
            const updatedUser = payload.applications.find((user: UserResponse) => user.id === app.player.id);
            if (updatedUser) {
              return {
                ...app,
                status: updatedUser.state || app.status,
                player: {
                  ...app.player,
                  firstName: updatedUser.name,
                  lastName: updatedUser.surname,
                  middleName: updatedUser.middleName,
                  birthDate: updatedUser.birthDate,
                  logoUrl: updatedUser.photo?.externalUrl
                }
              };
            }
            return app;
          })
        };
        state.data.allIds = [...state.data.allIds, payload.team.id];
      }
    }
    state.updatingTeamApplication = null;
  });

  builder.addCase(upsertChampionshipApplicationUsers.rejected, (state) => {
    state.updatingTeamApplication = null;
  });
}

const slice = createSlice({
  name: 'teams/championshipTeams',
  initialState,
  reducers: {
    updatePlayerApplication: (state, action: PayloadAction<{ teamId: number, id: number, application: PlayerApplication }>) => {
      if (!state.data) {
        return;
      }

      const team = state.data?.byId[action.payload.application.championshipId][action.payload.teamId];
      if (!team) {
        return;
      }

      const applications = team.applications.map(app => app.id === action.payload.id ? action.payload.application : app);
      team.applications = applications;
    },
    addPlayerApplication: (state, action: PayloadAction<{ teamId: number, application: Omit<PlayerApplication, 'championshipId'> }>) => {
      if (!state.data) {
        return;
      }

      const championshipIds = state.teams.byId[action.payload.application.teamId].map(championship => championship.championshipId);
      if (!championshipIds) {
        return;
      }

      for (const championshipId of championshipIds) {
        state.data.byId[championshipId][action.payload.teamId].applications.push({
          ...action.payload.application,
          championshipId,
        });
      }
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<ChampionshipTeamsSliceState>) => {
    createGetChampionshipAnnouncedTeamsReducer(builder);
    createUpsertChampionshipTeamApplicationReducer(builder);
    createAnnounceNewTeamReducer(builder);
    createUpsertChampionshipPlayerApplication(builder);
    createPlayerChampionshipApplicationNumberChangedReducer(builder);
    createPlayerChampionshipApplicationPositionChangedReducer(builder);
    createUpsertChampionshipApplicationUsersReducer(builder);
  }
});

export const { updatePlayerApplication, addPlayerApplication } = slice.actions;

export default slice.reducer;
