/** @jsxImportSource @emotion/react */

import { Form, Formik, FormikProps } from 'formik';
import React, { useCallback } from 'react';
import {
  modalButton,
  modalError,
  modalInputStyle,
} from '../../components/elements/modals/styles';

import { Button } from '../../../../ud-ui/components/button/styles';
import UDFormInput from '../../../../ud-form/components/input';
import UDText from '../../../../ud-ui/components/text';
import _ from 'lodash';
import classNames from 'classnames';
import useModal from './useModal';

export interface IUNModalProps {
  headerText: string;
  validator?: (value: { [p: string]: string }) => {
    [p: string]: string | undefined;
  };
  initialValue: {
    [key: string]:
      | string
      | { label: string; value: string }
      | { label: string; value: string }[]
      | undefined;
  };
  isProcessed?: boolean;
  buttonText: string;
  contentText?: string;
  input?: {
    placeholder: string;
  };
  additionalComponents?: React.ReactNode[];
  onClose?: () => void;
  onSubmit?: (values: { [p: string]: string }) => void;
  additionalButtons?: React.ReactNode[];
  addImportButton?: boolean;
}

function useUniversalModal(props: IUNModalProps) {
  const {
    headerText,
    contentText,
    input,
    buttonText,
    additionalComponents,
    initialValue,
    validator,
    onClose,
    onSubmit,
    isProcessed,
    additionalButtons,
  } = props;

  const onFormSubmit = (values: { [p: string]: string }) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  const getErrors = (props: FormikProps<{ [p: string]: string }>) => {
    return _.find(
      props.errors,
      (error, key) => !!error && !!props.touched[key]
    );
  };

  const content = (
    <Formik
      validateOnChange
      // @ts-ignore
      initialValues={initialValue}
      onSubmit={onFormSubmit}
      validate={(values) => (!validator ? {} : validator(values))}
    >
      {(formProps) => {
        return (
          <Form>
            <div
              key={'modal-content'}
              style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
            >
              <UDText type={'title-3'}>{headerText}</UDText>
              {contentText && <UDText type={'subhead'}>{contentText}</UDText>}
              {input && (
                <UDFormInput
                  readOnly={isProcessed}
                  onChangeCapture={() =>
                    formProps.values.name &&
                    !formProps.touched.name &&
                    formProps.setFieldTouched('name')
                  }
                  name={'name'}
                  autoFocus={true}
                  css={modalInputStyle}
                  placeholder={input.placeholder}
                />
              )}
              {additionalComponents}
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  gap: 24,
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  className={'d-flex flex-row align-items-center'}
                  style={{ height: 36, gap: 24 }}
                >
                  <Button
                    type={'submit'}
                    className={classNames(
                      'flex-center',
                      isProcessed && 'loading'
                    )}
                    disabled={
                      !formProps.isValid || formProps.values.name === ''
                    }
                    css={modalButton}
                  >
                    {buttonText}
                  </Button>
                  <span css={modalError}>{getErrors(formProps)}</span>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const { modal, open, close } = useModal({ children: content, onClose });

  const onOpen = useCallback(() => {
    open({
      modalStyle: {
        padding: 0,
        zIndex: 9000,
        position: 'fixed',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderColor: 'transparent',
        overflow: 'clip',
        background: '#00000085',
      },
    });
  }, [open]);

  return { modal, close, open: onOpen };
}

export default useUniversalModal;
