import * as S from './styles';

import ChangeProfileIcon from 'modules/ud-ui/components/icon/change-profile-icon';
import { MenuSkeletonBlock } from '../../styles';
import { Organizer } from 'modules/organizer/domain/interfaces/Organizer';
import { Pages } from '../../../../../navigation/domain/enums/pages';
import PlusIcon from 'modules/ud-ui/components/icon/plus-icon';
import React from 'react';
import UDButton from 'modules/ud-ui/components/button';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import UDLink from '../../../link';
import classNames from 'classnames';
import styled from '@emotion/styled';

type UDMenuLogoutProps = {
  isOrganizerLoading: boolean;
  organizerInfo: Organizer | null;
  onLogoutClick?: React.MouseEventHandler<HTMLElement>;
  onPlusClick?: React.MouseEventHandler<HTMLElement>;
  onChangeProfileClick?: React.MouseEventHandler<HTMLElement>;
};

const UDMenuLogout = (props: UDMenuLogoutProps) => {
  const logo = props.organizerInfo?.logoUrl ? (
    <S.MenuLogo src={props.organizerInfo?.logoUrl!} width={36} />
  ) : null;

  return (
    <S.MenuLogout
      className={classNames(
        'd-flex flex-row flex-center align-items-start pl-10 pr-2 w-100'
      )}
    >
      {props.organizerInfo ? (
        <UDLink
          className="bold color-SurfaceWhite organizer-link"
          to={Pages.SELECT_TOURNAMENT}
          disabled={!props.organizerInfo}
        >
          {logo}
          <span
            className="organizer-link-name"
            style={{
              lineClamp: 2,
              WebkitLineClamp: 2,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {props.organizerInfo?.name}
          </span>
        </UDLink>
      ) : (
        <MenuSkeletonBlock
          className="skeleton-block"
          style={{ height: '42px' }}
        />
      )}

      <UDButton
        variant="dark"
        className="icon d-flex align-items-center ml-auto"
        onClick={props.onChangeProfileClick}
        disabled={!props.organizerInfo}
      >
        <ChangeProfileIcon />
      </UDButton>
      <UDButton
        variant="dark"
        className="icon d-flex align-items-center"
        onClick={props.onPlusClick}
        disabled={!props.organizerInfo}
      >
        <PlusIcon />
      </UDButton>
      <UDButton
        variant="dark"
        className="icon d-flex align-items-center"
        onClick={props.onLogoutClick}
      >
        <UDColorableIcon name="logout" />
      </UDButton>
    </S.MenuLogout>
  );
};

export default UDMenuLogout;
