import { Items, modalContainerCss } from './style';
import { useCallback, useMemo } from 'react';

import AltIcon from '../../../../../ud-ui/components/icon/alt-icon';
import CommandIcon from '../../../../../ud-ui/components/icon/command-icon';
import Item from './item';
import UDText from '../../../../../ud-ui/components/text';
import useModal from '../useModal';

function useTipModal() {
  const modalContent = useMemo(
    () => (
      <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
        <UDText type={'title-3'}>Подсказки</UDText>
        <Items>
          <Item
            text={'Перетаскивание сетки'}
            windows={{ icon: <AltIcon />, text: '+ клик' }}
            mac={{ icon: <CommandIcon />, text: '+ клик' }}
          />
        </Items>
      </div>
    ),
    []
  );

  const data = {
    children: modalContent,
  };

  const { modal, open, close } = useModal(data);

  const onOpen = useCallback(() => {
    open({
      modalStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9000,
        background: '#0000008C',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        borderRadius: 0,
      },
      containerProps: { css: modalContainerCss },
    });
  }, [open]);

  return { modal, open: onOpen, close };
}

export default useTipModal;
