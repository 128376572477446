import RankingBase from '../components/base/ranking-base';

const FootballRanking = () => {
  return (
    <RankingBase sportType="football">
      {/* <div className="mt-4">
        <LabeledCheckbox
          name="settings.useGoalDifference"
          label="Учитывать разницу забитых и пропущенных мячей"
          containerClassName="d-flex align-items-center mt-3"
        />

        <LabeledCheckbox
          name="settings.useDirectMatches"
          label="Учитывать результаты личных встреч"
          containerClassName="d-flex align-items-center mt-3"
        />

        <LabeledCheckbox
          name="settings.prioritizeAwayGoals"
          label="Приоритет голов на выезде при равенстве очков"
          containerClassName="d-flex align-items-center mt-3"
        />
      </div> */}
    </RankingBase>
  );
};

export default FootballRanking;
