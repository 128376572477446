import { Form, Formik, FormikValues } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  championshipDataSelector,
  championshipISavingSelector,
  championshipIsDeletingSelector,
} from 'modules/tourneys/store/championship/selectors';
import {
  deleteChampionship as deleteChampionshipAction,
  upsertChampionship,
} from 'modules/tourneys/store/championship/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppDispatch } from 'store/store';
import { ChampionshipStateData } from 'modules/tourneys/store/championship';
import { Pages } from 'modules/navigation/domain/enums/pages';
import { SelectOption } from '../../../../../modules/ud-form/components/select/component';
import { TFunction } from 'i18next';
import { TeamPlayersNumber } from 'shared/types/team-players-number';
import UDButton from 'modules/ud-ui/components/button';
import UDFormDateRange from 'modules/ud-form/components/datetime/range';
import UDFormInput from 'modules/ud-form/components/input';
import UDFormLocationSelect from '../../../../../modules/ud-form/components/select/ud-form-location-select';
import UDFormSelect from 'modules/ud-form/components/select';
import UDFormTextearea from 'modules/ud-form/components/input/textarea';
import UdFormTemplateSelect from '../../../../../modules/ud-form/components/select/ud-form-template-select';
import _ from 'lodash';
import { fetchOrganizer } from 'modules/organizer/store/actions';
import { fetchUserTemplates } from '../../../../../modules/template-editor/store/templates/actions';
import { getTeamPlayersNumberOptions } from 'shared/utils';
import { removeChampionshipFromList } from '../../../../../modules/organizer/store';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAsyncButton } from 'modules/ud-ui/hooks/useAsyncButton';
import { useAuth } from '../../../../../modules/auth/ui/hooks/useAuth';
import { useModal } from '../../../../../modules/ud-ui/components/modal/useModal';
import { useOrganizer } from '../../../../../modules/organizer/ui/hooks/useOrganizer';
import useTemplates from '../../../../../modules/template-editor/ui/hooks/useTemplates';
import { useTranslation } from 'react-i18next';

const getTranslatedTeamPlayersNumberOption = (
  t: TFunction,
  playersNumber: number
) => ({
  label: t(`championship.info.teamPlayersNumber.${playersNumber}`),
  value: playersNumber,
});

const getTranslatedTeamPlayersNumberOptions = (t: TFunction) =>
  getTeamPlayersNumberOptions().map((num) =>
    getTranslatedTeamPlayersNumberOption(t, num)
  );

type ChampionshipInfoFormProps = {
  tournamentId: number;
  championshipId?: number;
  initialValues?: {
    id?: number;
    name: string;
    startDate: Date;
    endDate: Date;
  };
  onCreateGameModalOpen?: () => void;
};

type OverlayOptions = {
  label: string;
  value: number;
};

export const ChampionshipInfoForm = ({
  tournamentId,
  championshipId,
}: ChampionshipInfoFormProps) => {
  const location = useLocation();
  const state = location.state as undefined | { highlight: string[] };
  const isCreate = useMemo(() => {
    return location.pathname.includes('create');
  }, [location.pathname]);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const championship: ChampionshipStateData | undefined = useSelector(
    championshipDataSelector(championshipId ?? -1)
  );
  const navigate = useNavigate();

  if (!championship?.id && !isCreate) {
    navigate(Pages.TOURNEYS.INDEX);
  }

  const { templates, isLoaded: isTemplatesLoaded } = useTemplates();

  const isChampionshipSaving = useSelector(championshipISavingSelector);
  const isDelete = useSelector(championshipIsDeletingSelector);

  const [overlayOptions, setOverlayOptions] =
    React.useState<OverlayOptions[]>();
  const [isOBSAvailable, setIsOBSAvailable] = React.useState(false);

  const hasChampionship = !!championship;
  const typeOptions = getTranslatedTeamPlayersNumberOptions(t);
  const teamPlayersNumber =
    championship?.settings?.teamPlayersNumber || TeamPlayersNumber.FIVE;
  const tournamentType = getTranslatedTeamPlayersNumberOption(
    t,
    teamPlayersNumber
  );

  const { info } = useOrganizer();
  const { user } = useAuth();
  const isDev = useMemo(() => {
    return process.env.REACT_APP_ENVIRONMENT === 'development';
  }, []);
  const isAdmin = useMemo(() => {
    if (isDev) return true;
    if (!user) return false;
    return user.id === 20;
  }, [isDev, user]);

  useEffect(() => {
    setIsOBSAvailable(info?.availableFeatures.OBS_INTEGRATION || isDev);
  }, [info, isDev]);

  useEffect(() => {
    if (isTemplatesLoaded) return;
    dispatch(
      fetchUserTemplates({
        ownerId: tournamentId,
        visibility: isAdmin ? ['superAdmin', 'all'] : ['all'],
      })
    );
  }, [dispatch, isAdmin, isTemplatesLoaded, tournamentId]);

  useEffect(() => {
    if (!isTemplatesLoaded) return;
    if (templates.length <= 0) return;

    setOverlayOptions(
      templates.map((template) => ({
        label: template.name === 'ImGame (стандарт)' ? 'ImGame' : template.name,
        value: template.id,
      }))
    );
  }, [isTemplatesLoaded, templates]);

  const initialValues = {
    name: championship?.name || '',
    description: championship?.description || '',
    startDate: championship?.startDate ? new Date(championship.startDate) : '',
    endDate: championship?.endDate ? new Date(championship.endDate) : '',
    tournamentType,
    locations:
      championship?.locations.map((loc) => ({
        label: loc.name,
        value: loc.id,
      })) || [],
    template: _.find(overlayOptions, {
      value: championship?.settings?.overlayId,
    }),
  };

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      const { locations, tournamentType, template, ...otherValues } = values;
      const locationIds = locations
        ? locations.map((loc: SelectOption<Location>) => loc.value)
        : [];
      if (state && state.highlight) {
        state.highlight = [];
      }

      const data = {
        ...otherValues,
        name: otherValues.name.trim(),
        description: otherValues.description.trim(),
        id: championship?.id,
        tournamentId,
        locationIds,
        teamPlayersNumber: tournamentType.value,
        overlayId: isOBSAvailable
          ? template?.value ?? templates[0]?.id ?? null
          : _.find(templates, { access: 'default' })?.id ?? null,
      };

      dispatch(upsertChampionship(data)).then((result) => {
        dispatch(fetchOrganizer({ tournamentId }));

        if (!hasChampionship) {
          const championshipId = unwrapResult(result);
          const pageUrl = Pages.TOURNEYS.builders.edit(championshipId, 'rules');

          navigate(pageUrl, { replace: true });
        }
      });
    },
    [
      championship?.id,
      dispatch,
      hasChampionship,
      isOBSAvailable,
      navigate,
      state,
      templates,
      tournamentId,
    ]
  );

  const {
    open: openDeleteModal,
    close: closeDeleteModal,
    Modal: ConfirmDeleteModal,
  } = useModal({ defaultShow: false });

  const deleteChampionship = useCallback(async () => {
    if (!championship?.id) {
      toast.warn('Турнир не найден');
      return;
    }

    await dispatch(
      deleteChampionshipAction({ championshipId: championship.id })
    ).unwrap();
    closeDeleteModal();
    dispatch(removeChampionshipFromList({ id: championship.id }));
    navigate(Pages.TOURNEYS.INDEX);
  }, [championship?.id, closeDeleteModal, dispatch, navigate]);

  const { handleClick: handleDeleteChampionship, isLoading: isDeleting } =
    useAsyncButton(deleteChampionship);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <Form onSubmit={formProps.handleSubmit}>
            <div className="container g-2 mb-5">
              <div className="col-6">
                <UDFormInput
                  autoFocus={isCreate}
                  name="name"
                  label={t('championship.info.nameInput.label')}
                  placeholder={t('championship.info.nameInput.placeholder')}
                  containerProps={{ className: 'mb-5' }}
                />
                <UDFormDateRange
                  autoComplete="off"
                  startName="startDate"
                  endName="endDate"
                  startLabel={t('championship.info.startDateInput.label')}
                  endLabel={t('championship.info.endDateInput.label')}
                  containerProps={{ className: 'mb-5' }}
                />
                <UDFormSelect
                  name="tournamentType"
                  label={t('championship.info.typeSelect.label')}
                  placeholder={t('championship.info.typeSelect.placeholder')}
                  containerProps={{ className: 'mb-5' }}
                  options={typeOptions}
                />
                <UDFormLocationSelect
                  name="locations"
                  label={t('championship.info.locationInput.label')}
                  placeholder={t('championship.info.locationInput.placeholder')}
                  containerProps={{ className: 'mb-5' }}
                  isMulti
                />
                <UdFormTemplateSelect
                  name="template"
                  label={t('championship.info.overlayInput.label')}
                  placeholder={t('championship.info.overlayInput.placeholder')}
                  containerProps={{ className: 'mb-5' }}
                  options={overlayOptions}
                  isAvailable={isOBSAvailable}
                  highlight={state?.highlight?.includes('template')}
                />
                <UDFormTextearea
                  name="description"
                  label={t('championship.info.descriptionInput.label')}
                  placeholder={t(
                    'championship.info.descriptionInput.placeholder'
                  )}
                  containerProps={{ className: 'mb-5' }}
                />

                <div className="d-flex gap-6">
                  <UDButton
                    variant="primary"
                    type="submit"
                    loading={isChampionshipSaving}
                    disabled={
                      isChampionshipSaving ||
                      !formProps.dirty ||
                      !isTemplatesLoaded ||
                      isDelete ||
                      !formProps.isValid
                    }
                    className="w-100"
                  >
                    {t('championship.info.saveButton.title')}
                  </UDButton>

                  {!isCreate && (
                    <UDButton
                      variant="secondary"
                      type="button"
                      disabled={
                        isDelete || !isTemplatesLoaded || isChampionshipSaving
                      }
                      className="w-100"
                      onClick={openDeleteModal}
                      style={{ color: 'red', outline: 'none' }}
                    >
                      {t('championship.info.deleteButton.title')}
                    </UDButton>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmDeleteModal
        header={{
          title: 'Подтвердить удаление',
          subtitle: 'Вы действительно хотите удалить турнир?',
        }}
      >
        <div className="mt-10 d-flex justify-content-between">
          <UDButton
            variant="secondary"
            className="mb-3"
            type="button"
            onClick={closeDeleteModal}
            disabled={isDeleting}
          >
            Отмена
          </UDButton>

          <UDButton
            variant="primary"
            className="mb-3"
            type="button"
            onClick={handleDeleteChampionship}
            loading={isDeleting}
          >
            Удалить
          </UDButton>
        </div>
      </ConfirmDeleteModal>
    </>
  );
};
