/** @jsxImportSource @emotion/react */

import * as S from './styles';

import CircleChevronIcon from '../../../../ud-ui/components/icon/circle-chevron-icon';
import { HideScroll } from 'shared/styles/hide-scroll';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import UDText from 'modules/ud-ui/components/text';
import classNames from 'classnames';
import { components } from 'react-select';
import { useCallback } from 'react';

const NoOptions = () => {
  return (
    <div className="flex-center flex-column p-8">
      <UDText type="subhead" className="color-SurfaceRomanSilver30 mb-2">
        Список пуст
      </UDText>
    </div>
  );
};

export const DropDown = (props: any) => {
  return (
    <div
      id="dropdown-indicator"
      ref={props.innerRef}
      {...props.innerProps}
      className="p-2 flex-center"
    >
      <CircleChevronIcon width={20} height={20} />
    </div>
  );
};

export const DropDownWhite = (props: any) => {
  return (
    <div ref={props.innerRef} {...props.innerProps} className="p-2 flex-center">
      <CircleChevronIcon width={20} height={20} color="white" />
    </div>
  );
};

const Option = (props: any) => {
  const className = classNames(
    'd-flex align-items-center justify-content-between px-4',
    props.isMulti ? 'py-1' : 'py-2'
  );

  return (
    <S.Option {...props.innerProps} className={className}>
      <UDText type="subhead">{props.children}</UDText>
      {props.isMulti && <S.OptionCheckbox data-checked={props.isSelected} />}
    </S.Option>
  );
};

const MultiValue = (props: any) => {
  return (
    <S.MultiValue>
      <UDText
        type="subhead"
        style={{
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {props.children}
      </UDText>
    </S.MultiValue>
  );
};

const SingleValue = (props: any) => {
  return (
    <div>
      <UDText type="subhead">{props.children}</UDText>
    </div>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <div
      ref={props.innerRef}
      {...props.innnerProps}
      onClick={props.clearValue}
      className="p-2 flex-center"
    >
      <UDColorableIcon
        name="close"
        size={16}
        componentProps={{
          className: 'color-SurfaceRomanSilver30',
        }}
      />
    </div>
  );
};

const IndicatorsContainer = (props: any) => {
  return (
    <div
      {...props.innerProps}
      className="px-2 d-flex flex-row align-item-center ml-auto"
    >
      {props.children}
    </div>
  );
};

const ValueContainer = (props: any) => {
  return (
    <S.ValueContainer
      id="value-container"
      className="d-flex flex-row align-items-center"
      {...props.innerProps}
    >
      <div className="d-flex flex-row align-items-center">
        <UDText
          className="d-flex flex-row align-items-center"
          color={'black'}
          type={'subhead'}
        >
          {props.children}
        </UDText>
      </div>
    </S.ValueContainer>
  );
};

const Control = (props: any) => {
  return (
    <S.Control
      data-error={props.selectProps.hasError}
      {...props.innerProps}
      className={classNames('d-flex flex-row pl-4', {
        disabled: props.isDisabled,
        focused: props.isFocused,
        multi: props.isMulti,
        opened: props.menuIsOpen,
      })}
    >
      {props.children}
    </S.Control>
  );
};

const SelectContainer = (props: any) => {
  return (
    <S.SelectContainer
      {...props.innerProps}
      className={classNames(props.className, 'flex-shrink-0')}
    >
      {props.children}
    </S.SelectContainer>
  );
};

const Placeholder = (props: any) => {
  return (
    <UDText
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '18px',
        // right: '12px',
      }}
      type="subhead"
      className="color-SurfaceRomanSilver30"
      {...props.innerProps}
    >
      {props.children}
    </UDText>
  );
};

const MenuList = (props: any) => {
  return <S.MenuList {...props.innnerProps}>{props.children}</S.MenuList>;
};

const MenuInfo = (props: any) => {
  const selectedOptions = props.getValue();
  const allSelected = selectedOptions.length === props.options.length;
  const buttonText = allSelected
    ? 'снять все'
    : props.hasValue
    ? 'Выбрать все'
    : 'все';
  const onSelectAll = useCallback(() => {
    if (allSelected) {
      return props.clearValue();
    }
    return props.setValue(props.options, 'select-option');
  }, [allSelected, props]);
  return (
    <S.MenuInfo className="d-flex align-items-center justify-content-between py-2 px-4">
      <UDText type="subhead" className="color-SurfaceRomanSilver30">
        Выбрано {selectedOptions.length}
      </UDText>
      <UDText
        type="subhead"
        onClick={onSelectAll}
        className="color-AccentNapplesYellow c-pointer"
      >
        {buttonText}
      </UDText>
    </S.MenuInfo>
  );
};

const Menu = (props: any) => {
  return (
    <components.Menu
      // ref={props.innerRef}
      {...props}
      {...props.innerProps}
      className={classNames(props.className, 'shadow-4', 'mt-0')}
    >
      {props.isMulti && <MenuInfo {...props} />}
      {props.children}
    </components.Menu>
  );
};

const overridedSelectComponents = {
  NoOptionsMessage: NoOptions,
  DropdownIndicator: DropDown,
  IndicatorSeparator: null,
  Option: Option,
  MultiValue: MultiValue,
  ClearIndicator: ClearIndicator,
  IndicatorsContainer: IndicatorsContainer,
  ValueContainer: ValueContainer,
  Control: Control,
  SelectContainer: SelectContainer,
  Placeholder: Placeholder,
  Menu: Menu,
  MenuList: MenuList,
  SingleValue: SingleValue,
  Input: (props: any) => <components.Input {...props} isHidden={false} />,
};

export default overridedSelectComponents;
