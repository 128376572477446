import React, { useCallback, useEffect, useMemo } from 'react';
import {
  accessOptions,
  visibilityOptions,
} from '../../../../domain/consts/form/template/options';

import TemplateForm from './forms/templateForm';
import { Values } from '../../../../domain/interface/form/template';
import heightValidator from '../../../../domain/validators/height.validator';
import { resetPressedState } from '../../../../store/templates/templates';
import templateNameOnEditValidator from '../../../../domain/validators/templateNameOnEditValidator';
import { updateTemplate } from '../../../../store/templates/actions';
import { useAuth } from '../../../../../auth/ui/hooks/useAuth';
import { useDispatch } from 'react-redux';
import useModal from '../../../../../ud-ui/components/new-modal/useModal';
import { useOrganizer } from '../../../../../organizer/ui/hooks/useOrganizer';
import useTemplates from '../../useTemplates';
import valueTypeValidator from '../../../../domain/utils/form/template/valueTypeValidator';
import widthValidator from '../../../../domain/validators/width.validator';

function useEditModal() {
  const dispatch = useDispatch();
  const { Modal, close, open } = useModal();
  const { activeTemplate, templates, isProcessed, isFinished, currentProcess } =
    useTemplates();
  const { user } = useAuth();
  const { info } = useOrganizer();
  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production';
  const isAdmin = useMemo(() => {
    if (isDev) return true;
    if (!user) return false;
    return user.id === 20;
  }, [isDev, user]);

  const validator = useCallback(
    (values: Values) => {
      const errors: Values | undefined = {};

      if (typeof values.name === 'string') {
        const nameError = templateNameOnEditValidator(
          values.name?.trim(),
          templates?.map((category) => category.name),
          activeTemplate?.name
        );
        if (nameError) {
          errors.name = nameError;
        }
      }

      if (typeof values.width === 'string') {
        const widthError = widthValidator(values.width);
        if (widthError) {
          errors.width = widthError;
        }
      }

      if (typeof values.height === 'string') {
        const heightError = heightValidator(values.height);
        if (heightError) {
          errors.height = heightError;
        }
      }

      return errors;
    },
    [templates, activeTemplate]
  );

  const onSubmit = useCallback(
    (values: Values) => {
      if (!info) {
        throw new Error('failed to update template: info is not defined');
      }

      if (!activeTemplate) return;
      const { width, height, name, visibility, access } = valueTypeValidator({
        values,
      });
      const resolution = { width: +width, height: +height };
      dispatch(
        updateTemplate({
          id: activeTemplate?.id,
          name,
          resolution,
          visibility,
          access,
          ownerId: info.id,
        })
      );
    },
    [activeTemplate, dispatch, info]
  );

  useEffect(() => {
    if (!isFinished) {
      return;
    }
    if (currentProcess !== 'update template') return;
    close();
    dispatch(resetPressedState());
  }, [isFinished, close, dispatch, currentProcess]);

  const initialValue = useMemo(
    () => ({
      width: activeTemplate?.resolution.width?.toString() ?? '',
      height: activeTemplate?.resolution.height?.toString() ?? '',
      name: activeTemplate?.name ?? '',
      visibility:
        visibilityOptions.find(
          (option) => option.value === activeTemplate?.visibility
        ) ?? visibilityOptions[0],
      access:
        accessOptions.find(
          (option) => option.value === activeTemplate?.access
        ) ?? accessOptions[0],
    }),
    [activeTemplate]
  );

  const component = useMemo(
    () => (
      <Modal>
        <TemplateForm
          presets={'edit'}
          initialValues={initialValue}
          onSubmit={onSubmit}
          validate={validator}
          isAdmin={isAdmin}
          isLoading={isProcessed}
        />
      </Modal>
    ),
    [Modal, initialValue, isAdmin, isProcessed, onSubmit, validator]
  );

  const onOpen = useCallback(() => {
    open({
      modalStyle: {
        padding: 0,
        zIndex: 9000,
        position: 'fixed',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderColor: 'transparent',
        overflow: 'clip',
        background: '#00000085',
      },
    });
  }, [open]);

  return { modal: component, close, open: onOpen };
}

export default useEditModal;
