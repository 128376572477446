import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import {
  clearSelectedChampionship,
  deleteChampionship,
  deleteChampionshipGame,
  fetchChampionship,
  fetchChampionshipTeams,
  selectSymbolicTeam,
  showChampionshipStageForms,
  upsertChampionship,
  upsertChampionshipGame,
  upsertChampionshipSettings,
  upsertSymbolicTeam
} from './actions';

import { Championship } from '../../domain/interfaces/Championship';
import { Round } from '../../domain/interfaces/Round';
import { Stage } from '../../domain/interfaces/Stage';
import { SymbolicTeam } from '../../domain/interfaces/SymbolicTeam';
import { TeamGroupStatistics } from '../../domain/interfaces/TeamGroupStatistics';
import { toast } from "react-toastify";

type StageGroupsStatistics = TeamGroupStatistics | null;

export type ChampionshipStateData = Championship & {
  isStageSaving: boolean;
  showStageForms: boolean;
  isMatchSaving: boolean;
  isSymbolicTeamSaving: boolean;
  selectedStage: Stage | null;
  stageGroupsStatistics: StageGroupsStatistics;
  selectedSymbolicTeam: SymbolicTeam | null;
  teamsFlags: {
    isLoading: boolean;
    isLoaded: boolean;
  };
  stagesFlags: {
    isLoading: boolean;
    isLoaded: boolean;
  };
  gamesFlags: {
    isLoading: boolean;
    isLoaded: boolean;
  };
}

export type TourneyChampionshipSliceState = {
  isLoading: boolean;
  isLoaded: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  updatingApplicationTeamId: number | null;
  data: { byId: { [key: number]: ChampionshipStateData }, allIds: number[] };
};

const initialState: TourneyChampionshipSliceState = {
  isLoading: false,
  isLoaded: false,
  isSaving: false,
  isDeleting: false,
  updatingApplicationTeamId: null,
  data: { byId: {}, allIds: [] },
};

const defaultFlagsState = {
  isLoading: false,
  isLoaded: false,
};

const defaultChampionshipData = {
  isStageSaving: false,
  showStageForms: false,
  isMatchSaving: false,
  isSymbolicTeamSaving: false,
  selectedStage: null,
  stageGroupsStatistics: null,
  selectedSymbolicTeam: null,
  teamsFlags: { ...defaultFlagsState },
  stagesFlags: { ...defaultFlagsState },
  gamesFlags: { ...defaultFlagsState },
};

function createFetchChampionshipReducer(builder: ActionReducerMapBuilder<TourneyChampionshipSliceState>) {
  builder.addCase(fetchChampionship.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(fetchChampionship.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.isLoaded = true;

    if (!payload) {
      return;
    }

    state.data = {
      ...state.data,
      byId: {
        ...state.data.byId,
        [payload.id]: {
          ...defaultChampionshipData,
          ...payload,
        } as ChampionshipStateData,
      },
      allIds: [...state.data.allIds, payload.id],
    };
  });

  builder.addCase(fetchChampionship.rejected, (state) => {
    state.isLoading = false;
    state.isLoaded = false;
  });
}

function createFetchChampionshipTeamsReducer(builder: ActionReducerMapBuilder<TourneyChampionshipSliceState>) {
  builder.addCase(fetchChampionshipTeams.pending, (state, { meta }) => {
    if (!state.data) {
      return;
    }

    state.data.byId[meta.arg].teamsFlags.isLoading = true;
  });

  builder.addCase(fetchChampionshipTeams.fulfilled, (state, { payload, meta }) => {
    if (!state.data) {
      return;
    }

    state.data.byId[meta.arg] = {
      ...state.data.byId[meta.arg],
      teamsFlags: {
        isLoading: false,
        isLoaded: true,
      },
      applications: payload.applications,
      teams: payload.teams,
    };
  });

  builder.addCase(fetchChampionshipTeams.rejected, (state, { meta }) => {
    if (!state.data) {
      return;
    }

    state.data.byId[meta.arg] = {
      ...state.data.byId[meta.arg],
      teamsFlags: {
        isLoading: false,
        isLoaded: true,
      },
    };
  });
}

function createUpdateChampionshipGameReducer(builder: ActionReducerMapBuilder<TourneyChampionshipSliceState>) {
  builder.addCase(upsertChampionshipGame.pending, (state, { meta }) => {
    if (!state.data) {
      return;
    }

    state.data.byId[meta.arg.championshipId].isMatchSaving = true
  });

  builder.addCase(upsertChampionshipGame.rejected, (state, { meta }) => {
    if (!state.data) {
      return;
    }

    state.data.byId[meta.arg.championshipId].isMatchSaving = false
  });

  builder.addCase(upsertChampionshipGame.fulfilled, (state, { payload, meta }) => {
    if (!state.data || !payload) {
      return;
    }

    let updatedGames = state.data.byId[meta.arg.championshipId].games;
    let updatedStages = state.data.byId[meta.arg.championshipId].stages;

    const hasGameInSages = updatedStages.some(({ matches }) => matches.some((game) => game.id === payload.id));
    const hasGameInAllGames = updatedGames.some((game) => game.id === payload.id);

    if (!payload.round) {
      if (hasGameInSages) {
        updatedStages = updatedStages.map((stage) => {
          if (stage.matches.some((match) => match.id === payload.id)) {
            return {
              ...stage,
              matches: stage.matches.filter((match) => match.id !== payload.id),
              rounds: stage.rounds.map((round) => {
                if (round.matchIds.includes(payload.id)) {
                  return {
                    ...round,
                    matchIds: round.matchIds.filter((id) => id !== payload.id),
                  }
                }

                return round;
              }),
            };
          }

          return stage;
        });
      }

      if (hasGameInAllGames) {
        updatedGames = updatedGames.map((game) => {
          if (game.id === payload.id) {
            return payload;
          }

          return game;
        });
      } else {
        updatedGames = [
          ...updatedGames,
          payload,
        ].sort((a, b) => b.date.valueOf() - a.date.valueOf());
      }
    } else {
      if (hasGameInAllGames) {
        updatedGames = updatedGames.filter((game) => game.id !== payload.id);
      }

      if (hasGameInSages) {
        updatedStages = updatedStages.map((stage) => {
          const updatedRounds = stage.rounds
            .map((round) => {
              const hasMatch = round.matchIds.includes(payload.id);

              if (hasMatch && payload.round!.id !== round.id) {
                return null;
              }

              if (!hasMatch && payload.round!.id === round.id) {
                return {
                  ...round,
                  matchIds: [
                    ...round.matchIds,
                    payload.id,
                  ],
                };
              }

              return round
            })
            .filter(Boolean) as Round[];

          const updatedStageMatches = stage.matches.map((match) => {
            if (match.id === payload.id) {
              return payload;
            }

            return match;
          });

          return {
            ...stage,
            rounds: updatedRounds,
            matches: updatedStageMatches,
          };
        });
      } else {
        updatedStages = updatedStages.map((stage) => {
          if (stage.id !== payload.round!.stageId) {
            return stage;
          }

          return {
            ...stage,
            rounds: stage.rounds.map((round) => {
              if (round.id !== payload.round!.id) {
                return round;
              }

              return {
                ...round,
                matchIds: [
                  ...round.matchIds,
                  payload.id,
                ],
              };
            }),
            matches: [
              ...stage.matches,
              payload,
            ].sort((a, b) => b.date.valueOf() - a.date.valueOf()),
          };
        });
      }
    }

    const selectedStage = state.data.byId[meta.arg.championshipId].selectedStage;
    const updatedSelectedState = selectedStage
      ? updatedStages.find((stage) => stage.id === selectedStage.id) || null
      : selectedStage;

    state.data.byId[meta.arg.championshipId] = {
      ...state.data.byId[meta.arg.championshipId],
      games: updatedGames,
      stages: updatedStages,
      selectedStage: updatedSelectedState,
      isMatchSaving: false,
    };
  });
}

function createDeleteChampionshipGameReducer(builder: ActionReducerMapBuilder<TourneyChampionshipSliceState>) {
  builder.addCase(deleteChampionshipGame.fulfilled, (state, { payload, meta }) => {
    if (!state.data || !payload) {
      return;
    }

    const games = state.data.byId[meta.arg.championshipId].games.filter((game) => game.id !== payload);
    const stages = state.data.byId[meta.arg.championshipId].stages.map((stage) => {
      const existMatch = stage.matches.find((match) => match.id === payload);
      if (!existMatch) {
        return stage;
      }

      const filteredMatches = stage.matches.filter((match) => match.id !== payload);
      const filteredRounds = stage.rounds.map((round) => {
        if (round.matchIds.includes(payload)) {
          return {
            ...round,
            matchIds: round.matchIds.filter((matchId) => matchId !== payload),
          };
        }

        return round;
      });

      return {
        ...stage,
        matches: filteredMatches,
        rounds: filteredRounds,
      };
    });

    let selectedStage = state.data.byId[meta.arg.championshipId].selectedStage;
    if (selectedStage) {
      selectedStage = stages.find((stage) => stage.id === selectedStage?.id) || null;
    }

    state.data.byId[meta.arg.championshipId] = {
      ...state.data.byId[meta.arg.championshipId],
      games,
      stages,
      selectedStage,
    };
  });
}

function createUpsertChampionshipReducer(builder: ActionReducerMapBuilder<TourneyChampionshipSliceState>) {
  builder.addCase(upsertChampionship.pending, (state) => {
    state.isSaving = true;
  });

  builder.addCase(upsertChampionship.fulfilled, (state) => {
    state.isSaving = false;
  });

  builder.addCase(upsertChampionship.rejected, (state) => {
    state.isSaving = false;
  });
}

function createUpsertChampionshipSettingsReducer(builder: ActionReducerMapBuilder<TourneyChampionshipSliceState>) {
  builder.addCase(upsertChampionshipSettings.pending, (state) => {
    state.isSaving = true;
  });

  builder.addCase(upsertChampionshipSettings.fulfilled, (state) => {
    state.isSaving = false;
  });

  builder.addCase(upsertChampionshipSettings.rejected, (state) => {
    state.isSaving = false;
  });
}

function createUpsertSymbolicTeamReducer(builder: ActionReducerMapBuilder<TourneyChampionshipSliceState>) {
  builder.addCase(upsertSymbolicTeam.pending, (state, { meta }) => {
    if (!state.data) {
      return;
    }

    state.data.byId[meta.arg.championshipId].isSymbolicTeamSaving = true;
  });

  builder.addCase(upsertSymbolicTeam.fulfilled, (state, { meta, payload }) => {
    if (!state.data) {
      return;
    }

    if (meta.arg.id) {
      state.data.byId[meta.arg.championshipId].symbolicTeams = state.data.byId[meta.arg.championshipId].symbolicTeams.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }

        return item;
      });
    } else {
      state.data.byId[meta.arg.championshipId].symbolicTeams = [
        ...state.data.byId[meta.arg.championshipId].symbolicTeams,
        payload,
      ];
    }

    state.data.byId[meta.arg.championshipId].selectedSymbolicTeam = payload;
    state.data.byId[meta.arg.championshipId].isSymbolicTeamSaving = false;
  });

  builder.addCase(upsertSymbolicTeam.rejected, (state, { meta }) => {
    if (!state.data) {
      return;
    }

    state.data.byId[meta.arg.championshipId].isSymbolicTeamSaving = false;
  });
}

function createDeleteChampionshipReducer(builder: ActionReducerMapBuilder<TourneyChampionshipSliceState>) {
  builder.addCase(deleteChampionship.pending, (state) => {
    state.isDeleting = true;
  });

  builder.addCase(deleteChampionship.fulfilled, (state, action) => {
    state.isDeleting = false;
    toast.success('Турнир удален');
  });

  builder.addCase(deleteChampionship.rejected, (state) => {
    state.isDeleting = false;
    toast.error('Не удалось удалить турнир');
  });
}


const slice = createSlice({
  name: 'tourneys/championship',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<TourneyChampionshipSliceState>) => {
    createFetchChampionshipReducer(builder);
    createFetchChampionshipTeamsReducer(builder);
    createDeleteChampionshipReducer(builder);

    createUpdateChampionshipGameReducer(builder);
    createDeleteChampionshipGameReducer(builder);
    createUpsertChampionshipReducer(builder);
    createUpsertChampionshipSettingsReducer(builder);

    createUpsertSymbolicTeamReducer(builder);

    builder.addCase(clearSelectedChampionship, (state) => {
      state.data = { byId: {}, allIds: [] };
    });

    builder.addCase(showChampionshipStageForms, (state, { payload }) => {
      if (!state.data) {
        return;
      }

      state.data.byId[payload.championshipId].showStageForms = payload.state;
    });

    builder.addCase(selectSymbolicTeam, (state, { payload }) => {
      if (!state.data) {
        return;
      }

      state.data.byId[payload.championshipId].selectedSymbolicTeam = payload.symbolicTeam;
    });
  },
});

export default slice.reducer;
