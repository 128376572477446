import { Form, Formik, FormikProps } from 'formik';
import {
  PlayersFilterValues,
  SymbolicTeamFormValues,
  buildEmptyPlayers,
  getDefaultFormation,
} from '../../../domain';
import React, { useCallback, useMemo, useState } from 'react';
import { Team, TeamInfo } from 'modules/teams/domain/interfaces/Team';
import {
  championshipSymbolicTeamIsSavingSelector,
  championshipTeamPlayersNumberSelector,
} from 'modules/tourneys/store/championship/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { PlayerPosition } from 'modules/teams/domain/enums/PlayerPosition';
import { PlayersFilter } from './players-filter';
import { PlayersList } from './players-list';
import { SymbolicTeam } from 'modules/tourneys/domain/interfaces/SymbolicTeam';
import { TeamFormation } from './team-formation/team-formation';
import { TeamPlayer } from 'modules/teams/domain/interfaces/TeamPlayer';
import UDButton from 'modules/ud-ui/components/button';
import UDFormInput from 'modules/ud-form/components/input';
import { upsertSymbolicTeam } from 'modules/tourneys/store/championship/actions';

const getSelectedPlayers = (values: SymbolicTeamFormValues) => {
  return values.players
    .filter((player) => !!player.user)
    .map((player) => ({
      rank: player.rank,
      userId: player.user!.id,
    }));
};

type SymbolicTeamFormProps = {
  allTeams: Team[];
  championshipId: number;
  symbolicTeam?: SymbolicTeam | null;
};

export const SymbolicTeamForm = ({
  allTeams,
  championshipId,
  symbolicTeam = null,
}: SymbolicTeamFormProps) => {
  const dispatch = useDispatch();

  const isSaving = useSelector(
    championshipSymbolicTeamIsSavingSelector(championshipId)
  );
  const teamPlayersNumber = useSelector(
    championshipTeamPlayersNumberSelector(championshipId)
  );

  const [selectedRank, selectRank] = useState<number | null>(null);
  const [playersFilter, setPlayersFilter] = useState<PlayersFilterValues>({
    name: '',
    positions: [],
  });

  let players = symbolicTeam?.players
    ? [...symbolicTeam.players]
    : buildEmptyPlayers(teamPlayersNumber);
  if (players.length < teamPlayersNumber) {
    for (let i = players.length; i < teamPlayersNumber; i++) {
      players.push({ rank: i + 1 });
    }
  } else if (players.length > teamPlayersNumber) {
    players = players.filter((player) => player.rank <= teamPlayersNumber);
  }

  const initialValues: SymbolicTeamFormValues = {
    id: symbolicTeam?.id,
    championshipId,
    name: symbolicTeam?.name || '',
    formation: getDefaultFormation(teamPlayersNumber),
    players,
  };

  const allPlayers = useMemo(() => {
    return allTeams.reduce<TeamPlayer[]>((result, team) => {
      const teamPlayers = team.players.map((player) => ({
        ...player,
        team: {
          id: team.id,
          name: team.name,
          logo: team.logo,
        },
      }));

      return [...result, ...teamPlayers];
    }, []);
  }, [allTeams]);

  const filteredPlayers = useMemo(() => {
    let filtered = allPlayers;

    if (playersFilter.name.length > 2) {
      const query = playersFilter.name.toLowerCase();

      filtered = filtered.filter((p) => {
        const name = `${p.firstName.toLowerCase()} ${p.lastName.toLowerCase()}`;
        const reverseName = `${p.lastName.toLowerCase()} ${p.firstName.toLowerCase()}`;
        return name.includes(query) || reverseName.includes(query);
      });
    }

    if (playersFilter.positions.length) {
      filtered = filtered.filter((p) =>
        playersFilter.positions.includes(p.position as PlayerPosition)
      );
    }

    return filtered;
  }, [allPlayers, playersFilter]);

  const handlePlayersFilterChanged = (values: PlayersFilterValues) => {
    setPlayersFilter(values);
  };

  const handlePlayerSelect = useCallback(
    (formProps: FormikProps<SymbolicTeamFormValues>) =>
      (player: TeamPlayer, team: TeamInfo) => {
        if (!selectedRank || !player) {
          return;
        }

        const newPlayers = formProps.values.players.map((item) => {
          if (item.rank === selectedRank) {
            return {
              ...item,
              teamLogo: team.logo?.url,
              user: {
                id: player.userId,
                teamId: team.id,
                firstName: player.firstName,
                lastName: player.lastName,
                avatar: player.avatar,
              },
            };
          }

          return item;
        });

        formProps.setFieldValue('players', newPlayers);
        selectRank(null);
      },
    [selectedRank]
  );

  const handleSubmit = useCallback(
    (values: SymbolicTeamFormValues) => {
      const isValid =
        values.name &&
        values.players.every(
          (player) => player.rank && player.user && player.user.id
        );
      if (!isValid) {
        return;
      }

      const data = {
        id: values.id,
        championshipId: values.championshipId,
        name: values.name,
        formation: values.formation,
        players: values.players.map((player) => ({
          rank: player.rank,
          userId: player.user!.id,
          teamId: player.user!.teamId,
        })),
      };

      dispatch(upsertSymbolicTeam(data));
    },
    [dispatch]
  );

  return (
    <div>
      <div className="row">
        <div className="col-6 mb-3"></div>
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <Form onSubmit={formProps.handleSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="py-4">
                  <PlayersFilter
                    className="mb-6"
                    onChange={handlePlayersFilterChanged}
                  />
                  <PlayersList
                    formation={formProps.values.formation}
                    allPlayers={filteredPlayers}
                    selectedPlayers={getSelectedPlayers(formProps.values)}
                    onPlayerSelect={handlePlayerSelect(formProps)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="px-8 py-4 mb-8">
                  <UDFormInput
                    name="name"
                    label="Название"
                    placeholder="Название сборной"
                  />
                </div>

                <div style={{ maxWidth: '800px', margin: '0 auto' }}>
                  <div className="pt-4">
                    <div className="p-8">
                      <TeamFormation
                        formation={formProps.values.formation}
                        players={formProps.values.players}
                        selectedRank={selectedRank}
                        onRankSelect={selectRank}
                      />
                    </div>
                  </div>

                  <div className="p-8">
                    <UDButton
                      variant="primary"
                      type="submit"
                      className="col-12 mt-3 mb-3"
                      disabled={isSaving}
                      loading={isSaving}
                    >
                      Сохранить
                    </UDButton>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
