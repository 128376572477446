/** @jsxImportSource @emotion/react */

import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import BaseModal from './baseModal';
import { DivProps } from '../../../../typings';
import Portal from '../portal';
import UDIcon from '../icon';
import { css } from '@emotion/react';

export default function useModal(props?: IProps) {
  const { onClose, background } = props ?? {};
  const [state, setState] = useState<ModalState>({
    isOpen: false,
    modalStyle: {},
    iconProps: {},
    containerProps: {},
    overlayProps: {},
    childrenContainerProps: {},
  });

  const isOpen = useMemo(() => {
    return state.isOpen;
  }, [state.isOpen]);

  const close = useCallback(() => {
    setState({ isOpen: false, modalStyle: {} });
    onClose?.();
  }, [onClose]);

  const [closeIcon, setCloseIcon] = useState<ReactNode>();

  const open = useCallback(
    (styles: ModalStyles, closeIcon?: React.ReactNode) => {
      setState({ isOpen: true, ...styles });
      setCloseIcon(closeIcon);
    },
    []
  );

  const backgroundColor = useMemo(() => {
    switch (background) {
      case 'white':
        return '#FFFFFF';
      case 'gray':
        return '#F2F2F2';
      case 'black':
        return '#000000';
      default:
        return '#FFFFFF';
    }
  }, [background]);

  const defaultOpen = useCallback(
    (additionalStyle?: Partial<ModalStyles>, closeIcon?: React.ReactNode) => {
      open(
        {
          ...additionalStyle,
          modalStyle: {
            padding: 0,
            zIndex: 9000,
            position: 'fixed',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            backgroundColor,
            top: 0,
            left: 0,
            borderRadius: 0,
            borderColor: 'transparent',
            overflow: 'clip',
            background: '#00000085',
            ...additionalStyle?.modalStyle,
          },
        },
        closeIcon
      );
    },
    [backgroundColor, open]
  );

  useEffect(() => {
    const onPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    };

    document.addEventListener('keydown', onPress);
    return () => {
      document.removeEventListener('keydown', onPress);
    };
  }, [close]);

  const Modal = useCallback(
    (props: DivProps) => {
      return (
        <Portal wrapperId={Math.random().toString()}>
          <BaseModal isOpen={state.isOpen} style={{ ...state.modalStyle }}>
            <div
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100vw',
                height: '100vh',
              }}
              onClick={close}
              {...state.overlayProps}
            />
            <div
              {...state.containerProps}
              css={css(modalStyle, state.containerProps?.css)}
            >
              <div
                className="icon-wrapper"
                css={iconModalCss}
                {...state.iconProps}
                onClick={close}
              >
                {closeIcon ?? (
                  <UDIcon
                    name={'close'}
                    componentProps={{
                      style: { width: 12, height: 12, cursor: 'pointer' },
                      className: 'modal-close-icon',
                    }}
                  />
                )}
              </div>
              <div {...state.childrenContainerProps}>{props.children}</div>
            </div>
          </BaseModal>
        </Portal>
      );
    },
    [
      close,
      state.containerProps,
      state.iconProps,
      state.isOpen,
      state.modalStyle,
      state.overlayProps,
    ]
  );

  return { Modal, close, open, isOpen, defaultOpen };
}

interface IProps {
  onClose?: () => void;
  background?: 'white' | 'gray' | 'black';
}

interface ModalState extends ModalStyles {
  isOpen: boolean;
}

export interface ModalStyles {
  modalStyle: Partial<React.CSSProperties>;
  containerProps?: DivProps;
  overlayProps?: DivProps;
  iconProps?: DivProps;
  childrenContainerProps?: DivProps;
}

const modalStyle = css`
  z-index: 1001;

  background-color: #fff;
  border: 1px solid #ededed;
  box-shadow: 4px 4px 4px 0 #00000040;
  width: 500px;
  min-height: 200px;
  border-radius: 8px;

  font-family: SF Pro Text, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  display: flex;
  flex-direction: column;

  padding: 34px 24px;
  position: relative;
`;

const iconModalCss = css`
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 22px;
  cursor: pointer;
`;
