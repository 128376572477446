import { AnnouncedTeam } from '../../../domain/interfaces/AnnouncedTeam';
import { AnnouncedTeamStatus } from '../../../domain/enums/AnnouncedTeamStatus';
import { AppDispatch } from 'store/store';
import { CardContainer } from '../team-card/styles';
import ChampionshipTeamCardHeader from '../team-card/championship-team-card-header';
import { SelectOption } from '../../../../ud-form/components/select/component';
import { SingleValue } from 'react-select';
import StyledSelect from '../../styled-select';
import UDText from '../../../../ud-ui/components/text';
import { fetchChampionshipTeams } from '../../../../tourneys/store/championship/actions';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { upsertChampionshipTeamApplication } from '../../../store/championshipTeams/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type ChampionshipTeamCardProps = {
  index: number;
  championshipTeam: AnnouncedTeam;
  isUpdating?: boolean;
  championshipId: number;
};

const ChampionshipTeamCard = ({
  index,
  championshipTeam,
  isUpdating,
  championshipId,
}: ChampionshipTeamCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleCardClick = useCallback(() => {
    navigate(`${championshipTeam.id}`);
  }, [championshipTeam.id, navigate]);

  const handleStatusChange = useCallback(
    (option: SingleValue<SelectOption>) => {
      if (championshipTeam.applications.length === 0) {
        toast.error('Необходимо добавить участников в команду');
        return;
      }

      dispatch(
        upsertChampionshipTeamApplication({
          teamId: championshipTeam.id,
          status: option?.value,
          championshipId,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(fetchChampionshipTeams(championshipId));
        });
    },
    [
      championshipTeam.applications.length,
      championshipTeam.id,
      dispatch,
      championshipId,
    ]
  );

  const prefixComponent = (
    <UDText type="subhead" className="bold ml-3 mr-6">
      {index}
    </UDText>
  );

  const applicationDate = championshipTeam.applicationDate
    ? new Date(championshipTeam.applicationDate)
    : new Date();

  const bodyComponent = (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ flexBasis: '50%' }}
    >
      <UDText type="subhead" style={{ flexBasis: '30%' }}>
        {format(applicationDate, 'dd.MM.yyyy')}
      </UDText>
      <div className="d-flex align-items-center justify-content-between flex-grow-1">
        <UDText type="subhead" className="team-players-count">
          {t('teams.playersCount', {
            count: championshipTeam.applications.length,
          })}
        </UDText>
        <StyledSelect
          isLoading={isUpdating}
          isDisabled={isUpdating}
          placeholder="Выбрать"
          className="interactive"
          options={[
            {
              value: AnnouncedTeamStatus.ACCEPTED,
              label: 'Подтвердить',
            },
            {
              value: AnnouncedTeamStatus.DECLINED,
              label: 'Отклонить',
            },
          ]}
          onChange={handleStatusChange}
        />
      </div>
    </div>
  );

  return (
    <CardContainer className="mb-4 last-mb-0" onClick={handleCardClick}>
      <ChampionshipTeamCardHeader
        isExpanded={false}
        containerProps={{ style: { cursor: 'pointer' } }}
        teamName={championshipTeam.name}
        teamLogo={championshipTeam.logo?.url}
        prefixComponent={prefixComponent}
        actions={bodyComponent}
      />
    </CardContainer>
  );
};

export default ChampionshipTeamCard;
