import React from 'react';
import UDText from 'modules/ud-ui/components/text';

interface SectionTitleProps {
  title: string;
  icon?: string;
  className?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  icon = '',
  className = 'mb-4',
}) => {
  return (
    <UDText type="callout" className={className}>
      {icon} {title}
    </UDText>
  );
};

export default SectionTitle;
