import { LabeledCheckbox, LabeledInput } from '../components/fields';

import LoseBase from '../components/base/lose-base';
import React from 'react';

const FootballLose = () => {
  return (
    <LoseBase sportType="football">
      <div className="mt-4">
        {/* <LabeledInput
          name="settings.loseGoalScore"
          placeholder="0 очков"
          label="Дополнительные очки за гол в матче"
        /> */}

        {/* <LabeledCheckbox
          name="settings.loseAwayTeamReduction"
          label="Смягчение наказания для гостевой команды"
        /> */}

        <LabeledInput
          name="settings.lossPenaltySeries"
          placeholder="0 очков"
          label="В серии послематчевых пенальти"
        />

        <LabeledCheckbox
          name="settings.technicalDefeatGoalsCount"
          label="Учёт голов при техническом поражении"
          containerClassName="d-flex align-items-center"
        />
      </div>
    </LoseBase>
  );
};

export default FootballLose;
