import * as yup from 'yup';

import {
  CreatableSelectOption,
  SelectOption,
} from '../../../../ud-form/components/select/component';
import { Formik, FormikProps } from 'formik';
import { Game, GameTeam } from 'modules/games/domain/interfaces/Game';
import UDFormSelect, {
  UDFormCreatableSelect,
} from 'modules/ud-form/components/select';

import { GameEvent } from '../../../domain/interfaces/GameDetails';
import { Location } from '../../../../locations/domain/interfaces/location';
import { Round } from '../../../../tourneys/domain/interfaces/Round';
import { SingleValue } from 'react-select';
import { SportType } from '../../../../core/domain/enums/SportType';
import { Team } from 'modules/teams/domain/interfaces/Team';
import TeamLogo from '../../../../ud-ui/components/team-logo';
import UDButton from 'modules/ud-ui/components/button';
import UDFormDateInput from 'modules/ud-form/components/datetime';
import UDFormLocationSelect from 'modules/ud-form/components/select/ud-form-location-select';
import UDFormTimeInput from 'modules/ud-form/components/datetime/time';
import UDText from 'modules/ud-ui/components/text';
import { championshipIsMatchSavingSelector } from '../../../../tourneys/store/championship/selectors';
import { format } from 'date-fns';
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const TeamSelectOption = styled.div`
  display: flex;
  align-items: center;

  gap: 8px;
`;

type UpsertMatchGameDetailsParams = {
  videoUrls: string[];
  events: GameEvent[];
  result: {
    firstTeamScore: number;
    secondTeamScore: number;
  };
};

type UpsertMatchCommonParams = {
  date: string;
  time: string;
  firstTeam: GameTeam;
  secondTeam: GameTeam;
  sportType: SportType;
  locationId?: number;
};

type UpsertMatchValues = {
  date: string;
  time: string;
  firstTeam: SelectOption | null;
  secondTeam: SelectOption | null;
  sportType: SportType;
  round: CreatableSelectOption | null;
  location: SelectOption<number, Location> | null;
};

export type UpsertMatchParams = UpsertMatchCommonParams &
  Partial<UpsertMatchGameDetailsParams> &
  (
    | {
        roundId: number;
        round?: never;
      }
    | {
        roundId?: never;
        round: {
          name: string;
          stageId: number;
        };
      }
    | {
        roundId?: never;
        round?: never;
      }
  );

type CreateGameFormProps = {
  stageId?: number;
  roundId?: number;
  teams: Team[];
  game?: Game;
  rounds?: Round[];
  onSubmit: (
    values: UpsertMatchParams,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
  requiredRound?: boolean;
  availableLocations?: Location[];
  isLoading?: boolean;
  championshipId: number;
};

// NOTE: Сильный пролаг при использование HTML в label
const teamToTeamOption = (team: Team): SelectOption<number, Team> => ({
  value: team.id,
  label: team.name,
  data: team,
});

const CreateGameForm = (props: CreateGameFormProps) => {
  const isMatchSaving = useSelector(
    championshipIsMatchSavingSelector(props.championshipId)
  );

  const {
    stageId,
    teams,
    game,
    rounds,
    onSubmit,
    requiredRound,
    availableLocations = [],
    isLoading = false,
  } = props;

  const teamsOptions = teams.map(teamToTeamOption);

  const roundsOptions: SelectOption[] = (rounds || []).map((round) => ({
    label: round.name,
    value: round.id,
  }));

  const initialValues: UpsertMatchValues = {
    date: game?.date.toISOString() || '',
    time: game?.date ? format(game?.date, 'HH:mm') : '',
    firstTeam: game?.teams[0] ? teamToTeamOption(game.teams[0]) : null,
    secondTeam: game?.teams[1] ? teamToTeamOption(game.teams[1]) : null,
    sportType: game?.sportType || SportType.football,
    round:
      rounds && rounds?.length && game?.round
        ? {
            label: game.round.name,
            value: game.round.id,
          }
        : null,
    location: game?.location
      ? {
          label: game.location.name,
          value: game.location.id,
          data: game.location,
        }
      : null,
  };

  const handleSubmit = useCallback(
    (
      values: UpsertMatchValues,
      setSubmitting: (isSubmitting: boolean) => void
    ) => {
      if (isMatchSaving) {
        return;
      }

      const data = {
        date: values.date,
        time: values.time,
        firstTeam: values.firstTeam!.value,
        secondTeam: values.secondTeam!.value,
        sportType: values.sportType,
        locationId: values.location?.value,
      } as UpsertMatchParams;

      if (values.round) {
        if (values.round!.__isNew__ && stageId) {
          data.round = {
            name: values.round!.value,
            stageId,
          };
        } else if (!values.round!.__isNew__) {
          data.roundId = values.round!.value;
        }
      }

      if (requiredRound && !data.round && !data.roundId) {
        return;
      }

      onSubmit(data, setSubmitting);
    },
    [onSubmit, stageId, isMatchSaving]
  );

  const getAvailableOptionsTeams = useCallback(
    (formProps: FormikProps<UpsertMatchValues>, indexTeam: number) => {
      if (teamsOptions.length > 2) {
        return teamsOptions.filter(
          (team) =>
            team.value !== formProps.values.firstTeam?.value &&
            team.value !== formProps.values.secondTeam?.value
        );
      }

      if (teamsOptions.length === 2) {
        switch (indexTeam) {
          case 1:
            return teamsOptions.filter(
              (team) => team.value !== formProps.values.firstTeam?.value
            );
          case 2:
            return teamsOptions.filter(
              (team) => team.value !== formProps.values.secondTeam?.value
            );
        }
      }

      return teamsOptions;
    },
    [teamsOptions]
  );

  const handleChangeTeam = useCallback(
    (
      formProps: FormikProps<UpsertMatchValues>,
      indexTeam?: 1 | 2,
      newValue?: SingleValue<SelectOption<any, any>>
    ) => {
      if (teamsOptions.length !== 2) {
        return;
      }

      if (indexTeam === 1) {
        formProps
          .setFieldValue(
            'secondTeam',
            teamsOptions.find((team) => team.value !== newValue?.value)
          )
          .then(() => {
            formProps.validateForm();
          });
      }

      if (indexTeam === 2) {
        formProps
          .setFieldValue(
            'firstTeam',
            teamsOptions.find((team) => team.value !== newValue?.value)
          )
          .then(() => {
            formProps.validateForm();
          });
      }
    },
    [teamsOptions]
  );

  const gameValidator = yup.object().shape({
    date: yup.string().required('Дата обязательна'),
    time: yup.string().required('Время обязательно'),
    firstTeam: yup
      .object()
      .shape({
        value: yup.number().required('Команда 1 обязательна'),
        label: yup.string().required('Команда 1 обязательна'),
      })
      .nullable()
      .required('Команда 1 обязательна'),
    secondTeam: yup
      .object()
      .shape({
        value: yup.number().required('Команда 2 обязательна'),
        label: yup.string().required('Команда 2 обязательна'),
      })
      .nullable()
      .required('Команда 2 обязательна'),
    location: yup
      .object()
      .nullable()
      .shape({
        value: yup.number().optional(),
        label: yup.string().optional(),
      })
      .optional(),
    round: yup
      .object()
      .shape({
        value: yup.mixed().required('Тур обязательно'),
        label: yup.string().required('Тур обязательно'),
      })
      .nullable()
      .required('Тур обязательно'),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(value, formikHelpers) =>
        handleSubmit(value, formikHelpers.setSubmitting)
      }
      validationSchema={gameValidator}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <input type="hidden" value={formProps.values.sportType} />

            <div className="d-flex flex-row">
              <div className="">
                <UDText
                  type="subhead"
                  className="mr-2 color-SurfaceRomanSilver30"
                >
                  Дата матча
                </UDText>
                <UDFormDateInput
                  autoFocus={!Boolean(game)}
                  autoComplete="off"
                  name="date"
                />
              </div>
              <div className="ml-2">
                <UDText
                  type="subhead"
                  className="mr-2 color-SurfaceRomanSilver30"
                >
                  Время начала
                </UDText>
                <UDFormTimeInput
                  name="time"
                  autoComplete="off"
                  timeIntervals={15}
                />
              </div>
            </div>

            {rounds && (
              <div className="mt-2">
                <UDFormCreatableSelect
                  name="round"
                  label="Тур"
                  placeholder="Выбрать или ввести новый"
                  isMulti={false}
                  options={roundsOptions}
                  formatCreateLabel={(inputValue) =>
                    `Добавить: "${inputValue}"`
                  }
                  onChange={(newValue) => {
                    formProps.setFieldValue('round', newValue).then(() => {
                      formProps.validateForm();
                    });
                  }}
                />
              </div>
            )}

            <div className="mt-2">
              <UDFormLocationSelect
                name="location"
                label="Место проведения"
                placeholder="Выберите место проведения"
                containerProps={{ className: 'mb-5' }}
                availableLocations={availableLocations}
              />
            </div>

            <div className="mt-2">
              <UDFormSelect
                name="firstTeam"
                label="Команда 1"
                placeholder="Выберите команду"
                isMulti={false}
                options={getAvailableOptionsTeams(formProps, 1)}
                onChange={(newValue) =>
                  handleChangeTeam(formProps, 1, newValue)
                }
                formatOptionLabel={(option) => (
                  <TeamSelectOption>
                    <TeamLogo url={option.data.logo?.url} size={32} />
                    {option.label}
                  </TeamSelectOption>
                )}
              />
            </div>
            <div className="mt-2">
              <UDFormSelect
                name="secondTeam"
                label="Команда 2"
                placeholder="Выберите команду"
                isMulti={false}
                options={getAvailableOptionsTeams(formProps, 2)}
                onChange={(newValue) =>
                  handleChangeTeam(formProps, 2, newValue)
                }
                formatOptionLabel={(option) => (
                  <TeamSelectOption>
                    <TeamLogo url={option.data.logo?.url} size={32} />
                    {option.label}
                  </TeamSelectOption>
                )}
              />
            </div>
            <div className="mt-10">
              <UDButton
                variant="primary"
                className="w-100 mb-3"
                disabled={
                  isMatchSaving || formProps.isSubmitting || !formProps.dirty
                }
                loading={isMatchSaving || isLoading}
                type="submit"
              >
                Сохранить
              </UDButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CreateGameForm;
