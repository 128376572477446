/** @jsxImportSource @emotion/react */

import { Field, FieldProps, useFormikContext } from 'formik';

import { ImageList } from './components/ImageList';
import React from 'react';
import UDButton from '../../../ud-ui/components/button';
import UDDropZone from './component/UDDropZone';
import { UDFormDropZoneProps } from './types';
import UDFormLabel from '../label';
import UDText from '../../../ud-ui/components/text';
import classNames from 'classnames';
import { fileToImage } from './utils/fileToImage';
import { useImageManagement } from './hooks/useImageManagement';
import useModal from '../../../ud-ui/components/new-modal/useModal';

const ACCEPTED_FORMATS = 'image/png,image/jpeg,image/jpg';

const DeleteImageModal = ({
  onClose,
  onDelete,
  isLoading,
}: {
  onClose: () => void;
  onDelete: () => void;
  isLoading?: boolean;
}) => {
  return (
    <>
      <UDText
        type="headline"
        className="color-SurfaceRomanSilver30 px-10 mb-2"
        style={{ textAlign: 'center' }}
      >
        Удалить изображение?
      </UDText>
      <UDText
        type="subhead"
        className="color-SurfaceRomanSilver30 mb-10"
        style={{ textAlign: 'center' }}
      >
        После сохранения изображение будет удалено.
      </UDText>
      <div className="flex-center w-100" style={{ gap: 10 }}>
        <UDButton
          className="d-flex align-items-center justify-content-center mb-3 w-50"
          style={{ width: 144 }}
          variant="primary"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          disabled={isLoading}
          loading={isLoading}
        >
          <span>Да</span>
        </UDButton>
        <UDButton
          className="d-flex align-items-center justify-content-center mb-3 w-50"
          style={{ width: 144 }}
          variant="primary"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <span>Нет</span>
        </UDButton>
      </div>
    </>
  );
};

const UDFormDropZone: React.FC<Omit<UDFormDropZoneProps, 'value'>> = ({
  name,
  containerProps,
  label,
  labelProps,
  formProps,
  onChangeInterceptor = (value: Blob) => value,
  onDeleteImage,
  multiple,
  enableDragAndDrop = false,
  disabled = false,
  ...otherDropZoneProps
}) => {
  const { setFieldValue, values } = useFormikContext<Record<string, any>>();
  const currentValue = values[name];

  const { images, handleFileChange, handleDelete, handleDragEnd } =
    useImageManagement({
      name,
      multiple,
      currentValue,
      setFieldValue,
      onChangeInterceptor,
    });

  const {
    defaultOpen: openDeleteModal,
    close: closeDeleteModal,
    Modal: DeleteModal,
  } = useModal();
  const [imageToDelete, setImageToDelete] = React.useState<number | null>(null);

  const handleDeleteWithConfirmation = (index: number) => {
    if (disabled) return;
    setImageToDelete(index);
    openDeleteModal();
  };

  const confirmDelete = () => {
    if (imageToDelete !== null) {
      handleDelete(imageToDelete);
      closeDeleteModal();
      setImageToDelete(null);
    }
  };

  const handleFileChangeWithDisabled = (files: Array<File>) => {
    if (disabled) return;
    handleFileChange(files);
  };

  const handleDragEndWithDisabled = (result: any) => {
    if (disabled) return;
    handleDragEnd(result);
  };

  const renderContent = () => {
    return (
      <>
        <ImageList
          images={images.map((img) =>
            img instanceof File ? fileToImage(img) : img
          )}
          enableDragAndDrop={enableDragAndDrop && !disabled}
          onDelete={handleDeleteWithConfirmation}
          onDeleteImage={onDeleteImage}
          multiple={multiple}
          onDragEnd={handleDragEndWithDisabled}
          disabled={disabled}
        />
        <DeleteModal>
          <DeleteImageModal
            onClose={closeDeleteModal}
            onDelete={confirmDelete}
          />
        </DeleteModal>
      </>
    );
  };

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps<any>) => {
        const isErrorVisible = meta.touched && meta.error && label;
        return (
          <div {...containerProps}>
            {label && (
              <UDFormLabel
                {...labelProps}
                className={classNames('mb-2', labelProps?.className)}
              >
                {label}
              </UDFormLabel>
            )}
            {isErrorVisible && (
              <UDText type="caption-1" className="color-CaseError">
                {meta.error}
              </UDText>
            )}
            <div {...formProps}>
              <UDDropZone
                accept={ACCEPTED_FORMATS}
                {...otherDropZoneProps}
                {...field}
                onChange={handleFileChangeWithDisabled}
                multiple={multiple}
                disabled={disabled}
                dropZoneOptions={{
                  ...(otherDropZoneProps.dropZoneOptions || {}),
                  disabled,
                  noClick: disabled,
                  noDrag: disabled,
                  noKeyboard: disabled,
                }}
              />
              <span className="css-1 text footnote mt-2 color-SurfaceRomanSilver30">
                Формат: png, jpg
              </span>
              {renderContent()}
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default UDFormDropZone;
