import UDLogo from '../logo';
import styled from '@emotion/styled';

export const MenuLogo = styled(UDLogo)`
  height: 70px;
  min-height: 70px;
`;

export const MenuContainer = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.SurfaceIndependence40};

  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.AccentNaplesYellow};
  }
`;

export const MenuSkeletonBlock = styled.div`
  width: 100%;
  height: 20px;

  background: linear-gradient(110deg, #27273f 3%, #222235 60%, #27273f 97%);
  background-size: 200% 100%;
`;
