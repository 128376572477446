import { BodyBlock, HeadBlock } from './styles';
import {
  ChampionshipApplicationFiltersOption,
  ChampionshipTeamsFilterValues,
} from '../championship-application-filters/championship-application-filters';
import {
  announcedChampionshipTeamsDataSelector,
  updatingChampionshipTeamApplicationSelector,
} from '../../../store/championshipTeams/selectors';
import { useCallback, useMemo, useState } from 'react';

import { AppDispatch } from 'store/store';
import { ApplicationStatus } from '../../../../applications/domain/enums/ApplicationStatus';
import ChampionshipApplicationFilters from '../championship-application-filters/championship-application-filters';
import CircleAttentionIcon from '../../../../ud-ui/components/icon/circle-attention-icon';
import CircleCheckIcon from '../../../../ud-ui/components/icon/circle-check-icon';
import CircleCloseIcon from '../../../../ud-ui/components/icon/circle-close-icon';
import CreateTeamPlayerForm from './create-team-player-form';
import EmptyButton from '../../../../ud-ui/components/emptyButton';
import PaperClipIcon from '../../../../ud-ui/components/icon/paper-clip-icon';
import { PlayerApplication } from '../../../domain/interfaces/PlayerApplication';
import TeamLogo from '../../../../ud-ui/components/team-logo';
import TeamPlayerCard from './team-player-card';
import UDText from '../../../../ud-ui/components/text';
import { toast } from 'react-toastify';
import { upsertChampionshipApplicationUsers } from '../../../store/championshipTeams/actions';
import { useDispatch } from 'react-redux';
import useModalEditApplication from '../../hooks/useModalEditApplication';
import { useMultiSelect } from 'shared/hooks';
import { useSelector } from 'react-redux';

const statusItems: ChampionshipApplicationFiltersOption<ApplicationStatus>[] = [
  {
    status: ApplicationStatus.PENDING,
    icon: CircleAttentionIcon,
    color: 'black',
  },
  {
    status: ApplicationStatus.ACCEPTED,
    icon: CircleCheckIcon,
    color: '#58B879',
  },
  {
    status: ApplicationStatus.DECLINED,
    icon: CircleCloseIcon,
    color: '#FF7061',
  },
];

type ChampionshipTeamPlayersProps = {
  teamId: number;
  championshipId: number;
};

const ChampionshipTeamPlayers = ({
  teamId,
  championshipId,
}: ChampionshipTeamPlayersProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { open, Modal } = useModalEditApplication();
  const announcedTeams = useSelector(
    announcedChampionshipTeamsDataSelector(championshipId)
  );
  const updatingApplicationId = useSelector(
    updatingChampionshipTeamApplicationSelector
  );
  const announcedTeam = announcedTeams ? announcedTeams[teamId] : null;

  const [status, selectStatus] = useState<ApplicationStatus>(
    ApplicationStatus.PENDING
  );
  const [name, setName] = useState<string>('');

  const playersList = useMemo(() => {
    if (!announcedTeam) {
      return [];
    }

    return Object.values(announcedTeam.applications || [])
      .filter((app) => status === app.status)
      .filter((app) => {
        const query = name.toLowerCase();
        const firstName = app.player.firstName.toLowerCase();
        const lastName = app.player.lastName.toLowerCase();

        return firstName.includes(query) || lastName.includes(query);
      });
  }, [announcedTeam, status, name]);

  const {
    selectedIds: selectedPlayers,
    handleMouseDown,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseUp,
    handleSelect,
    setSelectedIds: setSelectedPlayers,
  } = useMultiSelect<PlayerApplication>({
    items: playersList,
    getId: (item: PlayerApplication) => item.player.id,
  });

  const countByStatus = useMemo(() => {
    const defaultValue = {
      [ApplicationStatus.PENDING]: 0,
      [ApplicationStatus.ACCEPTED]: 0,
      [ApplicationStatus.DECLINED]: 0,
    };

    if (!announcedTeam) {
      return defaultValue;
    }

    return Object.values(announcedTeam.applications || []).reduce<
      Record<string, number>
    >((result, item) => {
      const count = result[item.status] || 0;
      return {
        ...result,
        [item.status]: count + 1,
      };
    }, defaultValue);
  }, [announcedTeam]);

  const handleFilterChange = useCallback(
    (values: ChampionshipTeamsFilterValues<ApplicationStatus>) => {
      setName(values.name);
      selectStatus(values.status);
    },
    []
  );

  const handlePlayersStatusChange = useCallback(
    async (status: ApplicationStatus.ACCEPTED | ApplicationStatus.DECLINED) => {
      if (!announcedTeam || selectedPlayers.length === 0) return;

      const applications = announcedTeam.applications.map((app) => ({
        userId: app.player.id,
        name: app.player.firstName,
        surname: app.player.lastName,
        middleName: app.player.middleName,
        birthDate: app.player.birthDate,
        position: app.position,
        number: app.number,
        state: selectedPlayers.includes(app.player.id) ? status : app.status,
      }));

      await dispatch(
        upsertChampionshipApplicationUsers({
          teamId,
          championshipId,
          applications,
        })
      ).unwrap();

      toast.success('Игроки успешно обновлены');
      setSelectedPlayers([]);
    },
    [
      dispatch,
      teamId,
      championshipId,
      announcedTeam,
      selectedPlayers,
      setSelectedPlayers,
    ]
  );

  if (!announcedTeam) {
    return null;
  }

  const playerIds = announcedTeam.applications.map((app) => app.player.id);

  return (
    <div className="container mt-4" onMouseUp={handleMouseUp}>
      <HeadBlock>
        <div className={'d-flex justify-content-between'}>
          <div className={'d-flex align-items-center'} style={{ gap: 17 }}>
            <TeamLogo size={60} url={announcedTeam.logo?.url} />
            <UDText type={'subhead'}>{announcedTeam.name}</UDText>
          </div>
          <EmptyButton
            title={'В разработке'}
            disabled={true}
            icon={<PaperClipIcon />}
            color={'#007AFF'}
            style={{ gap: 6 }}
          >
            <UDText type={'subhead'} style={{ color: 'inherit' }}>
              Скопировать ссылку для заявки
            </UDText>
          </EmptyButton>
        </div>
        <CreateTeamPlayerForm
          teamId={teamId}
          championshipId={championshipId}
          existPlayerIds={playerIds}
        />
      </HeadBlock>
      <BodyBlock>
        <div style={{ paddingInline: 20 }}>
          <ChampionshipApplicationFilters<ApplicationStatus>
            selectedStatus={status}
            name={name}
            options={statusItems}
            inputPlaceholder="ФИО игрока"
            countByStatus={countByStatus}
            onChange={handleFilterChange}
            selectedIds={selectedPlayers}
            onAccept={async () =>
              await handlePlayersStatusChange(ApplicationStatus.ACCEPTED)
            }
            onDecline={async () =>
              await handlePlayersStatusChange(ApplicationStatus.DECLINED)
            }
          />
        </div>
        <div
          style={{
            marginTop: 10,
            paddingInline: 20,
          }}
        >
          {playersList.length === 0 ? (
            <div className="flex-center h-100 my-5">
              <UDText type="body-text" className="color-SurfaceRomanSilver30">
                Список пуст
              </UDText>
            </div>
          ) : (
            playersList.map((app, index) => (
              <TeamPlayerCard
                key={app.player.id}
                index={index + 1}
                teamId={app.teamId}
                player={app}
                isUpdating={updatingApplicationId === app.id}
                isSelected={selectedPlayers.includes(app.player.id)}
                onSelect={handleSelect}
                onMouseDownCheckbox={(event) =>
                  handleMouseDown(app.player.id, event)
                }
                onMouseEnterCheckbox={() => handleMouseEnter(app.player.id)}
                onMouseLeaveCheckbox={() => handleMouseLeave(app.player.id)}
                onClick={() => open(app, teamId)}
              />
            ))
          )}
        </div>
      </BodyBlock>
      <Modal />
    </div>
  );
};

export default ChampionshipTeamPlayers;
