import * as S from './styles';

import { AppDispatch } from 'store/store';
import { Organizer } from 'modules/organizer/domain/interfaces/Organizer';
import { Pages } from 'modules/navigation/domain/enums/pages';
import UDMenuCategories from './components/categoryes';
import UDMenuLogout from './components/logout';
import UDMenuSystemNavigation from './components/system-navigation';
import UDMenuTournaments from './components/tournaments';
import { authSignOut } from 'modules/auth/store/actions';
import getAvailableTournamentTypes from 'shared/utils/getAvailableTournamntType';
import { resetOrganizer } from '../../../organizer/store/actions';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { useAuth } from '../../../auth/ui/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type UDMenuProps = {
  isOrganizerLoading: boolean;
  organizerInfo: Organizer | null;
};

const UDMenu = (props: UDMenuProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { availableTournaments } = useAuth();

  const onLogoutClicked = () => {
    dispatch(authSignOut());
    dispatch(resetOrganizer());
  };

  const onPlusClicked = () => {
    const availableTournamentTypes =
      getAvailableTournamentTypes(availableTournaments);
    if (availableTournamentTypes.length === 0) {
      toast.warn(
        'Вы можете создать только одну организацию на один вид спорта'
      );
      return;
    }
    navigate(Pages.ORGANIZER.CREATE);
  };

  const onChangeProfileClicked = () => {
    navigate(Pages.SELECT_TOURNAMENT);
  };

  return (
    <S.MenuContainer className="d-flex flex-column justify-content-between">
      <div className="d-flex flex-column">
        <S.MenuLogo className="ml-10 d-flex align-items-center" />
        <UDMenuTournaments
          isOrganizerLoading={props.isOrganizerLoading}
          organizerInfo={props.organizerInfo}
        />
      </div>
      <div className="d-flex flex-column">
        <UDMenuCategories />
        <UDMenuSystemNavigation />
      </div>
      <LogoutContainer>
        <UDMenuLogout
          isOrganizerLoading={props.isOrganizerLoading}
          organizerInfo={props.organizerInfo}
          onLogoutClick={onLogoutClicked}
          onPlusClick={onPlusClicked}
          onChangeProfileClick={onChangeProfileClicked}
        />
      </LogoutContainer>
    </S.MenuContainer>
  );
};

export default UDMenu;

const LogoutContainer = styled.div`
  min-height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
`;
