import { fetchLocations, fetchLocationsByFilter } from "modules/locations/store/actions";

import { AppDispatch } from "store/store";
import { setFilter } from "modules/locations/store";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import useLocations from "modules/locations/ui/hooks/useLocations";

export const useLocationList = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { isLoading, isLoaded, locations, tournamentId, locationLoadedLimit, allDataLoaded, filter } = useLocations();

    const loadNextPage = useCallback(() => {
        if (isLoading) return;
        if (isLoaded) return;
        if (locations.length === 0) return;
        if (!tournamentId) return;
        if (allDataLoaded) return;

        dispatch(fetchLocations({
            tournamentId,
            limit: locationLoadedLimit,
            offset: locations.length
        }));
    }, [isLoading, isLoaded, locations.length, tournamentId, allDataLoaded, dispatch, locationLoadedLimit]);

    const onChangeFilter = useCallback((value: string) => {
        if (!tournamentId) return;

        if (filter.text === value) return
        dispatch(setFilter({ text: value }))

        if (value) {
            dispatch(fetchLocationsByFilter({ nameFilter: value, tournamentId, limit: locationLoadedLimit }))
        }
    }, [dispatch, filter.text, locationLoadedLimit, tournamentId]);

    return {
        isLoading: isLoading || filter.isLoading,
        isLoaded,
        locations,
        loadNextPage,
        onChangeFilter,
        filteredLocations: Boolean(filter.text) ? filter.locations : null
    }
}
