import { LabeledInput } from '../components/fields';
import React from 'react';
import WinBase from '../components/base/win-base';

const FootballWin = () => {
  return (
    <WinBase sportType="football">
      <div className="mt-4">
        {/* <LabeledInput
          name="settings.winAwayGoalsScore"
          placeholder="0 очков"
          label="Дополнительные очки за голы в гостях"
        /> */}

        {/* <LabeledInput
          name="settings.winCleanSheetScore"
          placeholder="0 очков"
          label="Очки за сухую победу"
          containerClassName="d-flex align-items-center"
        /> */}

        <LabeledInput
          name="settings.winPenaltySeries"
          placeholder="0 очков"
          label="В серии послематчевых пенальти"
          containerClassName="d-flex align-items-center"
        />
      </div>
    </WinBase>
  );
};

export default FootballWin;
