import { organizerInfoSelector, organizerIsLoadedSelector, organizerIsLoadingSelector, organizerIsSavingSelector } from "modules/organizer/store/selectors"

import { useSelector } from "react-redux"

export const useOrganizer = () => {
  const isLoading = useSelector(organizerIsLoadingSelector);
  const isLoaded = useSelector(organizerIsLoadedSelector);
  const isSaving = useSelector(organizerIsSavingSelector);
  const info = useSelector(organizerInfoSelector);

  return {
    isLoading,
    isLoaded,
    isSaving,
    info,
  };
}
