import * as S from './styles';

import { Pages } from 'modules/navigation/domain/enums/pages';
import UDNavLink from 'modules/ud-ui/components/link/nav-link';

type UDMenuCategoriesProps = {
  className?: string;
};

const UDMenuCategories = (props: UDMenuCategoriesProps) => {
  return (
    <S.MenuMainNavigation className={props.className}>
      <S.MenuMainNavigationLinksContainer>
        <UDNavLink
          checkPath={Pages.TEAMS.INDEX + '*'}
          to={Pages.TEAMS.INDEX}
          className="pl-10 nav-item d-flex align-items-center"
        >
          Команды
        </UDNavLink>
        <UDNavLink
          checkPath={Pages.PLAYERS.INDEX + '*'}
          to={Pages.PLAYERS.INDEX}
          className="pl-10 nav-item d-flex align-items-center"
        >
          Игроки
        </UDNavLink>
        <UDNavLink
          checkPath={Pages.LOCATIONS.INDEX + '*'}
          to={Pages.LOCATIONS.INDEX}
          className="pl-10 nav-item d-flex align-items-center"
        >
          Локации
        </UDNavLink>
      </S.MenuMainNavigationLinksContainer>
    </S.MenuMainNavigation>
  );
};

export default UDMenuCategories;
