import { RootState } from 'store/store';
import { createSelector } from '@reduxjs/toolkit';

export const organizerCommonStoreSelector = (state: RootState) => state.organizer;

export const organizerIsLoadingSelector = createSelector(organizerCommonStoreSelector, (state) => state.isLoading);
export const organizerIsLoadedSelector = createSelector(organizerCommonStoreSelector, (state) => state.isLoaded);
export const organizerIsTeamCreatingSelector = createSelector(organizerCommonStoreSelector, (state) => state.isTeamCreating);
export const organizerInfoSelector = createSelector(organizerCommonStoreSelector, (state) => state.info);
export const selectOrganizerTeamById = (id: number) => createSelector(
  organizerInfoSelector,
  (info) => info?.teams.find((team) => team.id === id),
);
export const selectOrganizerFeature = createSelector(
  organizerInfoSelector,
  (info) => info?.availableFeatures || [],
);
export const organizerIsTeamEditingSelector = createSelector(organizerCommonStoreSelector, (state) => state.isTeamEditing);
export const organizerIsSavingSelector = createSelector(organizerCommonStoreSelector, (state) => state.isSaving);
