/** @jsxImportSource @emotion/react */

import { authSignIn, authSignUp } from 'modules/auth/store/actions';
import {
  authStoreCodeRequestedSelector,
  authStoreCodeRequestingSelector,
  authStoreErrorsSelector,
  authStoreLoadingSelector,
} from 'modules/auth/store/selectors';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'store/store';
import AuthLayoutsMain from '../../layouts';
import { AuthSignInPayload } from '../../../domain/interfaces/AuthSignInPayload';
import { AuthSignUpPayload } from 'modules/auth/domain/interfaces/AuthSignUpPayload';
import { Formik } from 'formik';
import { Pages } from 'modules/navigation/domain/enums/pages';
import SignInForm from '../../components/signInForm';
import UDButton from 'modules/ud-ui/components/button';
import UDFormCheckbox from 'modules/ud-form/components/checkbox';
import UDFormInput from 'modules/ud-form/components/input';
import UDInputPhone from 'modules/ud-form/components/input/phone';
import UDText from 'modules/ud-ui/components/text';
import { authSignInValidator } from '../../../domain/validators/auth-sign-in.validator';
import { authSignUpValidator } from 'modules/auth/domain/validators/auth-sign-up.validator';
import { css } from '@emotion/react';
import { theme } from 'styles/theme/theme';
import { useTranslation } from 'react-i18next';

type SignUpFormData = {
  firstName: string;
  lastName: string;
  middleName?: string;
  phone: string;
  agreement: boolean;
};

const AuthPagesSignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const isSignInLoading = useSelector(authStoreLoadingSelector);
  const stateErrors = useSelector(authStoreErrorsSelector);
  const isAuthCodeRequested = useSelector(authStoreCodeRequestedSelector);
  const isAuthCodeRequesting = useSelector(authStoreCodeRequestingSelector);

  const [username, setUsername] = useState('');

  const errors = useMemo(() => {
    if (!stateErrors) {
      return {};
    }

    return Object.keys(stateErrors as { [key: string]: string }).reduce(
      (result, key) => ({
        ...result,
        [key]: t(`signUp.errors.${key}.${stateErrors[key]}`),
      }),
      {}
    ) as SignUpFormData;
  }, [stateErrors, t]);

  const onSignUp = useCallback(
    (data: AuthSignUpPayload) => {
      setUsername(data.phone);
      dispatch(authSignUp(data));
    },
    [dispatch]
  );

  const onSignIn = useCallback(
    (data: AuthSignInPayload) => {
      dispatch(authSignIn(data));
    },
    [dispatch]
  );

  return (
    <AuthLayoutsMain
      formContainerProps={{ style: { height: 522, padding: 30 } }}
      title={t('signUp.title')}
      subtitle={t('signUp.subtitle')}
      action={{
        title: t('signUp.signInHint'),
        linkLabel: t('signUp.signInButtonLabel'),
        link: Pages.SIGN_IN,
      }}
    >
      {isAuthCodeRequested ? (
        <SignInForm
          initialValues={{ username, password: '' }}
          onSubmit={onSignIn}
          isLoading={isSignInLoading}
          validationSchema={authSignInValidator(t)}
        />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: '',
            lastName: '',
            middleName: '',
            phone: '',
            agreement: false,
          }}
          initialTouched={{
            firstName: false,
            lastName: false,
            middleName: true,
            phone: false,
            agreement: false,
          }}
          initialStatus={{
            middleName: true,
          }}
          initialErrors={errors}
          onSubmit={onSignUp}
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={authSignUpValidator(t)}
        >
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <UDFormInput
                name="lastName"
                label={t('signUp.nameInputLabel')}
                placeholder={t('signUp.lastNameLabelPlaceholder')}
                containerProps={{ style: { marginBottom: 10 } }}
                errorVisible={false}
              />
              <UDFormInput
                name="firstName"
                placeholder={t('signUp.firstNameLabelPlaceholder')}
                containerProps={{ style: { marginBottom: 10 } }}
                errorVisible={false}
              />
              <UDFormInput
                name="middleName"
                placeholder={t('signUp.middleNameLabelPlaceholder')}
                containerProps={{ style: { marginBottom: 10 } }}
                errorVisible={false}
              />
              <UDInputPhone
                name="phone"
                placeholder={t('signUp.phoneInputPlaceholder')}
                containerProps={{ style: { marginBottom: 14 } }}
                value={formProps.values.phone}
                errorVisible={false}
              />
              <div
                className="flex-center w-100"
                style={{ marginBottom: 14, paddingInline: 6 }}
              >
                <UDFormCheckbox
                  name="agreement"
                  containerProps={{ style: { marginRight: 14 } }}
                  css={css`
                    & > div {
                      border: 1px solid #ff7061;
                      &:has(input:focus) + div {
                        border: 1px solid ${theme.colors.AccentNaplesYellow};
                      }
                      &:has(input:checked) + div {
                        border: 1px solid transparent;
                      }
                    }
                  `}
                />
                <UDText type="tip" className="color-SurfaceRomanSilver30">
                  {t('signUp.agreementHint')}
                </UDText>
              </div>
              <UDButton
                variant="primary"
                type="submit"
                className="w-100"
                disabled={!formProps.isValid || !formProps.dirty}
                loading={isAuthCodeRequesting}
              >
                {t('signUp.signUnButtonLabel')}
              </UDButton>
            </form>
          )}
        </Formik>
      )}
    </AuthLayoutsMain>
  );
};

export default AuthPagesSignUp;
