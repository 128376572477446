import * as S from './styles';

import { format, parseISO } from 'date-fns';

import { MenuSkeletonBlock } from '../../styles';
import { Organizer } from 'modules/organizer/domain/interfaces/Organizer';
import { Pages } from 'modules/navigation/domain/enums/pages';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import UDNavLink from 'modules/ud-ui/components/link/nav-link';
import UDText from 'modules/ud-ui/components/text';
import classNames from 'classnames';
import { useMemo } from 'react';

type UDMenuTournamentsProps = {
  className?: string;
  isOrganizerLoading: boolean;
  organizerInfo: Organizer | null;
};

const UDMenuTournaments = (props: UDMenuTournamentsProps) => {
  const recentlyAdded = props.organizerInfo?.latestTournaments.map((item) => {
    const { id, name, startDate, endDate } = item;

    const startYear = startDate ? format(parseISO(startDate), 'yyyy') : null;
    const endYear = endDate ? format(parseISO(endDate), 'yyyy') : null;
    const dates = [startYear];

    if (endYear && endYear !== startYear) {
      dates.push(endYear);
    }

    return {
      link: Pages.TOURNEYS.builders.edit(id),
      checkPath: Pages.TOURNEYS.builders.edit(id, '*'),
      label: `${name} ${dates.join('/')}`,
    };
  });

  const showRecentlyAdded =
    !Array.isArray(recentlyAdded) || recentlyAdded.length > 0;

  const body = useMemo(() => {
    if (!showRecentlyAdded) {
      return null;
    }

    return (
      <div className="pt-1 pl-10 pr-4 pb-6">
        <UDText type="caption-2" className="color-SurfaceRomanSilver30 mb-3">
          последние добавленные:
        </UDText>
        <ul className="recently-added">
          {recentlyAdded ? (
            recentlyAdded?.map((play) => (
              <li key={play.link}>
                <UDNavLink
                  to={play.link}
                  checkPath={play.checkPath}
                  type="subhead"
                  style={{
                    lineClamp: 1,
                    boxOrient: 'vertical',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                  }}
                  title={play.label}
                >
                  {play.label}
                </UDNavLink>
              </li>
            ))
          ) : (
            <div className="d-flex flex-column gap-4">
              {Array.from({ length: 5 }, (_, index) => (
                <MenuSkeletonBlock key={index} className="skeleton-block" />
              ))}
            </div>
          )}
        </ul>
      </div>
    );
  }, [recentlyAdded, showRecentlyAdded]);

  return (
    <S.MenuMainNavigation className={props.className}>
      <UDNavLink
        to={Pages.TOURNEYS.INDEX}
        className={classNames('pl-10 nav-item d-flex align-items-center', {
          'mb-2': !showRecentlyAdded,
        })}
      >
        <UDColorableIcon
          name="cup"
          size={24}
          componentProps={{ className: 'mr-2' }}
        />
        Турниры
      </UDNavLink>
      {body}
    </S.MenuMainNavigation>
  );
};

export default UDMenuTournaments;
