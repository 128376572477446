import { fetchGameDetails } from 'modules/games/store/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useGameDetails } from './use-game-details';

export const useGameDetailsFetch = (gameId: number, championshipId: number) => {
  const dispatch = useDispatch();
  const { isLoading } = useGameDetails();

  useEffect(() => {
    if (!isLoading) {
      dispatch(fetchGameDetails({ gameId, championshipId }));
    }
  }, [gameId, championshipId]);
};
