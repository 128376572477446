import ApplicationCompanyBase from '../components/base/application-company-base';

const FootballApplicationCompany = () => {
  return (
    <ApplicationCompanyBase sportType="football">
      {/* <div className="mt-4">
        <LabeledInput
          name="settings.applicationMinGoalkeepers"
          placeholder="0 чел"
          label="Минимальное количество вратарей"
        />

        <LabeledInput
          name="settings.applicationMaxForeignPlayers"
          placeholder="0 чел"
          label="Максимальное количество легионеров"
        />

        <LabeledCheckbox
          name="settings.applicationMedicalCertificateRequired"
          label="Требуется медицинская справка"
        />

        <LabeledCheckbox
          name="settings.applicationInsuranceRequired"
          label="Требуется страховка от несчастных случаев"
        />
      </div> */}
    </ApplicationCompanyBase>
  );
};

export default FootballApplicationCompany;
