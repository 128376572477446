import { useDispatch, useSelector } from 'react-redux';

import { allIdsChampionshipsDataSelector } from '../../store/championship/selectors';
import { fetchChampionship } from '../../store/championship/actions';
import { useChampionship } from './use-championship';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useChampionshipFetch = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { isLoading } = useChampionship(Number(id));
  const allLoadedChampionshipsIds = useSelector(allIdsChampionshipsDataSelector);
  const championshipId = id ? parseInt(id) : null;

  useEffect(() => {
    if (!isLoading && championshipId && !allLoadedChampionshipsIds.includes(championshipId)) {
      dispatch(fetchChampionship(championshipId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [championshipId]);
};
