/** @jsxImportSource @emotion/react */

import { AppDispatch, history } from '../../../../../../store/store';
import { Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { eventsOptions, typeOptions } from '../elements/events-selector/type';
import {
  modalButton,
  modalError,
  modalInputStyle,
} from '../../../components/elements/modals/styles';
import {
  resetPressedState,
  setActiveBoard,
} from '../../../../store/templates/templates';

import { Button } from '../../../../../ud-ui/components/button/styles';
import { CustomSelect } from '../elements/events-selector/customSelect';
import ImportButton from '../elements/buttons/importButton';
import { MainBlock } from '../elements/events-selector/styles';
import { Pages } from '../../../../../navigation/domain/enums/pages';
import Row from '../elements/events-selector/row';
import UDFormInput from '../../../../../ud-form/components/input';
import UDFormSelect from '../../../../../ud-form/components/select';
import UDText from '../../../../../ud-ui/components/text';
import ValueTypeValidator from '../../../../domain/utils/form/board/valueTypeValidator';
import { Values } from '../../../../domain/interface/form/board';
import _ from 'lodash';
import boardDurationValidator from '../../../../domain/validators/boardDurationValidator';
import boardNameValidatorOnCreate from '../../../../domain/validators/boardNameValidatorOnCreate';
import classNames from 'classnames';
import { createBoard } from '../../../../store/templates/actions';
import { toast } from 'react-toastify';
import useBoards from '../../useBoards';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useModal from '../useModal';
import { useOrganizer } from '../../../../../organizer/ui/hooks/useOrganizer';
import useTemplates from '../../useTemplates';

function useCreateModal() {
  const dispatch = useDispatch<AppDispatch>();
  const { info } = useOrganizer();
  const { boards, isProcessed, isFinished, process } = useBoards();
  const { activeTemplate } = useTemplates();
  const location = useLocation();
  const isCatalog = location.pathname.includes('catalog');

  const validator = useCallback(
    (values: { [p: string]: string | { label: string; value: string } }) => {
      const errors: { [p: string]: string | undefined } = {};

      if (typeof values.name === 'string') {
        const nameError = boardNameValidatorOnCreate(
          values.name?.trim(),
          boards.map((boards) => boards.name)
        );
        if (nameError) {
          errors.name = nameError;
        }
      }

      if (typeof values.type === 'object') {
        const durationError = boardDurationValidator(+values.duration);
        if (durationError && values.type.value === 'events') {
          errors.duration = durationError;
        }
      }

      return errors;
    },
    [boards]
  );

  const onSubmit = useCallback(
    (values: Values) => {
      if (!info) {
        console.warn({
          event: 'failed to create category: info is undefined',
          info,
        });
        return;
      }

      if (!activeTemplate) {
        console.warn({
          event: 'failed to create category: activeTemplate is undefined',
          activeTemplate,
        });
        return;
      }

      const { name, duration, objects, version, type, events } =
        ValueTypeValidator({ values });
      const isEvents = type === 'events';
      const isCarousel = type === 'carousel';
      const maxOrder = boards.at(-1)?.order || 0;

      dispatch(
        createBoard({
          templateId: activeTemplate.id,
          name: name.trim(),
          version,
          objects,
          type,
          duration: isEvents || isCarousel ? duration : undefined,
          events: isEvents ? events : undefined,
          order: maxOrder + 1,
        })
      );
    },
    [activeTemplate, boards, dispatch, info]
  );

  const onFormSubmit = (values: { [p: string]: string }) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  const getErrors = (props: FormikProps<{ [p: string]: string }>) => {
    return _.find(
      props.errors,
      (error, key) => !!error && !!props.touched[key]
    );
  };

  const initialValues = {
    name: '',
    type: typeOptions.find((type) => type.value === 'static'),
    duration: '4',
    objects: [],
    version: '5.3.0',
    events: [],
  };

  const content = (
    <Formik
      validateOnChange
      // @ts-ignore
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={onFormSubmit}
      validate={(values) => (!validator ? {} : validator(values))}
    >
      {(formProps) => {
        return (
          <Form>
            <div
              key={'modal-content'}
              style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
            >
              <UDText type={'title-3'}>{'Добавить новое табло'}</UDText>
              <UDFormInput
                readOnly={isProcessed}
                onChangeCapture={() =>
                  formProps.values.name &&
                  !formProps.touched.name &&
                  formProps.setFieldTouched('name')
                }
                name={'name'}
                autoFocus={true}
                css={modalInputStyle}
                placeholder={'Название табло'}
              />
              <MainBlock>
                <Row headerText={'Тип табло'}>
                  <UDFormSelect
                    containerProps={{ style: { width: '100%' } }}
                    selectComponent={CustomSelect}
                    name={'type'}
                    options={typeOptions}
                  />
                </Row>
                <Row headerText={'Событие'}>
                  <UDFormSelect
                    containerProps={{ style: { width: '100%' } }}
                    selectComponent={CustomSelect}
                    placeholder={'Выберите события'}
                    // @ts-ignore
                    isDisabled={formProps.values.type.value !== 'events'}
                    name={'events'}
                    isMulti
                    options={eventsOptions}
                  />
                </Row>
                <Row headerText={'Время'}>
                  <UDFormInput
                    containerProps={{ style: { width: '80px' } }}
                    style={{
                      background: '#F2F2F2',
                      display: 'flex',
                      textAlign: 'center',
                    }}
                    disabled={
                      // @ts-ignore
                      formProps.values.type.value !== 'events' &&
                      // @ts-ignore
                      formProps.values.type.value !== 'carousel'
                    }
                    name={'duration'}
                    type={'number'}
                    placeholder={'Длительность в секундах'}
                  />
                  <UDText type={'subhead'} style={{ color: '#969696' }}>
                    секунд
                  </UDText>
                </Row>
              </MainBlock>
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  className={'d-flex flex-row align-items-center'}
                  style={{ height: 36, gap: 16 }}
                >
                  <Button
                    style={{ width: 130 }}
                    type={'submit'}
                    className={classNames(
                      'flex-center',
                      isProcessed && 'loading'
                    )}
                    disabled={
                      !formProps.isValid || formProps.values.name === ''
                    }
                    css={modalButton}
                  >
                    Создать
                  </Button>
                  <ImportButton isLoading={isProcessed} formProps={formProps} />
                </div>
                {getErrors(formProps) && (
                  <span style={{ marginTop: 15 }} css={modalError}>
                    {getErrors(formProps)}
                  </span>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const { modal, open, close } = useModal({ children: content });

  const onOpen = useCallback(() => {
    open({
      modalStyle: {
        padding: 0,
        zIndex: 9000,
        position: 'fixed',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderColor: 'transparent',
        overflow: 'clip',
        background: '#00000085',
      },
    });
  }, [open]);

  useEffect(() => {
    if (!isFinished) {
      return;
    }
    if (process !== 'create board') {
      return;
    }
    close();
    dispatch(resetPressedState());
    const lastBoard = boards.at(-1);
    if (!lastBoard) {
      throw Error('The last created board was not found');
    }

    dispatch(setActiveBoard({ id: lastBoard.id }));

    if (activeTemplate?.id) {
      history.push(
        Pages.TEMPLATE.builders.editor(activeTemplate?.id, lastBoard.id),
        { from: isCatalog ? 'catalog' : 'boards' }
      );
    } else {
      console.error({
        event: 'go to editor',
        message: 'activeTemplate is undefined',
        activeTemplate,
        lastBoard,
      });
      toast.warn('Не удалось перейти к редактированию табло');
    }
  }, [
    activeTemplate?.id,
    boards,
    close,
    dispatch,
    isCatalog,
    isFinished,
    process,
  ]);

  return { modal, close, open: onOpen };
}

export default useCreateModal;
