import { AuthAvailableTournament } from 'modules/auth/domain/interfaces/AuthAvailableTournament';
import { SportType } from '../../modules/core/domain/enums/SportType';

const getAvailableTournamentTypes = (
  tournaments: AuthAvailableTournament[]
) => {
  return Object.keys(SportType).filter((type) => {
    return !tournaments.some((tournament) => tournament.sportType === type);
  });
};

export default getAvailableTournamentTypes;
