/** @jsxImportSource @emotion/react */

import {
  resetPressedState,
  setActiveBoard,
  setActiveTemplate,
} from '../../store/templates/templates';
import { useCallback, useEffect, useMemo } from 'react';

import Globe from '../../../ud-ui/components/icon/globe';
import ListContainer from '../components/elements/listContainer';
import { Pages } from '../../../navigation/domain/enums/pages';
import { SyncLoader } from 'react-spinners';
import TemplateItem from '../components/elements/templateItem';
import UDBreadcrumbs from '../../../ud-ui/components/breadcrumbs';
import UDButton from '../../../ud-ui/components/button';
import UDIcon from '../../../ud-ui/components/icon';
import UDMainLayout from '../../../ud-ui/layout/main';
import { history } from '../../../../store/store';
import useCreateModal from '../hooks/modals/template/create';
import { useDispatch } from 'react-redux';
import { useLoadTemplates } from '../hooks/useLoadTemplates';
import { useLocation } from 'react-router-dom';
import { useOrganizer } from '../../../organizer/ui/hooks/useOrganizer';
import useSuccessCopyModal from '../hooks/modals/template/successCopy';
import useTemplates from '../hooks/useTemplates';

function Templates() {
  const state = useLocation().state;
  const dispatch = useDispatch();
  const { templates, isLoading: isTemplatesLoading } = useTemplates();
  const { isLoaded: isOrganizerLoaded, info } = useOrganizer();
  const { modal: createModal, open: openCreateModal } = useCreateModal();
  const { modal: successCopyModal, open: openSuccessCopyModal } =
    useSuccessCopyModal();
  const isDev = process.env.NODE_ENV === 'development';
  const isFeatureAvailable = useMemo(() => {
    if (isDev) return true;
    if (!info) return false;
    return info.availableFeatures.OBS_INTEGRATION;
  }, [info, isDev]);

  useLoadTemplates();

  const breadcrumb = useMemo(
    () => (
      <UDBreadcrumbs
        className="my-10"
        breadcrumbs={[
          { label: 'Турниры', to: Pages.TOURNEYS.INDEX },
          { label: 'Шаблоны', to: Pages.TEMPLATE.INDEX },
        ]}
      />
    ),
    []
  );

  const openCatalog = useCallback(() => {
    history.push(Pages.TEMPLATE.CATALOG.INDEX);
  }, []);

  useEffect(() => {
    if (state && state.isCoppedTemplate) {
      openSuccessCopyModal();
    }

    dispatch(setActiveTemplate({ id: undefined }));
    dispatch(setActiveBoard({ id: undefined }));
    dispatch(resetPressedState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <UDMainLayout headerContent={breadcrumb}>
      <div
        className={'d-flex w-100 justify-content-end mt-4 pr-6'}
        style={{ gap: 24 }}
      >
        <UDButton
          variant={'dark'}
          className="d-flex align-items-center"
          onClick={openCatalog}
          disabled={!isOrganizerLoaded || !isFeatureAvailable}
        >
          <Globe className={'mr-2'} />
          Готовые шаблоны
        </UDButton>
        <UDButton
          variant={'dark'}
          className="d-flex align-items-center"
          onClick={openCreateModal}
          disabled={!isOrganizerLoaded || !isFeatureAvailable}
        >
          <UDIcon name="plus" componentProps={{ className: 'mr-2' }} />
          Добавить шаблон
        </UDButton>
      </div>
      <ListContainer
        className={'mt-4 px-6'}
        headerProps={{ title: 'Шаблоны' }}
        contentProps={{ className: 'h-100', style: { height: '100%' } }}
      >
        {isTemplatesLoading ? (
          <div className={'container flex-center h-100'}>
            <SyncLoader />
          </div>
        ) : (
          templates.map((template, index) => (
            <TemplateItem key={template.id} template={template} index={index} />
          ))
        )}
      </ListContainer>
      {createModal}
      {successCopyModal}
    </UDMainLayout>
  );
}

export default Templates;
