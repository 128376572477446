export function checkIsDev(emulate?: boolean): boolean {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return false;
  }

  if (emulate !== undefined) {
    return emulate;
  }

  return process.env.REACT_APP_ENVIRONMENT !== 'production';
}
