import {
  gameDetailsIsSavingSelector,
  gameEventsChangedSelector,
} from '../../../store/selectors';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../../../store/store';
import EventsList from './components/events-list';
import { GameResultHeader } from '../game-result-header';
import MainStreamURL from '../game-result/components/MainStreamURL';
import TimestampUrls from '../game-result/components/timestampUrls';
import UDButton from '../../../../ud-ui/components/button';
import { saveGameDetails } from '../../../store/actions';
import { toast } from 'react-toastify';
import { useGameDetails } from '../../hooks/use-game-details';
import { useGameDetailsFetch } from '../../hooks/use-game-details-fetch';

interface IProps {
  gameId: number;
  championshipId: number;
}

function MomentsPage(props: IProps) {
  const dispatch = useDispatch<AppDispatch>();

  const { gameId, championshipId } = props;
  useGameDetailsFetch(gameId, championshipId);

  const { isLoading, data } = useGameDetails();

  const eventsChanged = useSelector(gameEventsChangedSelector);
  const isDetailsSaving = useSelector(gameDetailsIsSavingSelector);

  const isSaveButtonDisabled = useMemo(() => {
    return !eventsChanged || isDetailsSaving;
  }, [eventsChanged, isDetailsSaving]);

  const handleSaveMomentsUrls = useCallback(() => {
    if (!data) return;
    if (!eventsChanged) return;
    if (isDetailsSaving) return;

    dispatch(saveGameDetails(data))
      .then(() => {
        toast.success('Сохранено');
      })
      .catch(() => {
        toast.error('Произошла ошибка');
      });
  }, [data, dispatch, eventsChanged, isDetailsSaving]);

  return (
    <div>
      <GameResultHeader isLoading={isLoading} game={data} />
      <MainStreamURL isLoading={isLoading} game={data} />
      <TimestampUrls isLoading={isLoading} game={data} />
      <div className="mt-9">
        <EventsList game={data} isLoading={isLoading} />
      </div>

      <div className="d-flex justify-content-end p-4" style={{ marginTop: 10 }}>
        <UDButton
          variant={'primary'}
          disabled={isSaveButtonDisabled}
          loading={isDetailsSaving}
          onClick={handleSaveMomentsUrls}
        >
          Сохранить и опубликовать
        </UDButton>
      </div>
    </div>
  );
}

export default MomentsPage;
