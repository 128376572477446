import * as S from './styles';

import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import React, { HTMLProps, useCallback } from 'react';

import { DivProps } from 'typings';
import UDButton from '../../../../../ud-ui/components/button';
import UDText from '../../../../../ud-ui/components/text';
import classNames from 'classnames';

export type UDDropZoneProps = {
  // onChange?: (...event: any[]) => void;
  onChange?: (files: Array<File>) => void;
  onGetImg?: (img: any | null) => void;
  value: any[];
  isError?: boolean;
  children?: React.ReactNode;
  dropZoneOptions?: Parameters<typeof useDropzone>[0];
  containerProps?: DivProps;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  onButtonClick?: () => void;
} & Omit<HTMLProps<HTMLInputElement>, 'value' | 'children' | 'onChange'>;

const UDDropZone = (props: UDDropZoneProps) => {
  const {
    onChange,
    onGetImg,
    isError,
    value: files = [],
    children,
    dropZoneOptions,
    containerProps,
    title = 'Перетащите сюда изображения',
    subtitle = 'или',
    buttonText = 'Выберите файл',
    onButtonClick,
    ...otherProps
  } = props;
  const maxFiles = dropZoneOptions?.maxFiles;
  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const couldAcceptMore =
        maxFiles !== undefined
          ? files.length + acceptedFiles.length <= maxFiles
          : true;
      if (onChange && couldAcceptMore) {
        onChange(acceptedFiles);
      }
      const path = acceptedFiles.map((file) => URL.createObjectURL(file));

      if (onGetImg) {
        onGetImg({
          url: path,
          size: acceptedFiles[0].size,
          name: acceptedFiles[0].name,
        });
      }
    },
    [maxFiles, files, onChange, onGetImg]
  );
  const { isFocused, isDragActive, getRootProps, getInputProps } = useDropzone({
    ...dropZoneOptions,
    onDrop,
  });

  return (
    <S.Container
      {...containerProps}
      className={classNames(
        {
          error: !!isError,
          focused: isFocused,
          active: isDragActive,
        },
        containerProps?.className
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} {...otherProps} type="file" />
      {children || (
        <>
          {title !== '' && (
            <UDText
              type="footnote"
              className="color-SurfaceRaisinBlack50 mb-2"
              style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
            >
              {title}
            </UDText>
          )}
          {subtitle !== '' && (
            <UDText type="footnote" className="color-SurfaceRomanSilver30 mb-2">
              {subtitle ?? 'или выберите файл'}
            </UDText>
          )}
          <UDButton variant="secondary" type="button" onClick={onButtonClick}>
            {buttonText ?? 'Выберите файл'}
          </UDButton>
        </>
      )}
    </S.Container>
  );
};

export default UDDropZone;
