import { LabeledInput, SectionTitle } from '../fields';
import React, { ReactNode } from 'react';

import { WinRuleProps } from 'modules/tourneys/ui/components/form-parts/rules/types';

interface ExtendedWinRuleProps extends WinRuleProps {
  children?: ReactNode;
}

const WinBase: React.FC<ExtendedWinRuleProps> = ({
  sportType = 'general',
  children,
}) => {
  return (
    <>
      <SectionTitle title="Победа" icon="🏆" />

      <LabeledInput
        name="settings.winScore"
        placeholder="0 очков"
        label=""
        containerClassName="mb-4"
        inputContainerClassName="w-30"
        alignRight={false}
      />

      <LabeledInput
        name="settings.winScoreExtraTime"
        placeholder="0 очков"
        label="В дополнительное время"
      />

      {/* Место для дополнительных полей */}
      {children}
    </>
  );
};

export default WinBase;
